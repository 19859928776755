import {
  TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator, TimelineItem,
} from '@mui/lab';
import {
  Collapse, Stack, styled, Typography, css,
} from '@mui/material';
import ExpandCollapseButton from 'features/shared/ExpandCollapseButton';
import useIsMobileScreen from 'lib/useIsMobileScreen';

type TimelineSectionProps = {
  children: React.ReactNode;
  expanded: boolean;
  icon: React.ReactNode;
  onExpand(): void;
  textColor: string;
  title: string;
}

/** Renders an entire section of the timeline (eg. Completed)  */
export default function TimelineSection({
  children, expanded, icon, onExpand, textColor, title,
}: TimelineSectionProps) {
  const isMobile = useIsMobileScreen();
  return (
    <>
      <SectionHeadingTimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot variant="unstyled">
            {icon}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ margin: 0, p: 0, py: '4px' }}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            onClick={isMobile ? onExpand : undefined}
          >
            <Typography
              color={textColor}
              component="h4"
              className="section-title"
              sx={{ pl: 1 }}
              variant="overline"
            >
              {title}
            </Typography>
            <ExpandCollapseButton expanded={expanded} onClick={onExpand} />
          </Stack>
        </TimelineContent>
      </SectionHeadingTimelineItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}

const SectionHeadingTimelineItem = styled(TimelineItem)(({ theme }) => css`
  margin-left: -10px;

  &:first-of-type {
    .MuiTimelineSeparator-root {
      margin-top: ${theme.spacing(1)};
    }

    .MuiTimelineConnector-root:first-of-type {
      display: none;
    }

    .section-title {
      margin-top: -6px;
    }
  }

  &:last-of-type {
    .MuiTimelineConnector-root:first-of-type {
      flex-grow: 0.5;
    }

    .MuiTimelineConnector-root:last-of-type {
      display: none;
    }

    & + .MuiCollapse-root {
      // Apply a styling fix for the first item in the future inner-timeline
      .MuiTimelineItem-root:first-of-type .MuiTimelineConnector-root:first-of-type {
        margin-top: -8px;
        padding-top: 8px;
      }

      // Hide last after connector for the future inner-timeline
      .MuiTimelineItem-root:last-of-type .MuiTimelineConnector-root:last-of-type {
        background-color: transparent;
      }
    }
  }
`);
