import {
  Card, Stack, Typography, css, styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PainLevelBox from './PainLevelBox';
import { PainLevel } from '../shared';

type PainLevelInfoCardProps = {
  painLevel: PainLevel;
}
export default function PainLevelInfoCard({ painLevel }: PainLevelInfoCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'painScale.aboutPainScale.levels' });
  const title = t(`${painLevel}.title`);
  const description = t(`${painLevel}.description`);

  return (
    <Card sx={{ mb: 1, '&:last-of-type': { mb: 0 } }}>
      <Stack direction="row" alignItems="center">
        <PainLevelBox painLevel={painLevel} />
        <Typography variant="body2" fontWeight="medium" sx={{ ml: 2 }}>
          {title}
        </Typography>
      </Stack>
      <InfoCardDescription>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </InfoCardDescription>
    </Card>
  );
}

const InfoCardDescription = styled('div')(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  border-top: 1px solid ${theme.palette.grey[300]};
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
`);
