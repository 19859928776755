import { createApiClient } from 'features/shared/api/client';
import { toApiUrl } from 'features/shared/api/request';
import useMutation from 'features/shared/api/useMutation';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import { CLIENT_ENTITY_ID } from 'features/shared/entityIds';
import { Mutation } from 'react-query';

export const FILE_UPLOAD_MUTATION_KEY = 'fileUpload';

const FILE_PARAM_NAME = 'file';
const BASE_URL = toApiUrl('File/UploadToRecord');
const BASE_DOCUMENT_PATH = 'Client/Uploads';

const client = createApiClient();

export type FileUploadMutationVariables = { file: File; abortController: AbortController; };
export type FileUploadMutation = Mutation<unknown, unknown, FileUploadMutationVariables> & {
  state: {
    // `Mutation` makes `TVariables` nullable, but they cannot be null since they're mandatory.
    variables: FileUploadMutationVariables;
  }
};

export const useFileUpload = () => {
  const {
    currentCase,
    isLoading: isCurrentCaseLoading,
    isError: isCurrentCaseError,
  } = useCurrentCase();

  return useMutation((
    { file, abortController }: FileUploadMutationVariables,
  ) => {
    if (isCurrentCaseLoading || isCurrentCaseError) {
      throw new Error('Illegal mutation call. Current case must be fetched successfully.');
    }

    return uploadFile({
      file,
      entityId: CLIENT_ENTITY_ID,
      recordId: currentCase!.id,
      documentPath: BASE_DOCUMENT_PATH,
      abortSignal: abortController.signal,
    });
  }, {
    mutationKey: FILE_UPLOAD_MUTATION_KEY,
    meta: { isFileUploadMutation: true },
  });
};

export const isFileUploadMutation = (mutation: any): mutation is FileUploadMutation => {
  return Boolean(mutation.meta?.isFileUploadMutation);
};

type UploadFileProps = {
  file: File;
  documentPath?: string;
  entityId: string;
  recordId: string;
  abortSignal: AbortSignal;
}

const uploadFile = async ({
  entityId, recordId, documentPath = 'Client%20Uploads', file, abortSignal,
}: UploadFileProps) => {
  const formData = new FormData();

  formData.append('filename', file.name);
  formData.append(FILE_PARAM_NAME, file);

  const url = `${BASE_URL}?EntityID=${entityId}&RecordID=${recordId}&DocumentPath=${documentPath}`;

  try {
    await client.post(
      url,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        signal: abortSignal,
      },
    );
  } catch (err) {
    if (!abortSignal.aborted) throw err;
  }
};
