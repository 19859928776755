import { Components, Theme } from '@mui/material/styles';
import LinkBehavior from './shared/LinkBehavior';

export default function createMuiButtonBase(): NonNullable<Components<Theme>['MuiButtonBase']> {
  return {
    defaultProps: {
      LinkComponent: LinkBehavior,
    },
  };
}
