import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import { isBlank } from 'lib/utils';
import { isNil, pick } from 'ramda';
import { CaseTeamMember, useCaseTeamMembers } from './api/useCaseTeamMembers';

export type TeamMember = Pick<
  CaseTeamMember,
  'id' | 'acceptCalls' | 'acceptEmail' | 'acceptSms' | 'email' | 'name' | 'phone' | 'smsPhone' | 'title'
> & {
  isPrimary: boolean;
}

/**  Merge info from CaseTeamMembers and Client endpoints
 * into a simplified form for TeamMembers data
*/
export default function useTeamMembers() {
  const {
    currentCase,
    isLoading: isCurrentCaseLoading,
    isError: isCurrentCaseError,
  } = useCurrentCase();
  const {
    data: allTeamMembers,
    isLoading: isTeamMembersLoading,
    isError: isTeamMembersError,
  } = useCaseTeamMembers();

  const isLoading = isCurrentCaseLoading || isTeamMembersLoading;
  const isError = isCurrentCaseError || isTeamMembersError;

  const toResult = (data: TeamMember[] | null) => ({ data, isLoading, isError });

  if (isLoading || isError || allTeamMembers!.length === 0 || isNil(currentCase)) {
    return toResult(null);
  }

  const currentCaseTeamMembers = currentCase.relationships?.teamMembers;

  if (isBlank(currentCaseTeamMembers)) return toResult(null);

  const teamMembers = currentCaseTeamMembers.map((caseMember) => {
    const caseMemberDetails = allTeamMembers?.find((member) => member.id === caseMember.id);

    return {
      ...pick(
        ['id', 'acceptCalls', 'acceptEmail', 'acceptSms', 'email', 'name', 'phone', 'smsPhone', 'title'],
        caseMemberDetails,
      ),
      isPrimary: caseMember.isPrimary ?? false,
    } as TeamMember;
  });

  return toResult(teamMembers);
}
