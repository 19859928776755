import { useClients } from 'features/shared/api/useClients';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import { useTranslation } from 'react-i18next';
import ListItemButtonWithSelectedIcon from '../shared/ListItemButtonWithSelectedIcon';
import ProfileCardListContainer from './shared/ProfileCardListContainer';

export default function ChangeCase() {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.case' });
  const {
    data: cases = [],
    isLoading: isCasesLoading,
    isError: isCasesError,
  } = useClients();
  const {
    currentCase,
    setCurrentCase,
    isLoading: isCurrentCaseLoading,
    isError: isCurrentCaseError,
  } = useCurrentCase();

  const isLoading = isCasesLoading || isCurrentCaseLoading;
  const isError = isCasesError || isCurrentCaseError;

  const { formatDate } = useLocalizedDateFormatFns();

  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;
  if (isError) return <GenericPageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></GenericPageLayout>;

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <ProfileCardListContainer title={t('selectCase')} listAriaLabel={t('a11y.listLabel')}>
        {cases?.map((caseObj) => {
          const isSelected = caseObj.id === currentCase?.id;

          return (
            <ListItemButtonWithSelectedIcon
              key={caseObj.id}
              selected={isSelected}
              onClick={() => setCurrentCase(caseObj)}
            >
              {`${caseObj.name} ${formatDate(caseObj.createDate, { format: {} })}`}
            </ListItemButtonWithSelectedIcon>
          );
        })}
      </ProfileCardListContainer>
    </GenericPageLayout>
  );
}
