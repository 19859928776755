import { useTranslation } from 'react-i18next';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'features/shared/form/Form';
import * as Yup from 'yup';
import TextField, { TextFieldProps } from 'lib/TextField';
import { FormProvider } from 'react-hook-form';
import { LocaleCode } from 'i18n';
import useShareWithPeople, { ShareInviteFormValues } from './api/useShareWithPeople';
import ProfileCard from '../shared/ProfileCard';

const VALIDATION_SCHEMA = Yup.object().shape({
  emailOrPhone: Yup.string().required(),
  name: Yup.string().required(),
});
const FORM_FIELDS: (keyof ShareInviteFormValues)[] = ['name', 'emailOrPhone'];
const FIELD_PROPS: { [K in keyof ShareInviteFormValues]: Omit<TextFieldProps, 'name'> } = {
  name: {
    autoComplete: 'name',
  },
  emailOrPhone: {
    autoComplete: 'email home',
  },
};

type ShareWithPeopleFormProps = {
  caseId: string;
  onSuccess(): void;
}
export default function ShareWithPeopleForm({ caseId, onSuccess }: ShareWithPeopleFormProps) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'profile.shareCase.shareWithPeople' });
  const { mutateAsync: sendInvite } = useShareWithPeople({
    caseId, locale: i18n.language as LocaleCode,
  });

  const formMethods = useForm<ShareInviteFormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
    defaultValues: {
      emailOrPhone: '',
      name: '',
    },
  });
  const {
    handleSubmit, reset, formState: { errors, isSubmitting },
  } = formMethods;

  const onFormSubmit = async (props: ShareInviteFormValues) => {
    return sendInvite(props).then(() => {
      reset();
      onSuccess();
    });
  };

  return (
    <ProfileCard title={t('title')} sx={{ mt: 2 }}>
      <FormProvider {...formMethods}>
        <Form
          onSubmit={handleSubmit(onFormSubmit)}
          submitButtonLabel={t('form.submitButton.label')}
          isSubmitting={isSubmitting}
          errors={errors}
        >
          {
            FORM_FIELDS.map((fieldName) => (
              <TextField
                name={fieldName}
                key={fieldName}
                {...FIELD_PROPS[fieldName]}
                fullWidth
                label={t(`form.${fieldName}.label`)}
                errors={errors[fieldName]}
              />
            ))
          }
        </Form>
      </FormProvider>
    </ProfileCard>
  );
}
