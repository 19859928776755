import { Box, SxProps } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  referenceValue: string | number;
  value: string | number;
  htmlIdBase: string;
  sx?: SxProps;
}

export default function TabPanel(props: TabPanelProps) {
  const {
    children, value, referenceValue, htmlIdBase: htmlIdPrefix, sx, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== referenceValue}
      id={`${htmlIdPrefix}-tabpanel-${value}`}
      aria-labelledby={`${htmlIdPrefix}-tab-${value}`}
      sx={{ width: 1, ...sx }}
      {...other}
    >
      {value === referenceValue && children}
    </Box>
  );
}
