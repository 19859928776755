import { createApiClient } from 'features/shared/api/client';
import { deserializeCustomResponse } from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import useQuery from 'features/shared/api/useQuery';

const REQUEST_URL = toApiUrl('CustomResponse/CP_Dashboard');

export type DashboardData = {
  sensitiveNotificationsCount: number,
  caseStatus: {
    completedStepsCount: number,
    currentStepsTitles: string[],
    futureStepsCount: number,
    histories: {
      unreadCount: number,
      latestUpdate: {
        type: string,
        details: string,
        date: Date | null
      }
    }
  },
  caseTeam: {
    primaryMemberName: string | null,
    additionalMembersCount: number
  },
  medicalAppointmentsDates: string[],
  painScale: {
    level: number,
    description: string,
    date: Date | null
  }
}

const apiClient = createApiClient();

export const useDashboard = (clientId: string | undefined) => {
  return useQuery(
    ['dashboard', clientId],
    async () => {
      return (await apiClient.get(`${REQUEST_URL}/${clientId}`)).data;
    },
    {
      select: (data) => deserializeCustomResponse<DashboardData>(data),
      enabled: Boolean(clientId),
    },
  );
};
