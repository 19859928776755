import {
  Box, css, Stack, styled, useTheme,
} from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';
import useLocationPathSegments from 'lib/useLocationPathSegments';
import {
  ReactNode, RefObject, useMemo, useState,
} from 'react';
import { useMatchesAny, UseMatchPattern } from 'lib/routing';
import { useLocation } from 'react-router-dom';
import { makeResponsiveBooleanResolver, ResponsiveBoolean } from 'lib/responsive-utils';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import { ErrorBoundary } from '@sentry/react';
import { useCurrentUser } from 'features/shared/session/auth';
import { AppHeader } from './internal/app-header/AppHeader';
import { PageTitleContext } from './internal/PageTitle';
import AppSidebar from './internal/app-sidebar/AppSidebar';
import { CORNERS_RADIUS, getContentPaddingY } from './styles';
import AppBottomNavigation from './internal/AppBottomNavigation';
import { TitleAccessoryContext } from './TitleAccessory';
import GenericPageLayout from '../GenericPageLayout';
import GenericErrorBoundaryFallback from '../GenericErrorBoundaryFallback';

export type AppLayoutProps = {
  children: ReactNode;
  hideNavigation?: ResponsiveBoolean | 'auto';
  showPageTitle?: ResponsiveBoolean;
  pathnamesWhereBackButtonShouldBeVisibleOnDesktop?: UseMatchPattern[];
  pathnamesWhereBackButtonShouldConfirmOnPress?: UseMatchPattern[],
  notificationsCount: number;
  pendingFileUploadsCount: number;
};

export default function AppLayout({
  children,
  hideNavigation: hideNavigationInput = 'auto',
  showPageTitle: showPageTitleInput = true,
  pathnamesWhereBackButtonShouldBeVisibleOnDesktop = [],
  pathnamesWhereBackButtonShouldConfirmOnPress = [],
  notificationsCount,
  pendingFileUploadsCount,
}: AppLayoutProps) {
  const theme = useTheme();
  const [titleRef, setTitleRef] = useState<RefObject<HTMLElement> | null>(null);
  const [titleAccessoryRef, setTitleAccessoryRef] = useState<RefObject<HTMLElement> | null>(null);
  const isTopLevelRoute = useLocationPathSegments().length === 1;
  const showBackButtonOnDesktop = useMatchesAny(pathnamesWhereBackButtonShouldBeVisibleOnDesktop);
  const isDesktopScreen = !useIsMobileScreen();
  const resolveResponsiveBoolean = makeResponsiveBooleanResolver(isDesktopScreen);
  const showPageTitle = resolveResponsiveBoolean(showPageTitleInput);
  const confirmOnBackButtonPress = useMatchesAny(pathnamesWhereBackButtonShouldConfirmOnPress);

  const showSidebar = isDesktopScreen && (
    hideNavigationInput === 'auto'
    || !resolveResponsiveBoolean(hideNavigationInput)
  );

  const showBottomNavigation = (() => {
    if (isDesktopScreen) return false;
    if (hideNavigationInput === 'auto') return isTopLevelRoute;
    return !resolveResponsiveBoolean(hideNavigationInput);
  })();

  const denseToolbar = !isDesktopScreen && showPageTitle;
  const toolbarHeight = denseToolbar ? theme.sizes.toolbarDenseHeight : theme.sizes.toolbarHeight;

  const { currentUser } = useCurrentUser();
  const { pathname } = useLocation();
  const roundCorners = isDesktopScreen || !showPageTitle;

  return (
    <PageTitleContext.Provider
      value={useMemo(() => ({ ref: titleRef, setRef: setTitleRef }), [titleRef])}
    >
      <TitleAccessoryContext.Provider value={useMemo(() => ({
        ref: titleAccessoryRef, setRef: setTitleAccessoryRef,
      }), [titleAccessoryRef])}
      >
        <AppHeader
          showPageTitle={showPageTitleInput}
          showBackButton={showBackButtonOnDesktop || 'on-mobile'}
          confirmOnBackButtonPress={confirmOnBackButtonPress}
          AppBarProps={{ fixed: !showBottomNavigation, denseToolbar }}
        />

        <ContentOuterContainer>
          <ContentInnerContainer className="AppLayout-contentInnerContainer" roundCorners={roundCorners}>
            <Stack direction={{ xs: 'column', md: 'row' }} sx={{ minHeight: `calc(100vh - ${toolbarHeight}px)` }}>
              {showSidebar && (
                <AppSidebar
                  notificationsCount={notificationsCount}
                  pendingFileUploadsCount={pendingFileUploadsCount}
                />
              )}

              <Box sx={{ flex: '1 1 0', py: getContentPaddingY }}>
                {/** @ts-ignore */}
                <ErrorBoundary
                  key={`${pathname}-${Boolean(currentUser)}`}
                  fallback={<GenericPageLayout pageTitle="Error" centerContent><GenericErrorBoundaryFallback /></GenericPageLayout>}
                >
                  {children}
                </ErrorBoundary>
              </Box>

              {showBottomNavigation && (
                <>
                  <AppBottomNavigation
                    showNotificationsIndicator={notificationsCount > 0}
                    showUploadsIndicator={pendingFileUploadsCount > 0}
                  />
                  <Box sx={{ visibility: 'hidden', height: theme.sizes.bottomNavigationHeight }} />
                </>
              )}
            </Stack>
          </ContentInnerContainer>
        </ContentOuterContainer>
      </TitleAccessoryContext.Provider>
    </PageTitleContext.Provider>
  );
}

const ContentOuterContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

type ContentInnerContainerOwnProps = {
  roundCorners: boolean;
};

const ContentInnerContainer = styled('div', { shouldForwardProp: isPropValid })<ContentInnerContainerOwnProps>(
  ({ theme, roundCorners }) => css`
    background: ${theme.palette.background.default};

    ${roundCorners && css`
      border-top-left-radius: ${CORNERS_RADIUS};
      border-top-right-radius: ${CORNERS_RADIUS};
    `}
  `,
);
