import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import GradingIcon from '@mui/icons-material/Grading';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpIcon from '@mui/icons-material/Help';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EmailIcon from '@mui/icons-material/Email';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import useLogout from './session/useLogout';

export const PROFILE_LINKS_BEFORE_TRANSLATION = [{
  translationKey: 'accountSettings',
  path: '/profile/settings',
  icon: <SettingsOutlinedIcon />,
  selectedIcon: <SettingsIcon />,
}, {
  translationKey: 'case',
  path: '/profile/case',
  icon: <GradingOutlinedIcon />,
  selectedIcon: <GradingIcon />,
}, {
  translationKey: 'shareCase',
  path: '/profile/share',
  icon: <PersonAddOutlinedIcon />,
  selectedIcon: <PersonAddIcon />,
}, {
  translationKey: 'language',
  path: '/profile/language',
  icon: <LanguageOutlinedIcon />,
  selectedIcon: <LanguageIcon />,
}, {
  translationKey: 'faq',
  path: '/profile/faq',
  icon: <HelpOutlineIcon />,
  selectedIcon: <HelpIcon />,
}, {
  translationKey: 'sendFeedback',
  path: '/profile/feedback',
  icon: <EmailOutlinedIcon />,
  selectedIcon: <EmailIcon />,
}];

export function useProfileLinks() {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation.sidebar' });

  return PROFILE_LINKS_BEFORE_TRANSLATION.map(
    ({ translationKey, ...attrs }) => ({ ...attrs, text: t(translationKey) }),
  );
}

export function useLogoutButton() {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation.sidebar' });
  const logout = useLogout();

  return {
    text: t('logout'),
    icon: <LogoutOutlinedIcon />,
    selectedIcon: <LogoutIcon />,
    action: logout,
  };
}
