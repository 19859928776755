import {
  Box, Button, IconButton, CircularProgress,
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, Close as CloseIcon } from '@mui/icons-material';
import { MutationStatus } from 'react-query';
import { CSSProperties, forwardRef } from 'react';
import Card, { CardProps } from './shared/Card';
import ErrorCard from './shared/ErrorCard';

export type FileCardProps = {
  name: string;
  status: MutationStatus;
  style?: CSSProperties;
  onRetry: () => void;
  onDismiss: () => void;
};

export default forwardRef<HTMLDivElement, FileCardProps>(function FileCard({
  name, status, style, onRetry, onDismiss,
}, ref) {
  const sharedProps: Partial<CardProps> = { noWrap: true, ...(style && ({ style })) };

  if (status === 'success') {
    return (
      <Card
        ref={ref}
        icon={<CheckCircleIcon color="success" />}
        action={<IconButton size="small" aria-label="clear" onClick={onDismiss}><CloseIcon /></IconButton>}
        {...sharedProps}
      >
        <span title={name}>{name}</span>
      </Card>
    );
  }

  if (status === 'loading' || status === 'idle') {
    return (
      <Card
        ref={ref}
        icon={<Box sx={{ display: 'flex' }}><CircularProgress size={24} /></Box>}
        {...sharedProps}
      >
        <span title={name}>{name}</span>
      </Card>
    );
  }

  if (status === 'error') {
    return (
      <ErrorCard
        ref={ref}
        action={<Button size="small" onClick={onRetry}>Retry</Button>}
        {...sharedProps}
      >
        <span title={name}>{name}</span>
      </ErrorCard>
    );
  }

  throw new Error('Invalid card status');
});
