import { Components, LinkProps as MuiLinkProps, Theme } from '@mui/material';
import LinkBehavior from './shared/LinkBehavior';

export default function createMuiLink(): NonNullable<Components<Theme>['MuiLink']> {
  return ({
    defaultProps: {
      component: LinkBehavior,
    } as MuiLinkProps,
  });
}
