import { Tab } from '@mui/material';
import {
  Link, matchPath, Outlet, useLocation,
} from 'react-router-dom';
import TabPanel from 'features/shared/TabPanel';
import AppLayoutTabs, { AppLayoutTabPanelsContainer, AppLayoutTabsContainer } from 'features/shared/app-layout/AppLayoutTabs';
import { useTranslation } from 'react-i18next';
import { useMatchesAny } from 'lib/routing';

type CaseStatusTabs = 'timeline' | 'history';
const TABS: { href: CaseStatusTabs, translationKey: string }[] = [
  {
    href: 'timeline',
    translationKey: 'recoveryJourney',
  },
  {
    href: 'history',
    translationKey: 'history',
  },
];
const DEFAULT_TAB_URL = TABS[0].href;
const TAB_HTML_ID_BASE = 'case-status';

export function a11yTabProps(htmlIdBase: string, tabValue: string | number) {
  return {
    id: `${htmlIdBase}-tab-${tabValue}`,
    'aria-controls': `${htmlIdBase}-tabpanel-${tabValue}`,
  };
}

export default function CaseStatus() {
  const url = useLocation();
  const isHistoryTab = useMatchesAny(['case/status/history/:id', 'case/status/history']);
  const currentTabUrl = (() => {
    if (isHistoryTab) return 'history';
    if (matchPath('case/status/timeline', url.pathname)) return 'timeline';
    return DEFAULT_TAB_URL;
  })();
  const { t } = useTranslation('translation', { keyPrefix: 'caseStatus' });

  return (
    <AppLayoutTabsContainer>
      <AppLayoutTabs
        value={currentTabUrl}
        aria-label={t('a11y.tabPanelLabel')!}
      >
        {TABS.map(({ href, translationKey }) => (
          <Tab
            value={href}
            to={href}
            label={t(`tabLabels.${translationKey}`)}
            component={Link}
            key={`case-status-tab-${href}`}
            {...a11yTabProps(TAB_HTML_ID_BASE, href)}
          />
        ))}
      </AppLayoutTabs>

      <AppLayoutTabPanelsContainer>
        {TABS.map(({ href }) => (
          <TabPanel
            key={href}
            referenceValue={href}
            value={currentTabUrl}
            htmlIdBase={TAB_HTML_ID_BASE}
          >
            <Outlet />
          </TabPanel>
        ))}

        {!TABS.some(({ href }) => href === currentTabUrl) && (
          <Outlet />
        )}
      </AppLayoutTabPanelsContainer>
    </AppLayoutTabsContainer>
  );
}
