import {
  ListItemButton, ListItemText, ListItemSecondaryAction, IconButton,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';

type ListItemButtonWithSelectedIconProps = {
  selected: boolean;
  onClick(): void;
  children: React.ReactNode;
}

export default function ListItemButtonWithSelectedIcon({
  selected, onClick, children,
}: ListItemButtonWithSelectedIconProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'uiElements.icons' });

  return (
    <ListItemButton
      selected={selected}
      onClick={onClick}
    >
      <ListItemText
        disableTypography
        sx={{ ...(selected && { color: 'primary.main', fontWeight: 500 }) }}
      >
        {children}
      </ListItemText>
      {selected && (
        <ListItemSecondaryAction>
          <IconButton disableRipple edge="end">
            <DoneIcon color="primary" aria-label={t('selected')} />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
}
