import { Button, CardContent, Link } from '@mui/material';
import TextField from 'lib/TextField';
import PasswordTextField from 'lib/PasswordTextField';
import * as Yup from 'yup';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Form from 'features/shared/form/Form';
import { FormProvider } from 'react-hook-form';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useLoginMutation from './api/useLoginMutation';
import FormCard from '../internal/FormCard';

type FormValues = {
  user: string;
  password: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  user: Yup.string().required(),
  password: Yup.string().required(),
});

export default function Login() {
  const { t } = useTranslation('translation', { keyPrefix: 'login' });
  const { mutateAsync: login } = useLoginMutation();

  const formMethods = useForm<FormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
  });
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = formMethods;

  const onFormSubmit = async ({ user, password }: FormValues) => {
    await login({ user, password });
  };

  return (
    <GenericPageLayout pageTitle={t('title')!} disableGutters disableMargins centerContent="on-desktop">
      <FormCard title={t('title')!}>
        <CardContent>
          <FormProvider {...formMethods}>
            <Form
              onSubmit={handleSubmit(onFormSubmit)}
              errors={errors}
              isSubmitting={isSubmitting}
              submitButtonLabel={t('button')}
            >
              <TextField
                fullWidth
                {...register('user')}
                label={t('userInputLabel')}
                errors={errors.user}
              />
              <PasswordTextField
                fullWidth
                {...register('password')}
                type="password"
                label={t('passwordInputLabel')}
                errors={errors.password}
              />
            </Form>
          </FormProvider>
          <Button
            fullWidth
            component={Link}
            href="/forgot-password"
            size="large"
            sx={{ mt: 2 }}
          >
            {t('forgotPassword')}
          </Button>
        </CardContent>
      </FormCard>
    </GenericPageLayout>
  );
}
