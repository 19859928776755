import {
  Box, Button, Collapse, Container, IconButton, Typography,
} from '@mui/material';
import {
  DriveFolderUploadOutlined as DriveFolderUploadOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  ReactNode, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import FileUploader from 'lib/FileUploader';
import useConfirmationForAction from 'lib/useConfirmationForAction';
import AppLayoutPageTitle from 'features/shared/app-layout/AppLayoutPageTitle';
import { grey } from '@mui/material/colors';
import useFilesUpload from 'features/shared/use-files-upload/useFilesUpload';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import GenericLoading from 'features/shared/GenericLoading';
import DataFetchingError from 'features/shared/DataFetchingError';
import { remove } from 'ramda';
import { TransitionGroup } from 'react-transition-group';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import { FileUploaderContent } from './FileUploaderContent';
import FileCard from './FileCard';
import ClearUploadsDialog from './ClearUploadsDialog';
import ErrorCard from './shared/ErrorCard';

const TRANSLATION_KEY_PREFIX = 'fileUpload';
const MAX_FILE_SIZE_IN_MB = 4 * 1024;

export default function Upload() {
  const { t } = useTranslation('translation', { keyPrefix: TRANSLATION_KEY_PREFIX });

  const { uploadFile, fileUploads, dismissAllFileUploads } = useFilesUpload();

  const [
    clearUploads,
    isClearUploadsDialogOpen,
    confirmClearUploads,
    cancelClearUploads,
  ] = useConfirmationForAction(
    dismissAllFileUploads,
    { when: fileUploads.some((upload) => upload.status === 'loading') },
  );

  const [isDraggingFileOver, setIsDraggingFileOver] = useState(false);

  const { uploaderErrors, pushUploaderError, dismissUploaderError } = useUploaderErrors();

  const { isLoading: isCurrentCaseLoading, isError: isCurrentCaseError } = useCurrentCase();

  const browseButtonRef = useRef<HTMLButtonElement>(null);

  if (isCurrentCaseError) {
    return <Layout pageTitle={t('pageTitle')} centerContent><DataFetchingError /></Layout>;
  }

  if (isCurrentCaseLoading) {
    return <Layout pageTitle={t('pageTitle')} centerContent><GenericLoading /></Layout>;
  }

  return (
    <Layout pageTitle={t('pageTitle')}>
      <FileUploader
        browseButtonRef={browseButtonRef}
        classes="file-upload-wrapper"
        handleChange={(fileList) => Array.from(fileList).forEach(uploadFile)}
        name="file"
        onSizeError={() => pushUploaderError(t('error.size'))}
        hoverTitle=" "
        onDraggingStateChange={setIsDraggingFileOver}
        multiple
        maxSize={MAX_FILE_SIZE_IN_MB}
      >
        <FileUploaderContent
          className="description-wrapper"
          isDraggingFileOver={isDraggingFileOver}
        >
          <DriveFolderUploadOutlinedIcon sx={{ fontSize: '96px', color: grey[400] }} />

          <Typography fontWeight={500} color="text.primary" sx={{ whiteSpace: 'pre-line', mb: 1 }}>
            {t('primaryHelperText')}
          </Typography>

          <Typography sx={{ textTransform: 'uppercase', mb: 2 }}>
            {t('divider')}
          </Typography>

          <Button
            ref={browseButtonRef}
            variant="contained"
            size="large"
            sx={{ mb: 2 }}
          >
            {t('browseButton')}
          </Button>

          <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {t('secondaryHelperText', { maxSize: `${MAX_FILE_SIZE_IN_MB / 1024}GB` })}
          </Typography>

          {fileUploads.length > 0 && (
            <Button
              size="small"
              sx={{ mt: 3 }}
              onClick={clearUploads}
            >
              {t('clearButton')}
            </Button>
          )}

          <Box sx={{ mt: 3 }}>
            <TransitionGroup>
              {uploaderErrors.map((error, errorIndex) => (
                <Collapse unmountOnExit key={error.time}>
                  <ErrorCard
                    key={error.time}
                    action={(
                      <IconButton
                        size="small"
                        aria-label="clear"
                        onClick={() => dismissUploaderError(errorIndex)}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  >
                    {error.text}
                  </ErrorCard>
                </Collapse>
              ))}

              {fileUploads.map((fileUpload) => (
                <Collapse unmountOnExit key={fileUpload.file.name}>
                  <FileCard
                    key={fileUpload.file.name}
                    name={fileUpload.file.name}
                    status={fileUpload.status}
                    onRetry={fileUpload.retry}
                    onDismiss={fileUpload.dismiss}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </Box>
        </FileUploaderContent>
      </FileUploader>

      <ClearUploadsDialog
        open={isClearUploadsDialogOpen}
        onClose={cancelClearUploads}
        onWaitPending={() => dismissAllFileUploads((status) => status !== 'loading')}
        onClearAll={confirmClearUploads}
      />
    </Layout>
  );
}

function useUploaderErrors() {
  const [uploaderErrors, setUploaderErrors] = useState<{ time: number; text: string }[]>([]);

  const pushUploaderError = (text: string) => {
    setUploaderErrors((prev) => [...prev, { time: Date.now(), text }]);
  };

  const dismissUploaderError = (index: number) => {
    setUploaderErrors(remove(index, 1));
  };

  return {
    uploaderErrors,
    pushUploaderError,
    dismissUploaderError,
  };
}

type LayoutProps = {
  pageTitle: string;
  children: ReactNode;
  centerContent?: boolean;
};

function Layout({ pageTitle, children, centerContent = false }: LayoutProps) {
  if (centerContent) {
    return <GenericPageLayout pageTitle={pageTitle} centerContent>{children}</GenericPageLayout>;
  }

  return (
    <Container maxWidth={false}>
      <AppLayoutPageTitle>{pageTitle}</AppLayoutPageTitle>
      {children}
    </Container>
  );
}
