/* eslint-disable react/no-danger */
import {
  Box, Card as MuiCard, CardActionArea, CardContent, Collapse, Stack,
  Typography, Icon, styled, css, alpha, keyframes,
} from '@mui/material';
import {
  capitalize, greaterThanDays, sanitizeHTML, toIsoDate,
} from 'lib/utils';
import ExpandCollapseButton from 'features/shared/ExpandCollapseButton';
import { titleize } from 'inflected';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import useToggle from 'lib/useToggle';
import useDismissNotification from 'features/shared/api/useDismissNotification';
import { forwardRef, useRef } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { HistoryWithNotificationStatus } from './useHistoriesWithNotificationStatus';

type HistoryCardProps = {
  item: HistoryWithNotificationStatus;
  initiallyHighlighted?: boolean;
}

const HistoryCard = forwardRef((
  { item, initiallyHighlighted = false }: HistoryCardProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const {
    date, details, iconRead, iconUnread, kind, subject,
    isUnread = false, notificationId = null, relationships: { creator },
  } = item;
  const title = kind[0];
  const [isExpanded, toggleIsExpanded] = useToggle(initiallyHighlighted);
  const iconUrl = isUnread ? iconUnread : iconRead;
  const isMobile = useIsMobileScreen();
  const isExpandable = details && creator;
  const { mutateAsync: markAsRead } = useDismissNotification();
  const { formatDate, formatRelativeDate } = useLocalizedDateFormatFns();
  const [elementRef, isReadyForHighlightAnimation] = useTrackHighlightAnimationReadyState({
    enabled: initiallyHighlighted,
  });

  const onExpand = () => {
    toggleIsExpanded();
    if (isUnread && !isExpanded) {
      markAsRead(notificationId!);
    }
  };

  return (
    <Card
      ref={elementRef}
      initiallyHighlighted={initiallyHighlighted}
      animate={isReadyForHighlightAnimation}
    >
      <CardContent
        ref={ref}
        component={isMobile && isExpandable ? CardActionArea : 'div'}
        sx={{ '&:last-child': { pb: 0 }, p: 0 }}
        onClickCapture={isMobile && isExpandable ? toggleIsExpanded : undefined}
      >
        <Stack
          direction="row"
          sx={{
            borderBottom: '1px solid',
            borderBottomColor: `${isExpandable && isExpanded ? 'grey.300' : 'transparent'}`,
            p: 1,
            width: 1,
          }}
        >
          <Box sx={{
            alignItems: 'center', display: 'flex', justifyContent: 'center', padding: '0 0.5rem',
          }}
          >
            <Icon>
              <img src={iconUrl} alt="icon" style={{ height: '30px', width: '30px', padding: '2px' }} />
            </Icon>
          </Box>
          <Box sx={{ width: 1, marginLeft: '0.5rem' }}>
            <Stack>
              <Typography
                variant="caption"
                component="time"
                dateTime={toIsoDate(date)}
                sx={{ color: 'text.disabled' }}
              >
                {
                  greaterThanDays(date, -7)
                    ? titleize(formatDate(date))
                    : capitalize(formatRelativeDate(date))
                }
              </Typography>
              <Typography variant="body2" noWrap sx={{ fontWeight: 500, width: 'min(50vw, 450px);' }}>
                {title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {subject}
              </Typography>
            </Stack>
          </Box>
          {isExpandable && (
            <Box sx={{ alignItems: 'center', display: 'inline-flex', justifyContent: 'center' }}>
              <ExpandCollapseButton
                expanded={isExpanded}
                onClick={onExpand}
                edge="end"
                {...(isMobile && { component: 'span' })}
              />
            </Box>
          )}
        </Stack>
      </CardContent>
      {isExpandable && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <Box sx={{ p: '1.25rem' }}>
            {details && (
              <Box
                component="p"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(details) }}
                sx={{ m: 0, mb: 2 }}
              />
            )}
            {creator && (
              <Typography variant="body2" sx={{ color: 'text.disabled' }}>
                {creator.name}
              </Typography>
            )}
          </Box>
        </Collapse>
      )}
    </Card>
  );
});

function useTrackHighlightAnimationReadyState({ enabled = true }: { enabled?: boolean }) {
  const [
    elementRef,
    { isVisible: isInViewport, wasEverVisible: wasEverInViewport },
  ] = useTrackVisibility();

  const isReadyForHighlightAnimationRef = useRef(false);
  if (enabled && isInViewport && !wasEverInViewport) {
    isReadyForHighlightAnimationRef.current = true;
  }

  return [
    elementRef,
    isReadyForHighlightAnimationRef.current,
  ] as const;
}

type CardOwnProps = {
  initiallyHighlighted?: boolean;
  animate?: boolean;
}
const Card = styled(
  MuiCard,
  { shouldForwardProp: isPropValid },
)<CardOwnProps>(({ theme, initiallyHighlighted = false, animate = false }) => {
  const highlightColor = theme.palette.primary.main;

  const rippleAnimation = keyframes`
    0% { box-shadow: 0 0 0 2px ${alpha(highlightColor, 0.5)}; }
    50% { box-shadow: 0 0 0 3px ${alpha(highlightColor, 0.3)}; }
    100% { box-shadow: 0 0 0 4px ${alpha(highlightColor, 0)}; }
  `;

  return css`
    width: 100%;
    margin-bottom: ${theme.spacing(2)};

    ${initiallyHighlighted && css`
      box-shadow: 0 0 0 2px ${alpha(highlightColor, 0.5)};
    `}

    ${animate && css`
      animation: ${rippleAnimation} 200ms linear 500ms both;
    `}
  `;
});

export default HistoryCard;
