import { css, styled } from '@mui/material/styles';
import {
  createContext, RefObject, useContext, useEffect, useRef,
} from 'react';

export const PageTitleContext = createContext<{
  ref: RefObject<HTMLElement> | null,
  setRef:(ref: RefObject<HTMLElement> | null) => void,
    }>({ ref: null, setRef: () => {} });

export default function PageTitle() {
  const ref = useRef<HTMLElement>(null);
  const { setRef } = useContext(PageTitleContext);

  useEffect(() => {
    setRef(ref);

    return () => {
      setRef(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TitleText ref={ref} />;
}

const TitleText = styled('span')(({ theme }) => css`
  font-weight: 500;

  ${theme.breakpoints.up('md')} {
    font-weight: 400;
    font-size: 1.125rem;
  }
`);
