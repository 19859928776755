import { Components, css, Theme } from '@mui/material';

export default function createMuiBottomNavigationAction(theme: Theme): NonNullable<Components<Theme>['MuiBottomNavigationAction']> {
  return ({
    styleOverrides: {
      root: css`
        height: ${theme.sizes.bottomNavigationHeight}px;
      `,
      label: css`
        &, &.Mui-selected {
          font-size: 0.75rem;
        }
      `,
    },
  });
}
