import { createApiClient } from 'features/shared/api/client';
import useQuery from 'features/shared/api/useQuery';
import { deserializeCustomResponse } from 'features/shared/api/deserialize';
import { filter, propEq } from 'ramda';
import { toApiUrl } from 'features/shared/api/request';
import { renameKeys } from 'lib/utils';

const REQUEST_URL = toApiUrl('Notification');
export const QUERY_KEY = 'notifications';

// TODO: Update NotificationKind with all available values
export type NotificationKind = string;
export type NotificationReadStatus = 'Unread' | 'Read';

export type Notification = {
  id: string;
  actionExecuteRequestId: string | null;
  actionId: string | null;
  actions: string[];
  contents: string;
  createDate: Date;
  entityId: string;
  name: string;
  notificationDate: Date;
  readStatus: NotificationReadStatus;
  recordId: string;
  requestId: string | null;
  status: string;
  timeSensitive: boolean;
  type: NotificationKind;
}

const apiClient = createApiClient();

type UseNotificationsOptions = {
  refetchInterval: false | number;
  enabled: boolean;
}
export default function useNotifications(
  { refetchInterval, enabled }: UseNotificationsOptions = { refetchInterval: false, enabled: true },
) {
  return useQuery(
    [QUERY_KEY],
    async () => {
      return (await apiClient.get(REQUEST_URL)).data;
    },
    {
      select: (data) => {
        const parsedData = data.data.map(renameKeys({ priority: 'timeSensitive' }));
        return deserializeCustomResponse<Notification[]>(parsedData);
      },
      refetchInterval,
      enabled,
    },
  );
}

export function useSensitiveNotificationsCount(
  { refetchInterval, enabled }: UseNotificationsOptions = { refetchInterval: false, enabled: true },
) {
  const { data: notifications = [] } = useNotifications({ refetchInterval, enabled });

  return filter(propEq('readStatus', 'Unread'), notifications).length;
}
