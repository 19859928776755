import { useFileUpload } from './internal/api/useFileUpload';
import useSelectedFiles from './internal/useSelectedFiles';

export default function useFilesUpload() {
  const { mutateAsync: callUploadFileMutation } = useFileUpload();
  const { fileUploads: allFileUploads, dismissAllFileUploads } = useSelectedFiles();
  const fileUploads = allFileUploads.filter((upload) => !upload.isDismissed);

  const uploadFile = (file: File) => {
    callUploadFileMutation({
      file,
      abortController: new AbortController(),
    });
  };

  return {
    uploadFile,
    fileUploads,
    dismissAllFileUploads,
  };
}
