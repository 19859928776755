import { Components, Theme } from '@mui/material';

export default function createMuiToolbar(theme: Theme): NonNullable<Components<Theme>['MuiToolbar']> {
  return ({
    styleOverrides: {
      root: {
        height: theme.sizes.toolbarHeight,
        minHeight: 'auto',
      },
      dense: {
        height: theme.sizes.toolbarDenseHeight,
      },
    },
  });
}
