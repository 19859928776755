import {
  Button, CardContent, Link, Stack, Typography,
} from '@mui/material';
import TextField from 'lib/TextField';
import * as Yup from 'yup';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import Lines from 'lib/Lines';
import Form from 'features/shared/form/Form';
import { FormProvider } from 'react-hook-form';
import { useState } from 'react';
import EnvelopeImage from 'features/shared/graphics/Envelope';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import FormCard from '../internal/FormCard';
import useForgotPasswordMutation from './api/useForgotPasswordMutation';

type FormValues = {
  user: string;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  user: Yup.string().required(),
});

export default function ForgotPassword() {
  const { t } = useTranslation('translation', { keyPrefix: 'forgotPassword' });
  const { mutateAsync: requestPasswordReset } = useForgotPasswordMutation();
  const [successUsername, setSuccessEmail] = useState<string | null>(null);

  const formMethods = useForm<FormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
    defaultValues: {
      user: '',
    },
  });
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = formMethods;

  const onFormSubmit = async ({ user }: FormValues) => {
    return requestPasswordReset({ user })
      .then(() => setSuccessEmail(user));
  };

  return (
    <GenericPageLayout pageTitle={t('title')!} disableGutters disableMargins centerContent="on-desktop">
      <FormCard title={t('title')!}>
        <CardContent>
          {successUsername
            ? <SuccessCard username={successUsername} />
            : (
              <>
                <FormProvider {...formMethods}>
                  <Form
                    onSubmit={handleSubmit(onFormSubmit)}
                    errors={errors}
                    isSubmitting={isSubmitting}
                    submitButtonLabel={t('button')}
                  >
                    <Typography>
                      <Lines of={t('description', { returnObjects: true })} />
                    </Typography>
                    <TextField
                      fullWidth
                      {...register('user')}
                      label={t('userInputLabel')}
                      errors={errors.user}
                    />
                  </Form>
                </FormProvider>
                <Button
                  fullWidth
                  component={Link}
                  href="/login"
                  variant="outlined"
                  size="large"
                  sx={{ mt: 2 }}
                >
                  {t('cancel')}
                </Button>
              </>
            )}
        </CardContent>
      </FormCard>
    </GenericPageLayout>
  );
}
type SuccessCardProps = {
  username: string;
}
function SuccessCard({ username }: SuccessCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'forgotPassword.successState' });
  return (
    <Stack alignItems="center">
      <EnvelopeImage />
      <Typography fontWeight="medium" textAlign="center" sx={{ mt: 2 }}>
        {t('title')}
      </Typography>
      <Typography color="text.secondary" textAlign="center" sx={{ mt: 1 }}>
        <Trans t={t} i18nKey="description">
          An email has been sent to the account associated with
          {' '}
          <strong>{{ username }}</strong>
          .
          In case you don’t see the email in your inbox check also your spam folder.
        </Trans>
      </Typography>
    </Stack>
  );
}
