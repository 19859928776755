import {
  Box, Card, CardContent, IconButton, Stack, Typography, css, styled,
  CardActionArea, CardHeader, CardActions, Link, alpha,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type DashboardCardProps = {
  href: string;
  title: string;
  children: string | React.ReactNode;
  icon: React.ReactNode;
  headerContent?: React.ReactNode;
  footerContent?: React.ReactNode;
};

export default function DashboardCard({
  href, title, children, icon, headerContent, footerContent,
}: DashboardCardProps) {
  const cardContent = typeof children === 'string'
    ? (
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {children}
      </Typography>
    )
    : children;
  const cardHeader = headerContent && (
    <CardHeader
      disableTypography
      sx={{
        py: 1,
        bgcolor: 'grey.50',
      }}
      title={headerContent}
    />
  );
  const cardFooter = footerContent && (
    <CardActions sx={{
      py: 1,
      px: 2,
      bgcolor: 'grey.50',
      borderTop: (theme) => `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
    }}
    >
      {footerContent}
    </CardActions>
  );

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      {cardHeader}
      <CardActionArea component={Link} href={href}>
        <CardContent sx={{ py: 1 }}>
          <Stack direction="row" spacing={2}>
            <IconWrapper>
              {icon}
            </IconWrapper>
            <Stack sx={{ flex: '1 1 0' }}>
              <Typography variant="subtitle1">
                {title}
              </Typography>
              {cardContent}
            </Stack>
            <Box sx={{ alignItems: 'center', display: 'inline-flex', justifyContent: 'center' }}>
              <IconButton edge="end" size="small">
                <ChevronRightIcon />
              </IconButton>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
      {cardFooter}
    </Card>
  );
}

const IconWrapper = styled('div')(({ theme }) => css`
  align-items: center;
  color: ${theme.palette.text.secondary};
  display: flex;
  font-size: ${theme.spacing(5)};
  justify-content: center;
  & svg {
    font-size: inherit;
  }
`);
