import { CSSObject, Theme, TypographyVariant } from '@mui/material';
import { map } from 'ramda';

export const createTypographyGlobalStyles = (theme: Theme): CSSObject => {
  return {
    ...mapTypographyStylesToNativeElements(theme, {
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
    }),
    p: { marginTop: 0, marginBottom: 0 },
  };
};
const mapTypographyStylesToNativeElements = (
  theme: Theme,
  hash: Record<string, TypographyVariant>,
): CSSObject => map(
  (variant) => ({ margin: 0, ...theme.typography[variant] }),
  hash,
);
