import AppLayout from 'features/shared/app-layout/AppLayout';
import { ReactNode } from 'react';
import useInvariantLayoutProps from './useInvariantLayoutProps';

export default function PageNotFoundAppLayout({ children }: { children: ReactNode; }) {
  const invariantProps = useInvariantLayoutProps();

  return (
    <AppLayout {...invariantProps} hideNavigation showPageTitle={false}>{children}</AppLayout>
  );
}
