import axios from 'axios';
import { dissoc } from 'ramda';
import {
  FieldValues, Path, useForm as rhfUseForm, UseFormProps, UseFormReturn,
} from 'react-hook-form';
import { getApiErrorMessages, isApiError } from './api/errors';

export function useForm<
  TOwnFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TFieldValues extends { base: string } = TOwnFieldValues & { base: string },
>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> {
  const methods = rhfUseForm<TFieldValues, TContext>(props);
  // @ts-ignore
  methods.register('base');

  return {
    ...methods,
    handleSubmit: (onSubmit, onError) => {
      if (onError) {
        throw new Error(
          '`useForm` wrapper does not support the `onError` handler'
          + ' as the error handling is encapsulated in the hook.',
        );
      }

      const handler = methods.handleSubmit((data, event) => onSubmit(dissoc('base', data), event));

      return async (event) => {
        try {
          await handler(event);
        } catch (error) {
          if (!axios.isAxiosError(error)) {
            throw error;
          }

          if (!isApiError(error)) {
            methods.setError('base' as Path<TFieldValues>, { type: 'custom', message: 'Something went wrong.' });
            return;
          }

          const errorMessages = getApiErrorMessages(error);

          Object.entries(errorMessages).forEach(([fieldName, message]) => {
            // TODO - `message` should not need non-null assertion because it can't be `undefined`
            methods.setError(fieldName as Path<TFieldValues>, { type: 'custom', message: message! });
          });
        }
      };
    },
  };
}
