import { createApiClient } from 'features/shared/api/client';
import useQuery from 'features/shared/api/useQuery';
import { RelationshipUser } from 'features/dashboard/api/useUsers';
import deserialize from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import { RelationshipClient } from 'features/shared/api/useClients';
import { RelationshipHistory } from 'features/case-status/histories/api/useHistories';

const REQUEST_URL = toApiUrl('JourneyStep');

export type JourneyStep = {
  type: 'JourneyStep';
  id: string;
  createDate: Date;
  createUserId: string;
  description: string;
  duration: string;
  journeyStepId: string;
  modifyDate: Date;
  modifyUserId: string;
  name: string;
  order: number;
  ownerId: string;
  recordState: string;
  relationships: {
    // The clients relationship is missing for future steps
    clients?: RelationshipClient[];
    owner: RelationshipUser;
    creator: RelationshipUser;
    editor: RelationshipUser;
    journeys: RelationshipJourney[];
    histories?: RelationshipHistory[];
  };
}

export type RelationshipJourney = {
  type: 'Journey';
  id: string;
  name: string;
}

export type RelationshipJourneyStep = {
  type: 'JourneyStep';
  id: string;
  name: string;
  state: string;
  startDate: Date;
  endDate: Date;
}

const apiClient = createApiClient();

export const useJourneySteps = () => {
  return useQuery(
    ['journeySteps'],
    async () => {
      return (await apiClient.get(REQUEST_URL)).data;
    },
    {
      select: (data) => deserialize<JourneyStep[]>(data),
    },
  );
};

export const useJourneyStep = (stepId: string) => {
  return useQuery(
    ['journeyStep', stepId],
    async () => {
      return (await apiClient.get(`${REQUEST_URL}/${stepId}`)).data;
    },
    {
      select: (data) => deserialize<JourneyStep>(data),
      enabled: Boolean(stepId),
    },
  );
};
