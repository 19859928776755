import GenericBanner from 'features/shared/generic-banner/GenericBanner';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';

type InviteSentBannerProps = {
  expanded: boolean;
  hidden?: boolean;
  onClick?: () => void;
}

export default function InviteSentBanner({
  expanded = false, hidden = false, onClick,
}: InviteSentBannerProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.shareCase' });
  return (
    <GenericBanner
      backgroundColor="success.main"
      title={t('inviteSentBanner')}
      Icon={EmailIcon}
      visuallyHidden={hidden}
      expanded={expanded}
      onClick={onClick}
      noBottomGutterOnMobile
    />
  );
}
