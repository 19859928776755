import { Fragment } from 'react';

/** Renders a fragment of `<br />` separated lines out of an array of strings.  */
export default function Lines({ of: lines }: { of: string[] }) {
  return (
    <>
      {lines.map((line, lineIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={lineIndex}>
          {lineIndex > 0 && <br />}
          {line}
        </Fragment>
      ))}
    </>
  );
}
