/* eslint-disable no-param-reassign */
import {
  binary, curry, has, mapObjIndexed,
} from 'ramda';
import { camelize, pascalize } from '../string';
import { isNonNullObject } from '../type';

/**
 * @example
 * isObjOf('foo', { foo: 'bar' }); //=> true
 * isObjOf('foo', {}); //=> false
 * isObjOf('foo', { foo: 'bar', mortal: 'kombat' }); //=> false
 */
export const isObjOf = curry((key: string, obj: any) => (
  has(key, obj) && Object.keys(obj).length === 1
));

export const makeKeysTransformer = (transformKey: (key: string) => string) => {
  return function transformKeys(
    object: { [key: string]: any },
    { deep = false }: { deep?: boolean } = {},
  ) {
    return Object
      .entries(object)
      .reduce<{ [key: string]: any }>((carry, [key, value]) => {
        let nextValue = value;
        if (deep) {
          if (Array.isArray(value)) {
            nextValue = value.map(
              (obj) => (isNonNullObject(obj) ? transformKeys(obj, { deep }) : obj),
            );
          } else if (isNonNullObject(value)) {
            nextValue = transformKeys(value, { deep });
          }
        }

        carry[transformKey(key)] = nextValue;
        return carry;
      }, {});
  };
};

export const camelizeKeys = makeKeysTransformer(camelize);

export const pascalizeKeys = makeKeysTransformer(pascalize);

/**
 * {@link https://stackoverflow.com/a/56220677/3760848}
 * @example
 *
 * renameKeys({ personName: 'name' })({ personName: 'Sam' }); //=> { name: 'Sam' };
*/
export const renameKeys = (
  keysMap: { [K: string]: string },
) => (obj: any) => Object.entries(obj).reduce(
  (a, [k, v]) => (k in keysMap ? { ...a, [keysMap[k]]: v } : { ...a, [k]: v }),
  {},
);

const mapObjDeepRec = <T, U>(
  fn: (value: T, key: string, object: any) => U,
  value: any,
  key?: string,
  object?: any,
): any => {
  if (Array.isArray(value)) return value.map((x, i) => mapObjDeepRec(fn, x, String(i), value));
  if (isNonNullObject(value)) return mapObjIndexed((...args) => mapObjDeepRec(fn, ...args), value);
  return fn(value, key!, object);
};

/**
 * Works as {@link https://ramdajs.com/docs/#mapObjIndexed R.mapObjIndexed} except it goes recursively
 * over the entire object and iterates over both descendant arrays and objects.
 * Descendant arrays are being mapped using `Array.prototype.map`,
 * while objects are being mapped using `mapObjIndexed`.
 *
 * The supplied function has the same signature as the one accepted by `mapObjIndexed`.
 * When an array is being mapped, `key` holds a string representing `value`'s index in the array.
 *
 * Unlike ramda's map function, this one doesn't iterate over
 * non-array iterable objects, array-like objects or functors.
 *
 * For more examples check the specs.
 *
 * @example
 *
 * mapObjDeep(R.toUpper, { x: 'foo', y: { z: 'bar' } }) //=> { x: 'FOO', y: { z: 'BAR' } }
 */
export const mapObjDeep = curry(binary(mapObjDeepRec));
