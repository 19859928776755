import { Typography } from '@mui/material';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import GenericLoading from 'features/shared/GenericLoading';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import { useTranslation } from 'react-i18next';
import CurrentCaseDetails from 'features/shared/CurrentCaseDetails';
import { useState } from 'react';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import ShareByLink from './ShareByLink';
import CaseInvitees from './CaseInvitees';
import ShareWithPeopleForm from './ShareWithPeopleForm';
import InviteSentBanner from './InviteSentBanner';

export default function ShareCase() {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.shareCase' });
  const { currentCase, isLoading, isError } = useCurrentCase();
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const isMobile = useIsMobileScreen();

  const onSuccess = () => {
    setShowSuccessBanner(true);
    setTimeout(() => {
      setShowSuccessBanner(false);
    }, 5000);
  };

  const onSuccessBannerClick = () => setShowSuccessBanner(false);

  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;
  if (isError || !currentCase) return <GenericPageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></GenericPageLayout>;

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <InviteSentBanner
        expanded={showSuccessBanner}
        onClick={onSuccessBannerClick}
      />
      {isMobile && (
        <Typography color="primary" fontWeight="medium">
          <CurrentCaseDetails />
        </Typography>
      )}
      <ShareByLink caseId={currentCase.id} />
      <ShareWithPeopleForm caseId={currentCase.id} onSuccess={onSuccess} />
      <CaseInvitees caseId={currentCase.id} />
      {isMobile && showSuccessBanner && (
        <InviteSentBanner
          expanded={showSuccessBanner}
          hidden
        />
      )}
    </GenericPageLayout>
  );
}
