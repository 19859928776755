import { createApiClient } from 'features/shared/api/client';
import useQuery from 'features/shared/api/useQuery';
import deserialize from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';

const REQUEST_URL = toApiUrl('CaseTeamMember');

export type CaseTeamMember = {
  type: 'CaseTeamMember';
  id: string;
  acceptCalls: boolean;
  acceptEmail: boolean;
  acceptSms: boolean;
  caseTeamMemberId: string;
  createDate: string;
  createUserId: string;
  email: string | null;
  firstName: string;
  lastName: string;
  modifyDate: string;
  modifyUserId: string;
  name: string;
  ownerId: string;
  phone: string | null;
  smsPhone: string | null;
  recordState: string;
  title: string[];
}

export type RelationshipCaseTeamMember = {
  type: 'CaseTeamMember';
  id: string;
  name: string;
  isPrimary: boolean;
}

const apiClient = createApiClient();

export const useCaseTeamMembers = () => {
  return useQuery(
    ['caseTeamMembers'],
    async () => {
      return (await apiClient.get(REQUEST_URL)).data;
    },
    {
      select: (data) => deserialize<CaseTeamMember[]>(data),
    },
  );
};
