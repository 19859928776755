import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useTranslation } from 'react-i18next';
import DashboardCard from '../DashboardCard';
import { DashboardData } from '../../api/useDashboard';
import CurrentStepsContent from './CurrentStepsContent';
import HistoryUpdates from './HistoryUpdates';
import CaseStatusProgress from '../../../shared/CaseStatusProgress';
import { I18N_PREFIX } from '.';

type CaseStatusCardProps = DashboardData['caseStatus'];

export default function CaseStatusCard({
  histories, currentStepsTitles, completedStepsCount, futureStepsCount,
}: CaseStatusCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: I18N_PREFIX });

  return (
    <DashboardCard
      href="/case/status/timeline"
      icon={<FactCheckIcon />}
      title={t('title')}
      headerContent={(
        <CaseStatusProgress
          currentStepsCount={currentStepsTitles.length}
          completedStepsCount={completedStepsCount}
          futureStepsCount={futureStepsCount}
        />
      )}
      footerContent={histories.unreadCount > 0 && <HistoryUpdates {...histories} />}
    >
      <CurrentStepsContent currentStepsTitles={currentStepsTitles} />
    </DashboardCard>
  );
}
