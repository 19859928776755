import { useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useClients } from 'features/shared/api/useClients';
import { useQueryClient } from 'react-query';
import CurrentCaseContext, { Client } from './CurrentCaseContext';
import { useCurrentUser } from '../session/auth';

type CurrentCaseProviderProps = {
  children: React.ReactNode;
};

export default function CurrentCaseProvider({ children }: CurrentCaseProviderProps) {
  const { currentUser } = useCurrentUser();
  const { data: clients = [], isLoading, isError } = useClients({ enabled: Boolean(currentUser) });
  const [currentCaseId, setCurrentCaseId] = useLocalStorage<string | undefined>('clientId', undefined);

  const queryClient = useQueryClient();

  const currentCase = useMemo(() => {
    if (isLoading || isError) {
      return null;
    }
    return clients!.find((c) => c.id === currentCaseId) || clients![0];
  }, [clients, currentCaseId, isError, isLoading]);

  const setCurrentCase = useCallback((client: Client | null) => {
    setCurrentCaseId(client?.id);
    queryClient.getQueryCache().clear();
  }, [setCurrentCaseId, queryClient]);

  useEffect(() => {
    if (currentCase && !currentCaseId) {
      setCurrentCaseId(currentCase.id);
    }
  }, [currentCase, currentCaseId, setCurrentCaseId]);

  const value = useMemo(
    () => ({
      currentCase, setCurrentCase, isLoading, isError,
    }),
    [currentCase, setCurrentCase, isError, isLoading],
  );

  return (
    <CurrentCaseContext.Provider value={value}>
      {children}
    </CurrentCaseContext.Provider>
  );
}
