import {
  Button,
  Card, CardContent, CardHeader, Typography,
} from '@mui/material';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useLogout from 'features/shared/session/useLogout';
import { useTranslation } from 'react-i18next';

export default function NoCaseAvailable() {
  const logout = useLogout(false);
  const { t } = useTranslation('translation', { keyPrefix: 'generic.noCaseAvailable' });

  return (
    <GenericPageLayout pageTitle={t('title')!} centerContent>
      <Card>
        <CardHeader title={t('title')} sx={{ textAlign: 'center' }} />
        <CardContent>
          <Typography textAlign="center" sx={{ mb: 2 }}>
            {t('description')}
          </Typography>

          <Button variant="contained" sx={{ width: '100%' }} onClick={logout}>
            {t('logout')}
          </Button>
        </CardContent>
      </Card>
    </GenericPageLayout>
  );
}
