import {
  FormControlLabel, Rating, MenuItem, FormControl,
} from '@mui/material';
import TextField from 'lib/TextField';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import * as Yup from 'yup';
import { Controller, FormProvider } from 'react-hook-form';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { isBlank } from 'lib/utils';
import { useState } from 'react';
import Form from 'features/shared/form/Form';
import useSendFeedbackMutation, { FeedbackFormValues } from './api/useSendFeedbackMutation';
import ProfileCard from '../shared/ProfileCard';
import ThankYouCard from './ThankYouCard';

const FEEDBACK_FOR_SELECT_OPTIONS: FeedbackFormValues['feedbackType'][] = ['app', 'firm'];

const VALIDATION_SCHEMA = Yup.object().shape({
  rating: Yup.number().required(),
  feedbackType: Yup.string().required(),
  comments: Yup.string(),
});

export default function SendFeedback() {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.sendFeedback' });
  const { mutateAsync: sendFeedback } = useSendFeedbackMutation();
  const [displayThankYou, setDisplayThankYou] = useState(false);

  const formMethods = useForm<FeedbackFormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
    defaultValues: {
      rating: 5,
      feedbackType: '',
      comments: '',
    },
  });
  const {
    control, reset, handleSubmit, formState: { errors, isSubmitting },
  } = formMethods;

  const onFormSubmit = async (props: FeedbackFormValues) => {
    return sendFeedback(props)
      .then(() => {
        reset();
        setDisplayThankYou(true);
      });
  };

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      {displayThankYou
        ? <ThankYouCard onClose={() => setDisplayThankYou(false)} />
        : (
          <ProfileCard title={t('form.title')}>
            <FormProvider {...formMethods}>
              <Form
                onSubmit={handleSubmit(onFormSubmit)}
                submitButtonLabel={t('form.submitButton.label')}
                isSubmitting={isSubmitting}
                errors={errors}
              >
                <TextField
                  name="feedbackType"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={t('form.feedbackType.label')}
                  select
                  defaultValue=""
                  variant="standard"
                  SelectProps={{
                    defaultValue: '',
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (isBlank(selected)) {
                        return t('form.feedbackType.placeholder');
                      }
                      return t(`form.feedbackType.options.${selected}`);
                    },
                  }}
                  errors={errors.feedbackType}
                >
                  {FEEDBACK_FOR_SELECT_OPTIONS.map((value) => (
                    <MenuItem key={value} value={value}>
                      {t(`form.feedbackType.options.${value}`)}
                    </MenuItem>
                  ))}
                </TextField>

                <Controller
                  name="rating"
                  control={control}
                  defaultValue={5}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <FormControl>
                      <FormControlLabel
                        componentsProps={{ typography: { fontSize: '0.75rem', color: 'text.secondary' } }}
                        label={t('form.rating.label')}
                        labelPlacement="top"
                        sx={{ alignItems: 'start', mx: 0 }}
                        control={(
                          <Rating
                            name={name}
                            size="large"
                            value={Number(value)}
                            getLabelText={(count) => t('form.rating.stars', { count })}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />

                <TextField
                  name="comments"
                  fullWidth
                  label={t('form.comments.label')}
                  multiline
                  errors={errors.comments}
                />
              </Form>
            </FormProvider>
          </ProfileCard>
        )}
    </GenericPageLayout>
  );
}
