import {
  Box, Card as MuiCard, css, Stack, styled,
} from '@mui/material';
import { CSSProperties, forwardRef, ReactNode } from 'react';
import isPropValid from '@emotion/is-prop-valid';

export type CardProps = {
  icon: ReactNode;
  action?: ReactNode;
  children: ReactNode;
  noWrap?: boolean;
  style?: CSSProperties;
};

const PADDING_Y = 1.5;

export default forwardRef<HTMLDivElement, CardProps>(function Card({
  icon, action, children, noWrap, style,
}, ref) {
  return (
    <MuiCard
      ref={ref}
      sx={{
        py: PADDING_Y, pl: 2, pr: 1, mb: 1,
      }}
      {...style && { style }}
    >
      <Stack direction="row">
        <Box sx={{ my: -PADDING_Y, display: 'flex', alignItems: 'center' }}>
          {icon}
        </Box>

        <TextContainer
          {...noWrap && { noWrap }}
          sx={{ ml: 2, flex: '1 1 auto' }}
        >
          {children}
        </TextContainer>

        {action && (
          <Box sx={{ my: -PADDING_Y, display: 'flex', alignItems: 'center' }}>
            {action}
          </Box>
        )}
      </Stack>
    </MuiCard>
  );
});

const TextContainer = styled('div', { shouldForwardProp: isPropValid })<{ noWrap?: boolean }>(({ noWrap, theme }) => css`
  color: ${theme.palette.text.secondary};
  text-align: left;

  ${noWrap && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  > .MuiTypography-root {
    display: inline;
  }
`);
