import { Components, Theme } from '@mui/material';

export default function createMuiListItemIcon(): NonNullable<Components<Theme>['MuiListItemIcon']> {
  return ({
    styleOverrides: {
      root: {
        minWidth: 40,
      },
    },
  });
}
