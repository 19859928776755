import { useContext } from 'react';
import Cookies from 'js-cookie';
import { useConfirm } from 'material-ui-confirm';
import AuthContext from './auth/AuthContext';

export default function useLogout(requestUserConfirmation = true) {
  const destroySession = useDestroySession();
  const confirm = useConfirm();

  if (!requestUserConfirmation) {
    return destroySession;
  }

  return async () => {
    try {
      await confirm({ dialogProps: { maxWidth: 'xs' } });
      destroySession();
    // eslint-disable-next-line no-empty
    } catch {}
  };
}

function useDestroySession() {
  const { setCurrentUser } = useContext(AuthContext);
  return () => {
    // Use non-secure cookie during development, for CORS reasons, while letting
    // the server set the auth token cookie securely on production.s
    if (process.env.NODE_ENV !== 'production') {
      Cookies.remove('token');
    }

    setCurrentUser(null);
  };
}
