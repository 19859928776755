import { List as MuiList, styled } from '@mui/material';
import { ReactNode } from 'react';
import { CORNERS_RADIUS } from '../../../styles';

export default function NavList({ children }: { children: ReactNode }) {
  return (
    <List disablePadding>
      {children}
    </List>
  );
}

const List = styled(MuiList)`
  flex-grow: 1;
  background: ${({ theme }) => theme.palette.background.paper};
  border-top-left-radius: ${CORNERS_RADIUS};
  overflow: auto;

  .MuiList-root .MuiListItemButton-root {
    padding-left: ${({ theme }) => theme.spacing(6)};
  }
`;
