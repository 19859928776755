import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useCurrentUser from 'features/shared/session/auth/useCurrentUser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import AccountInfoForm from './AccountInfoForm';
import ChangePasswordForm from './ChangePasswordForm';
import useUserDetails from './api/useUserDetails';
import SuccessBanner from './SuccessBanner';
import AddressForm from './AddressForm';

export default function AccountSettings() {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.accountSettings' });
  const { currentUser } = useCurrentUser();
  const { data: userDetails, isLoading, isError } = useUserDetails(currentUser?.id);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const isMobile = useIsMobileScreen();

  const onSuccess = () => {
    setShowSuccessBanner(true);
    setTimeout(() => {
      setShowSuccessBanner(false);
    }, 5000);
  };

  const onBannerClose = () => setShowSuccessBanner(false);

  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;
  if (isError || !userDetails) {
    return (
      <GenericPageLayout pageTitle={t('title')!} centerContent>
        <DataFetchingError />
      </GenericPageLayout>
    );
  }

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <SuccessBanner onClose={onBannerClose} expanded={showSuccessBanner} />
      <AccountInfoForm
        userId={currentUser!.id}
        cellPhone={userDetails.cellPhone}
        displayName={userDetails.displayName}
        email={userDetails.email}
        onSuccess={onSuccess}
      />
      <ChangePasswordForm
        userId={currentUser!.id}
        onSuccess={onSuccess}
      />
      <AddressForm
        userId={currentUser!.id}
        onSuccess={onSuccess}
        address1={userDetails.address1}
        address2={userDetails.address2}
      />
      {isMobile && showSuccessBanner && <SuccessBanner hidden />}
    </GenericPageLayout>
  );
}
