import {
  TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent,
} from '@mui/lab';
import { RecoveryJourneyStep } from '../api/useRecoveryJourney';
import TimelineStepCard from './TimelineStepCard';

type TimelineStepsCardsProps = {
  accentColor: string;
  steps: RecoveryJourneyStep[];
}

/** Renders the inner timeline for a section (eg. Completed) */
export default function TimelineStepsCards({ accentColor, steps }: TimelineStepsCardsProps) {
  return (
    <>
      {
        steps.map((step: RecoveryJourneyStep) => {
          return (
            <TimelineItem key={step.id}>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot sx={{
                  bgcolor: accentColor,
                  borderColor: accentColor,
                  boxShadow: 'none',
                }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <TimelineStepCard
                  accentColor={accentColor}
                  historyUpdate={{
                    ...step.lastUnreadHistory,
                    unreadCount: step.unreadHistoriesCount,
                  }}
                  step={step}
                />
              </TimelineContent>
            </TimelineItem>
          );
        })
      }
    </>
  );
}
