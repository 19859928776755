import { createApiClient } from 'features/shared/api/client';
import useQuery from 'features/shared/api/useQuery';
import deserialize from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import { User } from 'features/profile/account-settings/api/useUserDetails';

const REQUEST_URL = toApiUrl('User');

const apiClient = createApiClient();

export const useCaseInvitees = (caseId: string, currentUserId: string) => {
  return useQuery(
    getCaseInviteesQueryKey(caseId),
    async () => {
      return (await apiClient.get(REQUEST_URL, { params: { RelatedId: caseId } })).data;
    },
    {
      select: (data) => {
        // sort users so the current user is the first shown
        const caseUsers = deserialize<User[]>(data);
        const ownUserIndex = caseUsers.findIndex((caseUser) => caseUser.id === currentUserId);
        const ownUser = caseUsers.splice(ownUserIndex, 1);
        return [...ownUser, ...caseUsers];
      },
      enabled: Boolean(caseId),
    },
  );
};

export const getCaseInviteesQueryKey = (caseId: string) => {
  return ['useCaseInvitee', caseId];
};

export type { User };
