import { Components, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function createMuiTabs(): NonNullable<Components<Theme>['MuiTabs']> {
  return ({
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${grey[300]}`,
      },
    },
  });
}
