import { RelationshipUser } from 'features/dashboard/api/useUsers';
import deserialize from 'features/shared/api/deserialize';
import { createApiClient } from 'features/shared/api/client';
import { toApiUrl } from 'features/shared/api/request';
import useQuery from 'features/shared/api/useQuery';
import { sort, descend, prop } from 'ramda';
import { PainLevel } from '../shared';

const REQUEST_URL = toApiUrl('PainReport');

export type PainReport = {
  type: 'PainReport';
  id: string;
  createDate: Date;
  createUserId: string;
  description: string | null;
  modifyDate: Date;
  modifyUserId: string;
  name: string;
  ownerId: string;
  painLevel: PainLevel;
  painReportId: string;
  recordState: string;
  relationships: {
    editor: RelationshipUser;
    creator: RelationshipUser;
    owner: RelationshipUser;
  }
}

const apiClient = createApiClient();

export const usePainReports = () => {
  return useQuery(
    ['painReports'],
    async () => {
      return (await apiClient.get(REQUEST_URL)).data;
    },
    {
      select: (data) => sort(descend(prop('createDate')), deserialize<PainReport[]>(data)),
    },
  );
};
