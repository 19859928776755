import { Components, css, Theme } from '@mui/material/styles';

export default function createMuiAvatar(theme: Theme): NonNullable<Components<Theme>['MuiAvatar']> {
  return {
    styleOverrides: {
      root: css`
        color: ${theme.palette.common.white}
      `,
    },
  };
}
