import GenericPageLayout from 'features/shared/GenericPageLayout';
import { LocaleCode, SUPPORTED_LANGUAGES } from 'i18n';
import { useTranslation } from 'react-i18next';
import ListItemButtonWithSelectedIcon from '../shared/ListItemButtonWithSelectedIcon';
import ProfileCardListContainer from './shared/ProfileCardListContainer';

export default function ChangeLanguage() {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'profile.language' });
  const currentLanguage = i18n.language;

  const changeLanguage = (languageKey: LocaleCode) => {
    i18n.changeLanguage(languageKey);
  };

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <ProfileCardListContainer title={t('selectLanguage')} listAriaLabel={t('a11y.listLabel')}>
        {SUPPORTED_LANGUAGES.map((languageKey) => {
          const isSelected = languageKey === currentLanguage;

          return (
            <ListItemButtonWithSelectedIcon
              key={languageKey}
              selected={isSelected}
              onClick={() => changeLanguage(languageKey)}
            >
              {t(`languages.${languageKey}`)}
            </ListItemButtonWithSelectedIcon>
          );
        })}
      </ProfileCardListContainer>
    </GenericPageLayout>
  );
}
