import { createApiClient } from 'features/shared/api/client';
import useMutation from 'features/shared/api/useMutation';
import { toApiUrl } from 'features/shared/api/request';
import { LocaleCode } from 'i18n';
import { isEmailAddress } from 'lib/utils';

const REQUEST_URL = toApiUrl('Login/Invite');

const apiClient = createApiClient();

export type ShareInviteFormValues = {
  emailOrPhone: string;
  name: string;
}

export default function useShareWithPeople(
  { caseId, locale }: { caseId: string, locale: LocaleCode },
) {
  return useMutation(({ emailOrPhone, name }: ShareInviteFormValues) => {
    return apiClient.post(REQUEST_URL, computeFormData({
      emailOrPhone, name, caseId, locale,
    }));
  });
}

const computeFormData = (props: ShareInviteFormValues & { caseId: string, locale: LocaleCode }) => {
  const { emailOrPhone, locale, caseId } = props;
  const email = isEmailAddress(emailOrPhone) ? emailOrPhone : '';
  const phone = !isEmailAddress(emailOrPhone) ? emailOrPhone : '';

  return {
    client_ids: [caseId],
    // sending both email and phone because the endpoint expects non-null values
    email,
    phone,
    send_email: Boolean(email),
    send_sms: Boolean(phone),
    recipient_language: locale,
  };
};
