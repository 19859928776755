import { Components, css, Theme } from '@mui/material/styles';

export default function createMuiCardContent(): NonNullable<Components<Theme>['MuiCardContent']> {
  return {
    styleOverrides: {
      root: css`
        &:last-child {
          padding-bottom: 16px;
        }
      `,
    },
  };
}
