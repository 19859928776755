import { useContext, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { TitleAccessoryContext } from './TitleAccessory';

export type AppHeaderTitleAccessoryProps = {
  children: ReactNode;
};

export default function AppHeaderTitleAccessory({ children }: AppHeaderTitleAccessoryProps) {
  const { ref } = useContext(TitleAccessoryContext);

  return ref ? createPortal(children, ref.current!) : null;
}
