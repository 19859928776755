import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { PageTitleContext } from './internal/PageTitle';

export type AppLayoutPageTitleProps = {
  children: string;
};

export default function AppLayoutPageTitle({ children }: AppLayoutPageTitleProps) {
  const { ref } = useContext(PageTitleContext);
  const { i18n } = useTranslation();

  const helmet = (
    <Helmet
      titleTemplate="%s | My Lawyer Connect"
      defaultTitle="My Lawyer Connect"
      htmlAttributes={{ lang: i18n.language }}
    >
      <title>{children}</title>
    </Helmet>
  );

  return (
    <>
      {helmet}
      {ref ? createPortal(children, ref.current!) : null}
    </>
  );
}
