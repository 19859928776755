import { createApiClient } from 'features/shared/api/client';
import { useSessionCreate } from 'features/shared/session/auth';
import useMutation from 'features/shared/api/useMutation';
import { toApiUrl } from 'features/shared/api/request';

const apiClient = createApiClient();

export default function useLoginMutation() {
  const createSession = useSessionCreate();

  return useMutation(async ({ user, password }: { user: string, password: string }) => {
    const AuthorizationHeader = `Basic ${btoa(`${user}:${password}`)}`;

    const { data: { data: { token, user_id: userId } } } = await apiClient.post(toApiUrl('Login'), {}, {
      params: { AuthorizationHeader },
    });

    createSession(token, userId);
  });
}
