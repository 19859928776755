/* eslint-disable react/no-danger */
import { useParams } from 'react-router-dom';
import {
  Card, CardContent, Typography, CardHeader as MuiCardHeader, styled, css,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import GenericLoading from 'features/shared/GenericLoading';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useLocalizedDateFormatFns, { DEFAULT_FORMAT_OPTIONS } from 'lib/useLocalizedDateFormatFns';
import { sanitizeHTML } from 'lib/utils';
import PageNotFoundError from 'features/shared/PageNotFoundError';
import HistoriesSection from './HistoriesSection';
import useStepDetails from './useStepDetails';

export default function TimelineStepDetails() {
  const { id: journeyStepId } = useParams();
  const {
    data: step, date, stepType, totalSteps, isError, isLoading,
  } = useStepDetails(journeyStepId!);

  const { t } = useTranslation('translation', { keyPrefix: 'caseStatus.stepDetails' });
  const { formatDate } = useLocalizedDateFormatFns();

  if (isLoading) return <GenericPageLayout pageTitle={t('titleLoading')!} centerContent><GenericLoading /></GenericPageLayout>;
  if (!step) return <GenericPageLayout pageTitle={t('titleLoading')!} centerContent><PageNotFoundError /></GenericPageLayout>;
  if (isError) return <GenericPageLayout pageTitle={t('titleLoading')!} centerContent><DataFetchingError /></GenericPageLayout>;

  const {
    name, description, order, duration,
  } = step;

  const formattedDate = date
    ? formatDate(date, { format: { ...DEFAULT_FORMAT_OPTIONS.formatDate, month: 'long' } })
    : null;
  const dateTitle = (() => {
    switch (stepType) {
      case 'completed':
        return t('headerDate.completedAt', { date: formattedDate });
      case 'current':
        return t('headerDate.startedAt', { date: formattedDate });
      case 'future':
        return t('headerDate.notStarted');
      default:
        return null;
    }
  })();

  return (
    <GenericPageLayout pageTitle={t('title', { currentStep: order + 1, totalSteps })!}>
      <Card variant="outlined">
        {dateTitle && (
          <CardHeader
            title={dateTitle}
            titleTypographyProps={{ variant: 'overline', color: 'text.disabled' }}
          />
        )}
        <CardContent sx={{ '&:last-child': { pb: 1 }, pt: 1.25 }}>
          <Typography component="h2" variant="subtitle1" sx={{ mb: 1.25 }}>
            {name}
          </Typography>
          <DescriptionWrapper
            color="text.secondary"
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }}
          />
        </CardContent>
        {duration && (
          <CardFooter title={t('estimatedDuration', { duration })} />
        )}
      </Card>
      <HistoriesSection
        historiesIds={step.relationships.histories?.map((history) => history.id) ?? []}
      />
    </GenericPageLayout>
  );
}

const CardHeader = styled(MuiCardHeader)(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  padding: 0 ${theme.spacing(2)};
`);

const CardFooter = styled(MuiCardHeader)(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  border-top: 1px solid ${theme.palette.grey[300]};
  border-bottom: 0;
  color: ${theme.palette.text.disabled};
  padding: ${theme.spacing(0.5)} ${theme.spacing(2)};

  & .MuiTypography-root {
    font-size: ${theme.typography.body2.fontSize};
    letter-spacing: ${theme.typography.body2.letterSpacing};
    line-height: ${theme.typography.body2.lineHeight};
  }
`);

const DescriptionWrapper = styled(Typography)`
  overflow: hidden;

  & img {
    max-width: 100%;
    border-radius: 4px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;
