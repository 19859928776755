import {
  AppBar as MuiAppBar, Grid, IconButton, Stack, Toolbar, useMediaQuery, useTheme, Box, Divider,
  Link,
} from '@mui/material';
import { ReactNode } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppLogo from 'features/shared/graphics/AppLogo';
import useLocationPathSegments from 'lib/useLocationPathSegments';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useResolvedResponsiveBooleans, ResponsiveBoolean } from 'lib/responsive-utils';
import PageTitle from '../PageTitle';
import TitleAccesory from '../../TitleAccessory';

export type AppHeaderProps = CommonProps & {
  showPageTitle?: ResponsiveBoolean;
  showBackButton?: ResponsiveBoolean;
  confirmOnBackButtonPress?: boolean;
};

type CommonProps = {
  AppBarProps?: Partial<AppBarProps>;
};

export function AppHeader({
  showPageTitle: showPageTitleInput = true,
  showBackButton: showBackButtonInput = 'on-mobile',
  confirmOnBackButtonPress = false,
  ...otherProps
}: AppHeaderProps) {
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const Component = isDesktopScreen ? DesktopAppHeader : MobileAppHeader;
  const [showPageTitle, showBackButton] = useResolvedResponsiveBooleans(
    [showPageTitleInput, showBackButtonInput],
  );

  return (
    <Component {...{
      showPageTitle, showBackButton, confirmOnBackButtonPress, ...otherProps,
    }}
    />
  );
}

type MobileAppHeaderProps = CommonProps & {
  showPageTitle: boolean;
  showBackButton: boolean;
  confirmOnBackButtonPress: boolean;
};

function MobileAppHeader({
  showPageTitle,
  confirmOnBackButtonPress,
  AppBarProps,
}: MobileAppHeaderProps) {
  const isTopLevelRoute = useLocationPathSegments().length === 1;

  if (!showPageTitle) {
    return (
      <AppBar {...AppBarProps}>
        <Stack direction="row" justifyContent="center" sx={{ width: '100%' }}>
          <AppLogo />
        </Stack>
      </AppBar>
    );
  }

  const showBackButton = !isTopLevelRoute;

  return (
    <AppBar {...AppBarProps}>
      <Grid container sx={{ px: 1 }}>
        {showBackButton && (
          <Grid item xs={2}>
            <BackButton confirmOnBackButtonPress={confirmOnBackButtonPress} />
          </Grid>
        )}
        <Grid item xs={showBackButton ? 8 : 12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle />
        </Grid>
        <Grid item xs sx={{ display: 'flex', justifyContent: 'end' }}>
          <TitleAccesory />
        </Grid>
      </Grid>
    </AppBar>
  );
}

type BackButtonProps = {
  confirmOnBackButtonPress: boolean;
}

function BackButton({ confirmOnBackButtonPress = false }: BackButtonProps) {
  const pathSegments = useLocationPathSegments();
  const parentPath = `/${pathSegments.slice(0, -1).join('/')}`;
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'generic.headerBackButtonConfirm' });

  const confirmClickAndNavigate = async () => {
    try {
      await confirm({
        title: t('title'),
        description: t('description'),
        confirmationText: t('confirmButton'),
        cancellationText: t('cancelButton'),
      });
      navigateToParentPath();
      // eslint-disable-next-line no-empty
    } catch { }
  };

  const navigateToParentPath = () => navigate(parentPath);

  const onBackButtonClick = () => {
    if (confirmOnBackButtonPress) {
      confirmClickAndNavigate();
    } else {
      navigateToParentPath();
    }
  };

  return (
    <IconButton onClick={onBackButtonClick} color="inherit" size="large">
      <ArrowBackIcon />
    </IconButton>
  );
}

type DesktopAppHeaderProps = CommonProps & {
  showPageTitle: boolean;
  showBackButton: boolean;
  confirmOnBackButtonPress: boolean;
};

function DesktopAppHeader({
  showPageTitle,
  showBackButton,
  confirmOnBackButtonPress,
  AppBarProps,
}: DesktopAppHeaderProps) {
  const isTopLevelRoute = useLocationPathSegments().length === 1;

  return (
    <AppBar {...AppBarProps}>
      <Box
        sx={{
          width: (theme) => (showPageTitle ? theme.sizes.sidebarWidth : '100%'),
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Link href="/" sx={{ display: 'flex', alignItems: 'center' }}>
          <AppLogo />
        </Link>
      </Box>

      <Divider orientation="vertical" flexItem sx={{ borderColor: 'primary.light', transform: 'translateX(-100%)' }} />

      <Grid
        container
        sx={{
          flexGrow: 1, justifyContent: 'center', alignItems: 'center', width: 'auto',
        }}
      >
        <Grid item xs="auto">
          {showBackButton && !isTopLevelRoute && (
            <BackButton confirmOnBackButtonPress={confirmOnBackButtonPress} />
          )}
        </Grid>

        {showPageTitle && (
          <Grid item xs sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PageTitle />
            <TitleAccesory />
          </Grid>
        )}

        <Grid item xs="auto">
          {showBackButton && !isTopLevelRoute && (
            <Box sx={{ visibility: 'hidden' }}>
              <BackButton confirmOnBackButtonPress={confirmOnBackButtonPress} />
            </Box>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
}

type AppBarProps = {
  children: ReactNode;
  fixed?: boolean;
  denseToolbar?: boolean;
};

function AppBar({ children, fixed = false, denseToolbar = false }: AppBarProps) {
  const toolbarVariant = denseToolbar ? 'dense' : 'regular';

  return (
    <>
      <MuiAppBar position={fixed ? 'fixed' : 'relative'}>
        <Toolbar disableGutters variant={toolbarVariant}>
          {children}
        </Toolbar>
      </MuiAppBar>

      {fixed && (
        <Toolbar variant={toolbarVariant} />
      )}
    </>
  );
}
