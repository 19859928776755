import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { STORAGE_KEY, STORAGE_UPDATE_EVENT_TYPE } from './internal/constants';
import { PasswordSetupDetails } from './types';

const SEARCH_PARAMS_KEYS = [
  'user',
  'userCode',
  'reset',
] as const;

export default function usePasswordSetupDetails() {
  const savedDetails = usePasswordSetupDetailsFromSessionStorage();
  const [searchParams, setSearchParams] = useSearchParams();
  const detailsFromSearchParams = getPasswordSetupDetailsFromSearchParams(searchParams);

  useEffect(() => {
    if (!savedDetails && detailsFromSearchParams) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(detailsFromSearchParams));
      window.dispatchEvent(new Event(STORAGE_UPDATE_EVENT_TYPE));

      const nextSearchParams = new URLSearchParams(searchParams);
      SEARCH_PARAMS_KEYS.forEach((key) => nextSearchParams.delete(key));
      setSearchParams(nextSearchParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return savedDetails ?? detailsFromSearchParams;
}

function usePasswordSetupDetailsFromSessionStorage(): PasswordSetupDetails | null {
  const [valueFromStorage, setValueFromStorage] = useState(sessionStorage.getItem(STORAGE_KEY));

  useEffect(() => {
    const onDetailsFromStorageChange = () => {
      setValueFromStorage(sessionStorage.getItem(STORAGE_KEY));
    };

    window.addEventListener(STORAGE_UPDATE_EVENT_TYPE, onDetailsFromStorageChange);
    return () => {
      window.removeEventListener(STORAGE_UPDATE_EVENT_TYPE, onDetailsFromStorageChange);
    };
  }, []);

  return valueFromStorage && JSON.parse(valueFromStorage);
}

function getPasswordSetupDetailsFromSearchParams(
  searchParams: URLSearchParams,
): PasswordSetupDetails | null {
  const code = searchParams.get('userCode');
  if (!code) return null;

  return {
    code,
    user: searchParams.get('user'),
    isReset: searchParams.has('reset'),
  };
}
