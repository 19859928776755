import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useTranslation } from 'react-i18next';
import GenericBanner from 'features/shared/generic-banner/GenericBanner';

type SuccessBannerProps = {
  /** Hidden banner is used for spacing on mobile */
  hidden?: boolean;
  onClose?: () => void;
  expanded?: boolean;
}

export default function SuccessBanner({
  hidden = false, onClose, expanded = true,
}: SuccessBannerProps) {
  const { t } = useTranslation();

  return (
    <GenericBanner
      title={t('profile.accountSettings.successBanner')}
      visuallyHidden={hidden}
      expanded={expanded}
      backgroundColor="success.main"
      Icon={ThumbUpIcon}
      onClick={onClose}
      noBottomGutterOnMobile
    />
  );
}
