import { useTranslation } from 'react-i18next';
import useIsMobileScreen from './useIsMobileScreen';

/**
 * Utility that returns the appropriate user-interaction verb for desktop and mobile,
 * taking into account the current locale.
 *
 * You can interpolate the value into the translation files.
 * @example
 * const clickOrTap = useClickOrTapLabel() // => "click"
 * const clickOrTap = useClickOrTapLabel() // => "haz clic"
 * const clickOrTap = useClickOrTapLabel() // => "tap"
 * const clickOrTap = useClickOrTapLabel() // => "toque"
 */
export default function useClickOrTapLabel() {
  const isMobile = useIsMobileScreen();
  const { t } = useTranslation('translation', { keyPrefix: 'generic.clickOrTap' });

  return isMobile ? t('tap') : t('click');
}
