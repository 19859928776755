import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GenericBanner from 'features/shared/generic-banner/GenericBanner';

type UndoNotificationDismissBannerProps = {
  hidden?: boolean;
  notificationTitle: string | undefined;
  onUndo: () => void;
  expanded?: boolean;
};

export default function UndoNotificationDismissBanner({
  hidden = false, notificationTitle, onUndo, expanded = false,
}: UndoNotificationDismissBannerProps) {
  const { t } = useTranslation();

  return (
    <GenericBanner
      backgroundColor="grey.900"
      visuallyHidden={hidden}
      expanded={expanded}
      title={t('notifications.notificationDismissed', { title: notificationTitle })}
      onClick={onUndo}
      ActionButton={
        (
          <Button color="inherit" size="small">
            {t('uiElements.buttons.undo')}
          </Button>
        )
      }
    />
  );
}
