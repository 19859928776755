import { ThemeOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function createPalette(): NonNullable<ThemeOptions['palette']> {
  return {
    primary: {
      main: '#9B0721',
      light: '#AF384D',
    },
    background: {
      default: grey[200],
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
    },
  };
}
