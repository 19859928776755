import { Components, Theme } from '@mui/material';
import { createTypographyGlobalStyles } from './globalStyles/typography';

export default function createMuiCssBaseline(theme: Theme): NonNullable<Components<Theme>['MuiCssBaseline']> {
  return {
    styleOverrides: {
      ...createTypographyGlobalStyles(theme),
    },
  };
}
