import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import errorImg from 'features/shared/assets/error/error.png';
import errorImg2x from 'features/shared/assets/error/error@2x.png';
import errorImg3x from 'features/shared/assets/error/error@3x.png';

type GraphicsSize = 'small' | 'medium';

export default function Error({ size = 'medium' }: { size?: GraphicsSize; }) {
  const { t } = useTranslation('translation', { keyPrefix: 'assets.altText' });
  return (
    <Box>
      <Img
        srcSet={`${errorImg} 1x, ${errorImg2x} 2x, ${errorImg3x} 3x`}
        src={errorImg}
        alt={t('error')}
        sx={sxForSize(size)}
      />
    </Box>
  );
}

const Img = styled('img')``;

const sxForSize = (size: GraphicsSize) => {
  switch (size) {
    case 'small':
      return { height: '55px', p: 1 };
    case 'medium':
      return { height: '75px', p: 2 };
    default:
      return { height: '75px', p: 2 };
  }
};
