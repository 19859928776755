import { Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { useTranslation } from 'react-i18next';
import DashboardCard from './DashboardCard';
import { DashboardData } from '../api/useDashboard';

type CaseTeamCardProps = DashboardData['caseTeam'];

export default function CaseTeamCard({
  primaryMemberName, additionalMembersCount,
}: CaseTeamCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.cards.caseTeam' });

  return (
    <DashboardCard href="/case/team" title={t('title')} icon={<PeopleIcon />}>
      {primaryMemberName
        ? (
          <>
            <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
              {primaryMemberName}
              <Typography variant="body2" component="span" sx={{ color: 'text.disabled', ml: '0.25rem' }}>
                {t('primaryContact')}
              </Typography>
            </Typography>
            {(additionalMembersCount || 0) > 1 && (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('additionalContacts', { count: additionalMembersCount })}
              </Typography>
            )}
          </>
        )
        : (
          <Typography variant="body2">
            {t('contacts', { count: additionalMembersCount })}
          </Typography>
        )}
    </DashboardCard>
  );
}
