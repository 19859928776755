import {
  Box, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import { DashboardData } from 'features/dashboard/api/useDashboard';
import { isBlank } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { I18N_PREFIX } from '.';

type CurrentStepsContentProps = Pick<DashboardData['caseStatus'], 'currentStepsTitles'>
export default function CurrentStepsContent({ currentStepsTitles }: CurrentStepsContentProps) {
  const { t } = useTranslation('translation', { keyPrefix: I18N_PREFIX });
  if (isBlank(currentStepsTitles)) return <>{t('noCurrentSteps')}</>;

  return (
    <List disablePadding>
      {currentStepsTitles!.map((stepTitle) => (
        <CurrentStepListItem title={stepTitle} key={stepTitle} />
      ))}
    </List>
  );
}

function CurrentStepListItem({ title }: { title: string }) {
  return (
    <ListItem disablePadding disableGutters>
      <ListItemIcon sx={{ minWidth: '1rem' }}>
        <Box
          component="span"
          sx={{
            backgroundColor: 'info.main', height: '10px', width: '10px',
          }}
        />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}
