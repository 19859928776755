import { pluralize } from 'inflected';
import {
  camelizeKeys, camelize, renameKeys, mapObjDeep,
} from 'lib/utils';
import { when } from 'ramda';

type RelatedRecord = {
  entity_name: string;
  record_id: string;
  data: any;
  relationship: string;
  // TODO: remove after API change
  name?: string;
}

export type WithRelationships<T, Relationships> = T & {
  relationships: Relationships
}

type APIRecord = {
  type: string;
  data: any;
  related_record_ids?: RelatedRecord[];
}

export type ResponseBody<Output> = {
  status: 'success' | 'failure';
  data: Output extends any[] ? APIRecord[] : APIRecord;
}

type Options = {
  relationshipKeysOverrides?: Record<string, string>;
}

const COLLIDING_KEYS_REPLACEMENTS = {
  Type: 'Kind',
  type: 'kind',
};

const DEFAULT_RELATIONSHIPS_KEYS_OVERRIDES = {
  createUser: 'creator',
  modifyUser: 'editor',
  caseTeamMembers: 'teamMembers',
};

export default function deserialize<Output>(
  responseBody: ResponseBody<Output>,
  options?: Options,
): Output {
  const { data } = responseBody;

  if (Array.isArray(data)) {
    // @ts-ignore
    return data.map((record) => deserializeOne<Output>(record, options));
  }
  return deserializeOne(data, options);
}

export function deserializeCustomResponse<Output>(
  responseBody: unknown,
): Output {
  if (Array.isArray(responseBody)) {
    // @ts-ignore
    return responseBody.map((record) => deserializeOneCustom<Output>(record));
  }

  return deserializeOneCustom(responseBody);
}

function deserializeOne<Output>(
  record: APIRecord,
  { relationshipKeysOverrides }: Options = {},
): Output {
  return convertDateStrings(
    camelizeKeys({
      type: record.type,
      ...renameKeys(COLLIDING_KEYS_REPLACEMENTS)(record.data),
      ...(record.related_record_ids && {
        relationships: toRelationshipsObject(
          record.related_record_ids!,
          { ...DEFAULT_RELATIONSHIPS_KEYS_OVERRIDES, ...relationshipKeysOverrides },
        ),
      }),
    }, { deep: true }),
  ) as Output;
}

function toRelationshipsObject(
  relatedRecordIds: RelatedRecord[],
  keysOverrides: Options['relationshipKeysOverrides'],
) {
  return relatedRecordIds.reduce((acc, relatedRecord) => {
    const isCollection = relatedRecord.relationship === 'Link';

    const defaultKey: string = when(() => isCollection, pluralize, camelize(
      relatedRecord.relationship === 'Link'
        ? relatedRecord.entity_name
        : relatedRecord.relationship.split(':')[1].replace(/ID$/, ''),
    ));

    const key = keysOverrides?.[defaultKey] ?? defaultKey;
    const relationshipObject = {
      id: relatedRecord.record_id,
      type: relatedRecord.entity_name,
      ...(relatedRecord.name && { name: relatedRecord.name }),
      ...relatedRecord.data,
    };

    if (isCollection) {
      acc[key] ??= [];
      acc[key].push(relationshipObject);
    } else {
      acc[key] = relationshipObject;
    }

    return acc;
  }, {} as Record<string, any>);
}

function deserializeOneCustom<Output>(record: unknown): Output {
  return convertDateStrings(camelizeKeys(record as any, { deep: true }));
}

const convertDateStrings = mapObjDeep(
  (value, key) => (describesADate(key) && value ? new Date(value as string) : value),
);

const describesADate = (key: string) => key === 'date' || key.endsWith('Date');
