import {
  Card, CardActionArea, CardContent, Typography, Stack, Box,
} from '@mui/material';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import { useLogoutButton, useProfileLinks } from 'features/shared/profile';
import { useTranslation } from 'react-i18next';

export default function Profile() {
  const profileLinks = useProfileLinks();
  const logoutButton = useLogoutButton();
  const { t } = useTranslation();

  return (
    <GenericPageLayout pageTitle={t('navigation.mobile.profile')!}>
      {profileLinks.map(({ text, path, icon }) => (
        <ProfileCard text={text} icon={icon} href={path} key={text} />
      ))}

      <ProfileCard
        text={logoutButton.text}
        icon={logoutButton.icon}
        onClick={logoutButton.action}
      />
    </GenericPageLayout>
  );
}

type ProfileCardProps = {
  text: string;
  href?: string;
  onClick?: () => void;
  icon: JSX.Element;
};

function ProfileCard({
  text, href, onClick, icon,
}: ProfileCardProps) {
  return (
    <Card sx={{ mb: 2 }}>
      <CardActionArea {...href && ({ href })} {...onClick && ({ onClick })}>
        <CardContent>
          <Stack direction="row" alignItems="center">
            <Box sx={{ lineHeight: 1, color: 'text.secondary', mr: 2 }}>{icon}</Box>
            <Typography variant="subtitle1">{text}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
