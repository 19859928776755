import { IconButton, InputAdornment } from '@mui/material';
import { forwardRef, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import TextField, { TextFieldProps } from './TextField';

const PasswordTextField = forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {
  const { t } = useTranslation('translation', { keyPrefix: 'uiElements.buttons' });
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      ref={ref}
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              title={t('togglePasswordVisibility')}
              onClick={() => setShowPassword((b) => !b)}
              onMouseDown={(event) => event.preventDefault()}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
    />
  );
});

export default PasswordTextField;
