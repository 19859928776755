import {
  Link, Stack, Typography, CardActions,
} from '@mui/material';
import { DashboardData } from 'features/dashboard/api/useDashboard';
import HistoryUpdatesCountChip from 'features/shared/HistoryUpdatesCountChip';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import { sanitizeHTML, toIsoDate } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { I18N_PREFIX } from '.';

type HistoriesUpdatesProps = DashboardData['caseStatus']['histories'];

export default function HistoryUpdates({
  unreadCount, latestUpdate,
}: HistoriesUpdatesProps) {
  const { t } = useTranslation('translation', { keyPrefix: I18N_PREFIX });
  const { formatRelativeDate } = useLocalizedDateFormatFns();

  return (
    <CardActions sx={{ p: 0, width: 1 }}>
      <Link href="/case/status/history" sx={{ textDecoration: 'none', width: 1 }}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack alignItems="center" direction="row" spacing={3} sx={{ ml: 1 }}>
            <HistoryUpdatesCountChip updatesCount={unreadCount} />
            <Stack>
              {latestUpdate.type || latestUpdate.details ? (
                <>
                  <Typography
                    color="text.primary"
                    component="p"
                    noWrap
                    sx={{ fontWeight: 500, width: 'min(50vw, 300px)' }}
                    variant="body2"
                  >
                    {latestUpdate.type}
                  </Typography>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: sanitizeHTML(latestUpdate.details) }}
                    sx={{ color: 'text.secondary' }}
                    variant="body2"
                  />
                </>
              ) : (
                <Typography sx={{ color: 'text.disabled' }} variant="body2">
                  {t('noLatestHistory')}
                </Typography>
              )}
            </Stack>
          </Stack>
          {latestUpdate.date
            && (
              <Typography
                variant="caption"
                component="time"
                dateTime={toIsoDate(latestUpdate.date)}
                sx={{
                  color: 'text.disabled', minWidth: '50px', pt: '0.25rem', textAlign: 'right',
                }}
              >
                {formatRelativeDate(latestUpdate.date)}
              </Typography>
            )}
        </Stack>
      </Link>
    </CardActions>
  );
}
