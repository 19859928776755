import {
  QueryFunction,
  QueryKey,
  // eslint-disable-next-line no-restricted-imports
  useQuery as useRQQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useUnauthoriedErrorHandling } from './shared';

export default function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFnInput: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> {
  const queryFn = useUnauthoriedErrorHandling(queryFnInput);
  return useRQQuery<TQueryFnData, TError, TData, TQueryKey>(queryKey, queryFn, options);
}
