import { useTranslation } from 'react-i18next';
import GenericError from './GenericError';

type GenericErrorBoundaryFallbackProps = {
  title?: string;
  subtitle?: string;
  description?: string;
  fullWidth?: boolean;
}

export default function GenericErrorBoundaryFallback({
  title, subtitle, description, fullWidth,
}: GenericErrorBoundaryFallbackProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'generic.error' });

  return (
    <GenericError
      title={title || t('title')!}
      subtitle={subtitle || t('subtitle')!}
      description={description || t('description')!}
      {...(fullWidth && { fullWidth })}
    />
  );
}
