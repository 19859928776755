import { List } from '@mui/material';
import ProfileCard from './ProfileCard';

type ProfileCardListContainerProps = {
  children: React.ReactNode;
  title: string;
  listAriaLabel: string;
}

export default function ProfileCardListContainer({
  children, title, listAriaLabel,
}: ProfileCardListContainerProps) {
  return (
    <ProfileCard title={title} cardContentProps={{ sx: { p: 0, '&:last-child': { pb: 0 } } }}>
      <List aria-label={listAriaLabel} disablePadding>
        {children}
      </List>
    </ProfileCard>
  );
}
