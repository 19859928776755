import { createApiClient } from 'features/shared/api/client';
import useQuery from 'features/shared/api/useQuery';
import deserialize from 'features/shared/api/deserialize';
import { RelationshipUser } from 'features/dashboard/api/useUsers';
import { RelationshipCaseTeamMember } from 'features/case-team/api/useCaseTeamMembers';
import { toApiUrl } from 'features/shared/api/request';
import { RelationshipJourney, RelationshipJourneyStep } from 'features/case-status/recovery-journey/api/useJourneySteps';

const REQUEST_URL = toApiUrl('Client');

export type Client = {
  type: 'Client';
  id: string;
  birthDate: Date;
  clientId: string;
  createDate: Date;
  createUserId: string;
  email: string;
  firstName: string;
  phoneHome: string | null;
  journey: string | null;
  journeyStep: string[] | null;
  journeyStepStartDate: Date | null;
  language: string[];
  lastName: string;
  phoneMobile: string | null;
  modifyDate: Date;
  modifyUserId: string;
  name: string;
  ownerId: string;
  recordState: string;
  relationships: {
    teamMembers: RelationshipCaseTeamMember[];
    // documents: Document[];
    journey: RelationshipJourney;
    journeySteps: RelationshipJourneyStep[];
    editor: RelationshipUser;
    creator: RelationshipUser;
    owner: RelationshipUser;
  }
}

export type RelationshipClient = {
  type: 'Client';
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
};

const apiClient = createApiClient();

export const useClients = ({ enabled } = { enabled: true }) => {
  return useQuery(
    ['clients'],
    async () => {
      return (await apiClient.get(REQUEST_URL)).data;
    },
    {
      select: (data) => deserialize<Client[]>(data),
      enabled,
    },
  );
};
