import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import envelopeImg from 'features/shared/assets/envelope/envelope.png';
import envelopeImg2x from 'features/shared/assets/envelope/envelope@2x.png';
import envelopeImg3x from 'features/shared/assets/envelope/envelope@3x.png';

export default function Envelope() {
  const { t } = useTranslation('translation', { keyPrefix: 'assets.altText' });
  return (
    <Box sx={{ p: 2 }}>
      <img
        srcSet={`${envelopeImg} 1x, ${envelopeImg2x} 2x, ${envelopeImg3x} 3x`}
        src={envelopeImg}
        alt={t('envelope')}
        style={{ height: '100px' }}
      />
    </Box>
  );
}
