import { IconButton, IconButtonProps } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

type ExpandCollapseButtonProps = {
  expanded: boolean;
  onClick(): void;
  /**
   * @property Title override
   * @default `Expand/Collapse` (localized)
  * */
  title?: string;
} & IconButtonProps;

export default function ExpandCollapseButton({
  expanded, onClick, title = '', ...props
}: ExpandCollapseButtonProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'uiElements.buttons.expand' });
  const buttonTitle = title || t(expanded ? 'collapse' : 'expand');

  return (
    <IconButton
      onClick={onClick}
      title={buttonTitle}
      aria-label={buttonTitle}
      sx={{ color: 'text.secondary' }}
      size="small"
      {...props}
    >
      {expanded ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
  );
}
