import { useTranslation } from 'react-i18next';
import GenericError from './GenericError';

type DataFetchingErrorProps = {
  fullWidth?: boolean;
}
export default function DataFetchingError({ fullWidth = false }:DataFetchingErrorProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'generic.dataFetching.error' });

  return (
    <GenericError
      title={t('title')!}
      subtitle={t('subtitle')!}
      description={t('description')!}
      fullWidth={fullWidth}
    />
  );
}
