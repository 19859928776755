import { LoadingButton, LoadingButtonProps } from '@mui/lab';

type SubmitButtonProps = {
  label: string;
  loading: boolean;
  disabled: boolean;
} & LoadingButtonProps;

export default function SubmitButton({
  label, loading, disabled, ...props
}: SubmitButtonProps) {
  return (
    <LoadingButton
      fullWidth
      type="submit"
      variant="contained"
      size="large"
      loading={loading}
      disabled={disabled}
      sx={{ mt: 2 }}
      {...props}
    >
      {label}
    </LoadingButton>
  );
}
