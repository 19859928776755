import { Outlet } from 'react-router-dom';
import { removePasswordSetupDetailsFromSession, usePasswordSetupDetails, useUserCodeStatus } from 'features/shared/session/password-setup';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import GenericError from 'features/shared/GenericError';
import GenericLoading from 'features/shared/GenericLoading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * When the code for password setup is present, blocks the child routes from rendering
 * while checking if the code is valid and displays a loading spinner.
 *
 * After getting the result:
 *   * if the code is found to be valid, renders `<Outlet>`.
 *   * if the code was already used, clears the password setup details to switch to login flow.
 *   * if the code is not valid, shows an error message.
 */
export default function CheckPasswordSetupUserCode() {
  const passwordSetupDetails = usePasswordSetupDetails();

  // Caching the code because clearing the password setup details
  // when the code is invalid will trigger an immediate redirect otherwise.
  const [initialCode] = useState(passwordSetupDetails?.code);
  if (!initialCode) return <Outlet />;

  return <CheckPasswordSetupInitialUserCode code={initialCode} />;
}

function CheckPasswordSetupInitialUserCode({ code }: { code: string }) {
  const { data: userCodeStatus, isLoading, isError } = useUserCodeStatus(code);

  useEffect(() => {
    if (userCodeStatus && userCodeStatus !== 'valid') {
      removePasswordSetupDetailsFromSession();
    }
  }, [userCodeStatus]);

  if (isLoading) return <LoadingPage />;
  if (isError) return <GenericErrorPage />;
  if (userCodeStatus === 'invalid') return <InvalidCodeErrorPage />;

  return <Outlet />;
}

function LoadingPage() {
  return (
    <GenericPageLayout pageTitle="" centerContent><GenericLoading showText={false} /></GenericPageLayout>
  );
}

function GenericErrorPage() {
  return (
    <GenericPageLayout pageTitle="" centerContent><GenericError /></GenericPageLayout>
  );
}

function InvalidCodeErrorPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'setPassword.invalidUserCodeError' });

  return (
    <GenericPageLayout pageTitle="" centerContent>
      <GenericError
        title={t('title')!}
        subtitle={t('subtitle')!}
        description={t('description')!}
      />
    </GenericPageLayout>
  );
}
