import { IconButton } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import GenericBanner from 'features/shared/generic-banner/GenericBanner';
import { useNavigate } from 'react-router-dom';

type SensitiveNoticationsBannerProps = {
  count: number;
  /** Hidden banner is used for spacing on mobile */
  hidden?: boolean;
};

export default function SensitiveNoticationsBanner({
  count, hidden = false,
}: SensitiveNoticationsBannerProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.sensitiveNotifications' });
  const navigate = useNavigate();

  return (
    <GenericBanner
      title={t('description', { count })}
      visuallyHidden={hidden}
      backgroundColor="warning.main"
      Icon={AccessTimeIcon}
      onClick={() => navigate('/notifications')}
      ActionButton={(
        <IconButton size="small" edge="end" sx={{ padding: '0.25rem' }}>
          <ChevronRightIcon sx={{ fontSize: '1rem', color: 'warning.contrastText' }} />
        </IconButton>
      )}
    />
  );
}
