import { Components, Theme } from '@mui/material';

export default function createMuiButton(): NonNullable<Components<Theme>['MuiButton']> {
  return ({
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.size === 'small') && {
          lineHeight: '22px',
        },
        textTransform: 'none',
      }),
      contained: {
        boxShadow: 'none',
      },
    },
  });
}
