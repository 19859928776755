import { useCurrentUser } from 'features/shared/session/auth';
import { useSensitiveNotificationsCount } from 'features/notifications/api/useNotifications';
import { isPresent } from 'lib/utils';
import useFilesUpload from 'features/shared/use-files-upload/useFilesUpload';

export const NOTIFICATIONS_POLLING_INTERVAL = 60 * 1000;

export default function useInvariantLayoutProps() {
  const { currentUser } = useCurrentUser();
  const { fileUploads } = useFilesUpload();
  const notificationsCount = useSensitiveNotificationsCount({
    refetchInterval: NOTIFICATIONS_POLLING_INTERVAL,
    enabled: isPresent(currentUser),
  });

  return {
    notificationsCount,
    pendingFileUploadsCount: fileUploads.filter((f) => f.status !== 'success').length,
  };
}
