import { createApiClient } from 'features/shared/api/client';
import { toApiUrl } from 'features/shared/api/request';
import useQuery from 'features/shared/api/useQuery';
import { generatePath } from 'react-router-dom';

export type UserCodeStatus = 'valid' | 'used' | 'invalid';

type UserCodeResponse = {
  data: {
    status: 'valid' | 'invalid';
    used: 'true' | 'false'; //  ¯\_(ツ)_/¯
  };
};

const apiClient = createApiClient();

export default function useUserCodeStatus(userCode: string) {
  const url = userCode && toApiUrl(generatePath('/UserCode/Check/:code', { code: userCode }));

  return useQuery(
    ['userCodeStatus', userCode],
    async () => (await apiClient.get(url!)).data,
    {
      select: (data: UserCodeResponse): UserCodeStatus => {
        const { status, used } = data.data;

        if (status === 'invalid') return 'invalid';
        if (used === 'true') return 'used';
        return 'valid';
      },
    },
  );
}
