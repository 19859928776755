import { Chip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

type HistoryUpdatesCountChipProps = {
  updatesCount: number;
}

export default function HistoryUpdatesCountChip({ updatesCount }: HistoryUpdatesCountChipProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.cards.caseStatus' });
  const title = t('newHistoryUpdates', { count: updatesCount });

  return (
    <HistoryUpdatesChip
      aria-label={title}
      color={updatesCount === 0 ? 'success' : 'primary'}
      label={updatesCount}
      size="small"
      title={title}
      variant="outlined"
    />
  );
}

const HistoryUpdatesChip = styled(Chip)`
  border-width: 2px;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  line-height: 18px;
  padding-left: 6px;
  padding-right: 6px;
  width: 24px;
  & .MuiChip-label {
    padding-left: 0;
    padding-right: 0;
  }
`;
