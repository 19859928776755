import {
  Card, Typography, Slider, Box, CardHeader, CardContent,
} from '@mui/material';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import TextField from 'lib/TextField';
import * as Yup from 'yup';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'features/shared/form/Form';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useBeforeUnload from 'lib/useBeforeUnload';
import { useNavigate } from 'react-router-dom';
import PainLevelInfoCard from './internal/PainLevelInfoCard';
import { NEW_ENTRY_HIGHLIGHT_PARAM_KEY, PainLevel, PAIN_LEVELS } from './shared';
import useCreatePainReportMutation, { NewPainReportValues } from './api/useCreatePainReportMutation';

const DEFAULT_PAIN_LEVEL_VALUE = Math.min(...PAIN_LEVELS);

const VALIDATION_SCHEMA = Yup.object().shape({
  painLevel: Yup.number().required(),
  description: Yup.string(),
});

export default function NewPainReport() {
  const { t } = useTranslation('translation', { keyPrefix: 'painScale.newPainReport' });
  const { mutateAsync: createPainReport } = useCreatePainReportMutation();
  const navigate = useNavigate();

  const formMethods = useForm<NewPainReportValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
    defaultValues: {
      painLevel: DEFAULT_PAIN_LEVEL_VALUE,
      description: '',
    },
  });
  const {
    control, handleSubmit, formState: { errors, isSubmitting },
  } = formMethods;
  const formValues = useWatch({ control });

  const onFormSubmit = async (props: NewPainReportValues) => {
    return createPainReport(props)
      .then((newPainReportData) => {
        const newReportId = newPainReportData.data.ID;
        navigate(`/case/pain-scale?${NEW_ENTRY_HIGHLIGHT_PARAM_KEY}=${newReportId}`);
      });
  };

  useBeforeUnload({ enabled: Boolean(formValues.description) });

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <FormProvider {...formMethods}>
        <Form
          onSubmit={handleSubmit(onFormSubmit)}
          submitButtonLabel={t('form.submitButton')}
          isSubmitting={isSubmitting}
          errors={errors}
        >
          <Card>
            <CardHeader title={t('form.rateYourPain.title')} />
            <CardContent sx={{ pt: 1 }}>
              <Typography variant="caption" color="text.secondary">
                {t('form.rateYourPain.description')}
              </Typography>
              <Box sx={{ pl: 1 }}>
                <Controller
                  name="painLevel"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Slider
                      aria-label={t('form.rateYourPain.sliderAriaLabel')!}
                      defaultValue={DEFAULT_PAIN_LEVEL_VALUE}
                      getAriaValueText={(painLevel: number) => t('form.rateYourPain.sliderStepAriaLabel', { painLevel })}
                      marks
                      min={Math.min(...PAIN_LEVELS)}
                      max={Math.max(...PAIN_LEVELS)}
                      step={1}
                      value={value}
                      name={name}
                      onChange={onChange}
                      valueLabelDisplay="off"
                    />
                  )}
                />
              </Box>
              <PainLevelInfoCard painLevel={formValues.painLevel as PainLevel} />
            </CardContent>
          </Card>

          <Card sx={{ mt: 2 }}>
            <CardHeader title={t('form.personalNote.title')} />
            <CardContent>
              <TextField
                name="description"
                fullWidth
                multiline
                placeholder={t('form.personalNote.fields.description')!}
              />
            </CardContent>
          </Card>
        </Form>
      </FormProvider>
    </GenericPageLayout>
  );
}
