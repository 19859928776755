import {
  formatDate,
  DateFormatFn,
  formatRelativeDate,
} from './utils/date';
import useCurrentLocale, { LocaleCode } from './useCurrentLocale';

export { DEFAULT_FORMAT_OPTIONS } from './utils/date';

type LocalizedDateFormatFn<T> = (
  input: Parameters<DateFormatFn<T>>[0],
  options?: T
) => ReturnType<DateFormatFn<T>>;

/**
 * Accepts a date format function and returns a new format function
 * with the current locale injected.
 * @param fn Date format function
 * @param locale Locale to be applied to the date format function
 * @returns A date format function with locale injected, accepting the rest of the params
 */
function toLocalizedDateFormatFn<T>(fn: DateFormatFn<T>, locale: LocaleCode) {
  const localizedFormatFn: LocalizedDateFormatFn<T> = (input, options) => (
    fn(input, locale, options)
  );

  return localizedFormatFn;
}

/**
 * Hook that accepts a date format function and returns a new date format function
 * with the current locale injected.
 * @property fn Date format function
 * @example
 * const formatDate = useLocalizedDateFormatFn(originalFormatDate);
 * @returns A date format function with locale injected, accepting the rest of the params
 */
export function useLocalizedDateFormatFn<T>(fn: DateFormatFn<T>) {
  const currentLocale = useCurrentLocale();

  return toLocalizedDateFormatFn(fn, currentLocale);
}

/**
 * Hook that returns all the date format functions (with current locale injected).
 * @example
 * const { formatDate, formatRelativeDate } = useLocalizedDateFormatFns();
 */
export default function useLocalizedDateFormatFns() {
  const currentLocale = useCurrentLocale();

  return {
    formatDate: toLocalizedDateFormatFn(formatDate, currentLocale),
    formatRelativeDate: toLocalizedDateFormatFn(formatRelativeDate, currentLocale),
  };
}
