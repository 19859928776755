import { useTranslation } from 'react-i18next';
import welcomeBannerImg from 'features/shared/assets/welcome-banner/welcome_banner.png';
import welcomeBannerImg2x from 'features/shared/assets/welcome-banner/welcome_banner@2x.png';
import welcomeBannerImg3x from 'features/shared/assets/welcome-banner/welcome_banner@3x.png';

export default function WelcomeBanner() {
  const { t } = useTranslation('translation', { keyPrefix: 'welcome' });
  return (
    <img
      srcSet={`${welcomeBannerImg} 1x, ${welcomeBannerImg2x} 2x, ${welcomeBannerImg3x} 3x`}
      src={welcomeBannerImg}
      alt={t('bannerAltText')}
    />
  );
}
