import {
  IconButton, Link, Stack, Typography,
} from '@mui/material';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import { useTranslation } from 'react-i18next';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import CurrentCaseDetails from 'features/shared/CurrentCaseDetails';
import SensitiveNoticationsBanner from './SensitiveNotificationsBanner';
import CaseStatusCard from './cards/case-status-card';
import CaseTeamCard from './cards/CaseTeamCard';
import PainScaleCard from './cards/pain-scale-card';
import { useDashboard } from './api/useDashboard';

export default function Dashboard() {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const {
    currentCase,
    isLoading: isCurrentCaseLoading,
    isError: isCurrentCaseError,
  } = useCurrentCase();
  const {
    data,
    isLoading: isDashboardLoading,
    isError: isDashboardError,
  } = useDashboard(currentCase?.clientId);

  const isLoading = isCurrentCaseLoading || isDashboardLoading;
  const isError = isCurrentCaseError || isDashboardError;

  const isMobile = useIsMobileScreen();
  const displayNotificationsBanner = (data?.sensitiveNotificationsCount || 0) > 0;

  if (isLoading) {
    return (
      <GenericPageLayout pageTitle={t('title')!} centerContent>
        <GenericLoading />
      </GenericPageLayout>
    );
  }

  if (isError) {
    return (
      <GenericPageLayout pageTitle={t('title')!} centerContent>
        <DataFetchingError />
      </GenericPageLayout>
    );
  }

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <Stack sx={{ justifyContent: { xs: 'space-between', sm: 'flex-start' } }}>
        {isMobile && <CaseTitleHeader />}
        {displayNotificationsBanner
          && <SensitiveNoticationsBanner count={data!.sensitiveNotificationsCount} />}

        <CaseStatusCard {...data!.caseStatus} />

        <CaseTeamCard {...data!.caseTeam} />

        <PainScaleCard {...data!.painScale} />

        {isMobile && displayNotificationsBanner
          && (
            <SensitiveNoticationsBanner count={data!.sensitiveNotificationsCount} hidden />
          )}
      </Stack>
    </GenericPageLayout>
  );
}

function CaseTitleHeader() {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: -1.5, mb: 0.5 }}>
      <Typography color="primary" fontWeight="medium" component="span">
        <CurrentCaseDetails />
      </Typography>
      <Link href="/profile/share" title={t('shareCaseTitle')}>
        <IconButton color="inherit">
          <PersonAddOutlinedIcon />
        </IconButton>
      </Link>
    </Stack>
  );
}
