import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextField, { TextFieldProps } from 'lib/TextField';
import Form from 'features/shared/form/Form';
import { FormProvider } from 'react-hook-form';
import { defaultTo, mapObjIndexed } from 'ramda';
import { useUpdateAddressMutation, AddressFormValues } from './api/updateAccountMutations';
import ProfileCard from '../shared/ProfileCard';

type AddressFormProps = {
  userId: string;
  onSuccess(): void;
} & AddressFormValues;

const VALIDATION_SCHEMA = Yup.object().shape({
  address1: Yup.string(),
  address2: Yup.string(),
});

const FORM_FIELDS: (keyof AddressFormValues)[] = ['address1', 'address2'];
const FIELD_PROPS: { [K in keyof AddressFormValues]: Omit<TextFieldProps, 'name'> } = {
  address1: {
    autoComplete: 'address-line1',
  },
  address2: {
    autoComplete: 'address-line2',
  },
};

export default function AddressForm({
  address1, address2, userId, onSuccess,
}: AddressFormProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.accountSettings.forms.address' });
  const { mutateAsync: updateAddress } = useUpdateAddressMutation(userId);

  const formMethods = useForm<AddressFormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
    defaultValues: mapObjIndexed(defaultTo(''), {
      address1,
      address2,
    }),
  });
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = formMethods;

  const onFormSubmit = async (props: AddressFormValues) => {
    return updateAddress(props).then(onSuccess);
  };

  return (
    <ProfileCard title={t('title')}>
      <FormProvider {...formMethods}>
        <Form
          onSubmit={handleSubmit(onFormSubmit)}
          submitButtonLabel={t('submitButton.label')}
          isSubmitting={isSubmitting}
          errors={errors}
        >
          {FORM_FIELDS.map((fieldName) => (
            <TextField
              fullWidth
              key={fieldName}
              label={t(`${fieldName}.label`)}
              errors={errors[fieldName]}
              {...FIELD_PROPS[fieldName]}
              {...register(fieldName)}
            />
          ))}
        </Form>
      </FormProvider>
    </ProfileCard>
  );
}
