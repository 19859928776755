import {
  Box, Button, CardContent, css, IconButton, Stack, styled, Typography, Card,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CloseIcon from '@mui/icons-material/Close';
import { greaterThanDays, sanitizeHTML, toIsoDate } from 'lib/utils';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import { useTranslation } from 'react-i18next';

type NotificationCardProps = {
  title: string | React.ReactNode;
  date: Date;
  description: string;
  highlighted: boolean;
  actionTitle?: string;
  onActionClick?(): void;
  onDismiss(): void;
}

export default function NotificationCard({
  actionTitle, title, highlighted, date, description, onActionClick, onDismiss,
}: NotificationCardProps) {
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <Stack direction="row" justifyContent="flex-start">
        {highlighted && <CardLeftBorderAccent />}
        <Box sx={{ flex: '1 1 0' }}>
          <CardHeader highlighted={highlighted} date={date} onDismiss={onDismiss} />
          <CardContent sx={{ py: 1, '&:last-child': { pb: 1 } }}>
            <Stack>
              <Typography variant="subtitle1">
                {title}
              </Typography>
              <DescriptionWrapper
                variant="body2"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }}
              />
            </Stack>
          </CardContent>
          {onActionClick && (
            <CardFooter alignItems="center" justifyContent="center">
              <Button onClick={onActionClick} variant="text">
                {actionTitle}
              </Button>
            </CardFooter>
          )}
        </Box>
      </Stack>
    </Card>
  );
}
type CardHeaderProps = Pick<NotificationCardProps, 'highlighted' | 'date' | 'onDismiss'>
function CardHeader({ highlighted, date, onDismiss }: CardHeaderProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'notifications.card' });
  const { formatDate, formatRelativeDate } = useLocalizedDateFormatFns();

  const formattedDate = greaterThanDays(date, -7)
    ? formatDate(date, { format: { day: 'numeric', month: 'long' } })
    : formatRelativeDate(date);

  return (
    <CardHeaderBase direction="row" alignItems="center">
      {highlighted && <Box style={visuallyHidden}>{t('newNotification')}</Box>}
      <Typography
        color={highlighted ? 'warning.main' : 'action.active'}
        component="time"
        dateTime={toIsoDate(date)}
        variant="overline"
      >
        {formattedDate}
      </Typography>
      <IconButton edge="end" size="small" sx={{ ml: 'auto', p: 0.5 }} title={t('dismiss')} onClick={onDismiss}>
        <CloseIcon sx={{ fontSize: '1.25rem' }} />
      </IconButton>
    </CardHeaderBase>
  );
}

const CardLeftBorderAccent = styled('div')(({ theme }) => css`
  background-color: ${theme.palette.warning.main};
  display: flex;
  width: 2px;
`);

const CardHeaderBase = styled(Stack)(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  padding: 0 ${theme.spacing(1)} 0 ${theme.spacing(2)};
`);

const CardFooter = styled(Stack)(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  border-top: 1px solid ${theme.palette.grey[300]};
  padding: 0 ${theme.spacing(2)};
`);

const DescriptionWrapper = styled(Typography)(({ theme }) => css`
  & .MuiSvgIcon-root {
    font-size: 1rem;
    color: ${theme.palette.text.disabled}
  }
`);
