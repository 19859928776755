import {
  Box, Card, CardContent as MuiCardContent, Typography, styled, css,
} from '@mui/material';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import Lines from 'lib/Lines';
import { useTranslation } from 'react-i18next';
import PainLevelInfoCard from './internal/PainLevelInfoCard';
import { PAIN_LEVELS } from './shared';

export default function AboutPainScale() {
  const { t } = useTranslation('translation', { keyPrefix: 'painScale.aboutPainScale' });

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            <Lines of={t('description', { returnObjects: true })} />
          </Typography>
        </CardContent>
      </Card>
      <Box sx={{ pb: 2 }}>
        {PAIN_LEVELS.map((painLevel) => (
          <PainLevelInfoCard painLevel={painLevel} key={painLevel} />
        ))}
      </Box>
    </GenericPageLayout>
  );
}

const CardContent = styled(MuiCardContent)(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
  &:last-child {
    padding-bottom: ${theme.spacing(1)};
  }
`);
