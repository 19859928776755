import isPropValid from '@emotion/is-prop-valid';
import { Stack, styled, css } from '@mui/material';
import { PAIN_SCALE_COLORS, PainLevel, PAIN_LEVELS } from 'features/pain-scale/shared';
import { useTranslation } from 'react-i18next';

type PainScaleLevelProps = {
  currentLevel: number;
}

export default function PainScaleLevel({ currentLevel }: PainScaleLevelProps) {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: 'center' }}
    >
      {PAIN_LEVELS.map((level) => (
        <PainLevelBox
          level={level}
          current={currentLevel === level}
          key={`pain-level-box-${level}`}
        />
      ))}
    </Stack>
  );
}

type PainLevelBoxProps = {
  level: PainLevel;
  current: boolean;
}

function PainLevelBox({ level, current = false }: PainLevelBoxProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.cards.painScale' });

  const title = level ? t('currentLevel', { level }) : '';

  return (
    <PainScaleBox
      current={current}
      level={level}
      aria-label={title}
      title={title}
    >
      {current && level}
    </PainScaleBox>
  );
}

const PainScaleBox = styled(
  'div',
  { shouldForwardProp: isPropValid },
)<PainLevelBoxProps>(({ theme, current, level }) => css`
    align-items: center;
    background-color: ${PAIN_SCALE_COLORS[level]};
    color: ${theme.palette.getContrastText(PAIN_SCALE_COLORS[level])};
    display: inline-flex;
    font-size: 0.85rem;
    height: ${current ? '24px' : '4px'};
    justify-content: center;
    margin-right: 4px;
    padding-left: ${current ? '8px' : 0};
    padding-right: ${current ? '8px' : 0};
    text-align: center;
    width: ${current ? '24px' : '100%'};

    &:last-of-type {
      margin-right: 0;
    }
`);
