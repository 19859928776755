import CaseStatus from 'features/case-status/CaseStatus';
import TimelineStepDetails from 'features/case-status/recovery-journey/step-details/TimelineStepDetails';
import Dashboard from 'features/dashboard/Dashboard';
import PageNotFound from 'features/page-not-found/PageNotFound';
import Login from 'features/session/login/Login';
import SetPassword from 'features/session/set-password/SetPassword';
import { useCurrentUser } from 'features/shared/session/auth';
import ForgotPassword from 'features/session/forgot-password/ForgotPassword';
import Welcome from 'features/welcome/Welcome';
import {
  Outlet, Route, useNavigate,
} from 'react-router-dom';
import RecoveryJourney from 'features/case-status/recovery-journey/RecoveryJourney';
import Histories from 'features/case-status/histories/Histories';
import Upload from 'features/upload/Upload';
import Profile from 'features/profile/Profile';
import { Theme, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import CaseTeam from 'features/case-team/CaseTeam';
import ChangeLanguage from 'features/profile/ChangeLanguage';
import ChangeCase from 'features/profile/ChangeCase';
import Notifications from 'features/notifications/Notifications';
import FAQs from 'features/profile/faqs/FAQs';
import ShareCase from 'features/profile/share-case/ShareCase';
import AccountSettings from 'features/profile/account-settings/AccountSettings';
import SendFeedback from 'features/profile/send-feedback/SendFeedback';
import PainScale from 'features/pain-scale/PainScale';
import AboutPainScale from 'features/pain-scale/AboutPainScale';
import NewPainReport from 'features/pain-scale/NewPainReport';
import { usePasswordSetupDetails } from 'features/shared/session/password-setup';
import { SentryRoutes } from 'lib/errorTracing';
import NoCaseAvailable from 'features/no-case-available/NoCaseAvailable';
import DangerouslyNavigate from './DangerouslyNavigate';
import useDefaultPage from './useDefaultPage';
import useNoAvailableCaseCheck from './useNoAvailableCaseCheck';
import {
  UnauthenticatedUserAppLayout,
  AuthenticatedUserAppLayout,
  PageNotFoundAppLayout,
} from './app-layouts';
import CheckPasswordSetupUserCode from './CheckPasswordSetupUserCode';

const ROOT_PATH = '/';

export default function Routes() {
  const passwordSetupDetails = usePasswordSetupDetails();
  const defaultPage = useDefaultPage(passwordSetupDetails);
  const noCaseAvailable = useNoAvailableCaseCheck();

  return (
    <SentryRoutes>
      <Route element={<UnauthenticatedUserAppLayout><Outlet /></UnauthenticatedUserAppLayout>}>
        <Route element={<UnauthenticatedOnly />}>
          <Route element={<CheckPasswordSetupUserCode />}>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
        </Route>
      </Route>

      <Route element={<AuthenticatedUserAppLayout><Outlet /></AuthenticatedUserAppLayout>}>
        <Route path="/" element={<DangerouslyNavigate to={defaultPage} replace />} />

        <Route element={<RequireAuth />}>
          {(() => {
            if (noCaseAvailable) {
              return <Route path="*" element={<NoCaseAvailable />} />;
            }

            return (
              <>
                <Route path="/case">
                  <Route index element={<Dashboard />} />
                  <Route path="status">
                    <Route index element={<DangerouslyNavigate to="/case" replace />} />
                    <Route element={<CaseStatus />}>
                      <Route path="timeline" element={<RecoveryJourney />} />
                      <Route path="history" element={<Histories />} />
                    </Route>
                  </Route>
                  <Route path="status/timeline/:id" element={<TimelineStepDetails />} />
                  <Route path="team" element={<CaseTeam />} />
                  <Route path="pain-scale" element={<PainScale />} />
                  <Route path="pain-scale/about" element={<AboutPainScale />} />
                  <Route path="pain-scale/new" element={<NewPainReport />} />
                </Route>
                <Route path="/upload" element={<Upload />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/profile">
                  <Route index element={<ProfileRouteElement />} />
                  <Route path="settings" element={<AccountSettings />} />
                  <Route path="case" element={<ChangeCase />} />
                  <Route path="language" element={<ChangeLanguage />} />
                  <Route path="share" element={<ShareCase />} />
                  <Route path="feedback" element={<SendFeedback />} />
                  <Route path="faq" element={<FAQs />} />
                </Route>
              </>
            );
          })()}
        </Route>
      </Route>

      <Route path="*" element={<PageNotFoundAppLayout><PageNotFound /></PageNotFoundAppLayout>} />
    </SentryRoutes>
  );
}

function RequireAuth() {
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return <DangerouslyNavigate to={ROOT_PATH} />;
  }

  return <Outlet />;
}

function UnauthenticatedOnly() {
  const { currentUser } = useCurrentUser();

  if (currentUser) {
    return <DangerouslyNavigate to={ROOT_PATH} />;
  }

  return <Outlet />;
}

function ProfileRouteElement() {
  const isOnDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();

  useEffect(() => {
    if (isOnDesktop) {
      navigate(ROOT_PATH, { replace: true });
    }
  }, [isOnDesktop, navigate]);

  return <Profile />;
}
