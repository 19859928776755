import { Components, css, Theme } from '@mui/material/styles';

export default function createMuiCardHeader(theme: Theme): NonNullable<Components<Theme>['MuiCardHeader']> {
  return {
    defaultProps: {
      titleTypographyProps: { variant: 'body1', fontWeight: 500 },
    },
    styleOverrides: {
      root: css`
        border-bottom: 1px solid ${theme.palette.grey[300]};
        padding: 12px 16px;
      `,
    },
  };
}
