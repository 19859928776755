import { useMatch } from 'react-router-dom';
import AppLayout from 'features/shared/app-layout/AppLayout';
import { ReactNode } from 'react';
import useInvariantLayoutProps from './useInvariantLayoutProps';
import useNoAvailableCaseCheck from '../useNoAvailableCaseCheck';
import { DEFAULT_PAGE_WHEN_USER_IS_AUTHENTICATED } from '../constants';

export default function AuthenticatedUserAppLayout({ children }: { children: ReactNode; }) {
  const invariantProps = useInvariantLayoutProps();
  const isOnDefaultPage = Boolean(useMatch({
    path: DEFAULT_PAGE_WHEN_USER_IS_AUTHENTICATED,
    end: true,
  }));
  const noCaseAvailable = useNoAvailableCaseCheck();

  if (noCaseAvailable) {
    return (
      <AppLayout {...invariantProps} hideNavigation showPageTitle={false}>
        {children}
      </AppLayout>
    );
  }

  return (
    <AppLayout
      {...invariantProps}
      showPageTitle={isOnDefaultPage ? 'on-desktop' : true}
      pathnamesWhereBackButtonShouldBeVisibleOnDesktop={[
        'case/status/timeline/:id',
        'case/pain-scale/about',
        'case/pain-scale/new',
      ]}
      pathnamesWhereBackButtonShouldConfirmOnPress={[
        'case/pain-scale/new',
      ]}
    >
      {children}
    </AppLayout>
  );
}
