import { LocaleCode } from 'i18n';
import { is } from 'ramda';

export type DateFormatFn<T> = (
  date: Date,
  locale: LocaleCode,
  options?: T
) => string;

const DEFAULT_LOCALE: LocaleCode = 'en';

type AbsoluteDateFormatterOptions = {
  format?: Intl.DateTimeFormatOptions
}

const DEFAULT_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

/**
 * Returns a date as a formatted string.
 * @example
 * formatDate('Sat, 05 Feb 2022 05:34:40 UTC') // => feb 5, 2022
 * formatDate(new Date(2022, 3, 9), 'es') // => 9 abr 2022
 */
export const formatDate: DateFormatFn<AbsoluteDateFormatterOptions> = (
  date,
  locale = DEFAULT_LOCALE,
  { format = DEFAULT_DATE_FORMAT_OPTIONS } = {},
) => {
  const formatter = new Intl.DateTimeFormat(locale, format);
  return formatter.format(date);
};

type RelativeDateFormatterOptions = {
  format?: Intl.RelativeTimeFormatOptions,
  unit?: Intl.RelativeTimeFormatUnit
}

const DEFAULT_RELATIVE_DATE_FORMAT_OPTIONS: Intl.RelativeTimeFormatOptions = {
  numeric: 'auto',
};

/**
 * Returns a date as a relative date/time value
 * @example
 * formatRelativeDate('Sat, 05 Feb 2022 05:34:40 UTC', 'es') // => hace 2 días
 * formatRelativeDate(new Date(2022, 4, 1)) //=> Yesterday
 * formatRelativeDate('Sat, 05 May 2022 05:34:40 UTC', 'en', { unit: 'hours' }) // => in 2 hours
* */
export const formatRelativeDate: DateFormatFn<RelativeDateFormatterOptions> = (
  date,
  locale = DEFAULT_LOCALE,
  { format = DEFAULT_RELATIVE_DATE_FORMAT_OPTIONS, unit = 'days' } = {},
) => {
  const deltaDays = (date.getTime() - Date.now()) / MILLISECONDS_PER_DAY;
  const formatter = new Intl.RelativeTimeFormat(locale, format);
  return formatter.format(Math.round(deltaDays), unit);
};

/** Default format options applied to the date format function */
export const DEFAULT_FORMAT_OPTIONS = {
  formatDate: DEFAULT_DATE_FORMAT_OPTIONS,
  formatRelativeDate: DEFAULT_RELATIVE_DATE_FORMAT_OPTIONS,
};

/**
 * Returns a date as a string value in ISO format.
 * @example
 * toIsoDate('Sat, 08 Apr 2022 05:34:40 UTC') // => 2022-04-08T05:34:40.000Z
 * toIsoDate(new Date(2022, 4, 1)) // => 2022-04-30T21:00:00.000Z
*/
export const toIsoDate = (date: Date) => {
  return date.toISOString();
};

/**
 * Checks if a date is greater than the `days` parameter
 * (eg. 14 days in the future, 7 days in the past)
 * @example
 * greaterThanDays(new Date(2022, 4, 1), 7) => true
 * greaterThanDays(new Date(2022, 3, 1), -7) => false
 */
export const greaterThanDays = (date: Date, days: number) => {
  const today = new Date();
  // use + to get the milliseconds value
  if (days < 0) {
    return +date < +today + days * MILLISECONDS_PER_DAY;
  }
  return +date > +today + days * MILLISECONDS_PER_DAY;
};

/**
 * Receives a `string` or `Date` and always returns a `Date` object.
 */
export const ensureDate = (input: Date | string) => (
  is(String, input) ? new Date(input) : input
);

const MILLISECONDS_PER_DAY = 24 * 3600 * 1000;
