import { createApiClient } from 'features/shared/api/client';
import deserialize from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import useQuery from 'features/shared/api/useQuery';

const REQUEST_URL = toApiUrl('User');

export type User = {
  type: 'User';
  id: string;
  cellPhone: string | null;
  createDate: Date;
  displayName: string | null;
  extension: string;
  // lastAction: Date;
  // lastLogin: Date;
  modifyDate: Date;
  officePhone: string;
  recordState: string;
  roleId: string;
  userId: string;
  email: string | null;
  address1: string | null;
  address2: string | null;
  state: string[];
  zip: string | null;
}

const apiClient = createApiClient();

export default function useUserDetails(userId: string | undefined) {
  return useQuery(
    ['userDetails'],
    async () => {
      return (await apiClient.get(`${REQUEST_URL}/${userId}`)).data;
    },
    {
      select: (data) => deserialize<User[]>(data)[0],
      enabled: Boolean(userId),
    },
  );
}
