import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import {
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

/**
 *
 * Initialize Sentry's Error reporting & Tracing SDK
 */
export function sentryInit() {
  return Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN!,
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
