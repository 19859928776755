import { useMediaQuery, Theme } from '@mui/material';

/**
 * String values representing the cartesian product of the members
 * of tuple `[onMobile: boolean, onDesktop: boolean]`:
 *
 * * false => [false, false]
 * * on-mobile => [true, false]
 * * on-desktop => [false, true]
 * * true => [true, true]
 */
export type ResponsiveBoolean = boolean | 'on-desktop' | 'on-mobile';

export const useResolvedResponsiveBooleans = (rbs: readonly ResponsiveBoolean[]) => {
  return rbs.map(useResponsiveBooleanResolver());
};

export const useResponsiveBooleanResolver = () => {
  const isDesktopScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  return makeResponsiveBooleanResolver(isDesktopScreen);
};

export const makeResponsiveBooleanResolver = (
  (isDesktopScreen: boolean) => (rb: ResponsiveBoolean) => {
    return rb === true || rb === (isDesktopScreen ? 'on-desktop' : 'on-mobile');
  }
);
