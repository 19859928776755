import { createApiClient } from 'features/shared/api/client';
import { deserializeCustomResponse } from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import useQuery from 'features/shared/api/useQuery';

const REQUEST_URL = toApiUrl('CustomResponse/CP_JourneyStepsAndHistories');

export type TimelineStepType = 'completed' | 'current' | 'future';
export type RecoveryJourneyStepType = `${TimelineStepType}Steps`;
export type RecoveryJourneyStep = {
  id: string;
  lastUnreadHistory: {
    title: string;
    date: Date;
  };
  title: string;
  unreadHistoriesCount: number;
}
export type RecoveryJourneyData = {
  [K in RecoveryJourneyStepType]: null | RecoveryJourneyStep[];
}

const apiClient = createApiClient();

export const useRecoveryJourney = (clientId: string | undefined) => {
  return useQuery(
    ['recoveryJourney', clientId],
    async () => {
      return (await apiClient.get(`${REQUEST_URL}/${clientId}`)).data;
    },
    {
      select: (data) => deserializeCustomResponse<RecoveryJourneyData>(data),
      enabled: Boolean(clientId),
    },
  );
};
