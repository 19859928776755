import {
  Stack, SvgIcon, Typography,
} from '@mui/material';

type GenericEmptyStateProps = {
  children?: React.ReactNode;
  Icon: typeof SvgIcon;
  title?: string;
}

export default function GenericEmptyState({
  children, Icon, title,
}: GenericEmptyStateProps) {
  return (
    <Stack alignItems="center">
      <Icon sx={{
        color: (theme) => theme.palette.grey[400],
        fontSize: '6rem',
        mb: 1,
      }}
      />
      {title && (
        <Typography align="center" color="text.secondary">
          {title}
        </Typography>
      )}
      {children}
    </Stack>
  );
}
