import { createApiClient } from 'features/shared/api/client';
import useQuery from 'features/shared/api/useQuery';
import deserialize from 'features/shared/api/deserialize';
import { RelationshipClient } from 'features/shared/api/useClients';
import { RelationshipUser } from 'features/dashboard/api/useUsers';
import { toApiUrl } from 'features/shared/api/request';
import { isPresent } from 'lib/utils';
import { descend, prop, sort } from 'ramda';

const REQUEST_URL = toApiUrl('History');

export type History = {
  type: 'History';
  id: string;
  createDate: Date;
  createUserId: string;
  date: Date;
  details: string;
  historyId: string;
  iconRead: string;
  iconUnread: string;
  modifyDate: Date;
  modifyUserId: string;
  name: string;
  ownerId: string;
  recordState: string;
  subject: string;
  time: string;
  // renamed from original `type` per the convention
  kind: string[];
  relationships: {
    clients: RelationshipClient[],
    creator?: RelationshipUser;
    editor?: RelationshipUser;
    owner?: RelationshipUser;
  }
}
export type RelationshipHistory = {
  id: string;
  name: string;
}

const apiClient = createApiClient();

export const useHistories = () => {
  return useQuery(
    ['histories'],
    async () => {
      return (await apiClient.get(REQUEST_URL)).data;
    },
    {
      select: (data) => sort(descend(prop('date')), deserialize<History[]>(data)),
    },
  );
};

export const useDetailedHistories = (ids: string[]) => {
  return useQuery(
    ['detailedHistories', ids],
    async () => {
      return (await apiClient.get(REQUEST_URL, {
        headers: { IDS: ids.join(',') },
      })).data;
    },
    {
      select: (data) => sort(descend(prop('date')), deserialize<History[]>(data)),
      enabled: isPresent(ids),
    },
  );
};
