import {
  Card, CardContent as MuiCardContent, Box, CardActionArea, Collapse,
  Stack, Typography, styled, css,
} from '@mui/material';
import ExpandCollapseButton from 'features/shared/ExpandCollapseButton';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import useToggle from 'lib/useToggle';
import { sanitizeHTML } from 'lib/utils';

type FAQCardProps = {
  title: string;
  description: string;
}

export default function FAQCard({
  title, description,
}: FAQCardProps) {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const isMobile = useIsMobileScreen();

  return (
    <Card variant="outlined" sx={{ mb: 1 }}>
      <Box
        component={isMobile ? CardActionArea : 'div'}
        onClickCapture={isMobile ? toggleIsExpanded : undefined}
        sx={{ py: 1, px: 2 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2" fontWeight="medium">
            {title}
          </Typography>
          <ExpandCollapseButton
            expanded={isExpanded}
            onClick={toggleIsExpanded}
            edge="end"
            {...(isMobile && { component: 'span' })}
          />
        </Stack>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          { /* eslint-disable-next-line react/no-danger */}
          <DescriptionWrapper
            color="text.secondary"
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
}

const CardContent = styled(MuiCardContent)(({ theme }) => css`
  border-top: 1px solid ${theme.palette.grey[300]};
  padding-top: ${theme.spacing(1.25)};

  &:last-child {
    padding-bottom: ${theme.spacing(1)};
  }
`);

const DescriptionWrapper = styled(Typography)`
  overflow: hidden;

  & img {
    max-width: 100%;
    border-radius: 4px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;
