import GenericEmptyState from 'features/shared/GenericEmptyState';
import GenericLoading from 'features/shared/GenericLoading';
import { isBlank } from 'lib/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import { useHistories } from './api/useHistories';
import HistoryCard from './HistoryCard';
import useHistoriesWithNotificationStatus from './useHistoriesWithNotificationStatus';

export const HIGHLIGHTED_HISTORY_CARD_PARAM_KEY = 'highlightID';

export default function Histories() {
  const { data = [], isLoading, isError } = useHistories();
  const { t } = useTranslation('translation', { keyPrefix: 'caseStatus' });
  const [searchParams, setSearchParams] = useSearchParams();
  const [highlightedHistoryId] = useState(searchParams.get(HIGHLIGHTED_HISTORY_CARD_PARAM_KEY));
  const navigate = useNavigate();
  const historiesWithNotificationStatus = useHistoriesWithNotificationStatus(data);
  const highlightedCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (highlightedHistoryId && !isLoading) {
      highlightedCardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(HIGHLIGHTED_HISTORY_CARD_PARAM_KEY);
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [isLoading, highlightedHistoryId, navigate, searchParams, setSearchParams]);

  if (isError) return <GenericPageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></GenericPageLayout>;

  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;

  if (isBlank(historiesWithNotificationStatus)) {
    return (
      <GenericPageLayout pageTitle={t('title')!} centerContent>
        <GenericEmptyState
          Icon={ForumOutlinedIcon}
          title={t('histories.emptyState.title')!}
        />
      </GenericPageLayout>
    );
  }

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      {historiesWithNotificationStatus.map((item) => {
        const isHighlighted = item.id === highlightedHistoryId;
        return (
          <HistoryCard
            item={item}
            initiallyHighlighted={isHighlighted}
            ref={isHighlighted ? highlightedCardRef : null}
            key={item.id}
          />
        );
      })}
    </GenericPageLayout>
  );
}
