import { styled } from '@mui/material';
import {
  createContext, RefObject, useContext, useEffect, useRef,
} from 'react';

export const TitleAccessoryContext = createContext<{
  ref: RefObject<HTMLElement> | null,
  setRef:(ref: RefObject<HTMLElement> | null) => void,
    }>({ ref: null, setRef: () => { } });

export default function TitleAccesory() {
  const ref = useRef<HTMLElement>(null);
  const { setRef } = useContext(TitleAccessoryContext);

  useEffect(() => {
    setRef(ref);

    return () => {
      setRef(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Accessory ref={ref} />;
}

const Accessory = styled('span')`
  display: flex;
`;
