import { createApiClient } from 'features/shared/api/client';
import { useSessionCreate } from 'features/shared/session/auth';
import useMutation from 'features/shared/api/useMutation';
import { toApiUrl } from 'features/shared/api/request';
import { pascalizeKeys } from 'lib/utils';

type MutationFnOptions = {
  code: string;
  username: string;
  password: string;
};

const apiClient = createApiClient();

export default function useSetPasswordMutation() {
  const createSession = useSessionCreate();

  return useMutation(async ({ code, username, password }: MutationFnOptions) => {
    const { data: { data: { token, user_id: userId } } } = await apiClient.post(
      toApiUrl('Login'),
      pascalizeKeys({ code, username, password }),
    );

    return createSession(token, userId);
  });
}
