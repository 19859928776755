import { ThemeOptions } from '@mui/material';

export default function createTypography(): NonNullable<ThemeOptions['typography']> {
  return {
    fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif',
    h1: {
      letterSpacing: '-1.5px',
      fontWeight: 400,
    },
    h2: {
      letterSpacing: '-0.5px',
      fontWeight: 400,
    },
    h4: {
      letterSpacing: '0.25px',
      fontWeight: 400,
    },
    h6: {
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
    subtitle1: {
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
    subtitle2: {
      letterSpacing: '0.1px',
      fontWeight: 400,
    },
    body1: {
      letterSpacing: '0.15px',
      fontWeight: 400,
    },
    body2: {
      letterSpacing: '0.17px',
      fontWeight: 400,
    },
    button: {
      letterSpacing: '0.46px',
      fontWeight: 500,
    },
    caption: {
      letterSpacing: '0.4px',
      fontWeight: 400,
    },
    overline: {
      letterSpacing: '0.4px',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  };
}
