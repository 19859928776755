import {
  List,
  ListItem,
  Collapse,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Children, ReactElement, ReactNode, useLayoutEffect, useRef, useState,
} from 'react';
import { useMatchesAny } from 'lib/routing';
import { reject, isNil } from 'ramda';
import { useLocation } from 'react-router-dom';
import { NavListItemProps } from './NavListItem';
import NavListItemButton from './NavListItemButton';

type NavSubListProps = {
  icon: ReactNode;
  selectedIcon: ReactNode;
  text: string;
  children: ReactElement<NavListItemProps>[] | ReactElement<NavListItemProps>;
};

export default function NavSubList({
  icon, selectedIcon, text, children,
}: NavSubListProps) {
  const childrenPaths = reject(
    isNil,
    Children.map(children, (child) => child.props.pattern ?? child.props.path),
  );
  const anyChildSelected = useMatchesAny(childrenPaths);
  const [isOpen, setIsOpen] = useState(anyChildSelected);
  const isSelected = !isOpen && anyChildSelected;

  useLayoutEffectOnRedirectFromRoot(() => {
    if (anyChildSelected) {
      setIsOpen(true);
    }
  });

  return (
    <>
      <ListItem disablePadding>
        <NavListItemButton
          text={text}
          icon={icon}
          selectedIcon={selectedIcon}
          selected={isSelected}
          onClick={() => { setIsOpen((b) => !b); }}
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </NavListItemButton>
      </ListItem>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}

const useLayoutEffectOnRedirectFromRoot = (fn: () => void) => {
  const { pathname } = useLocation();
  const prevPathnameRef = useRef<string>();
  const prevPathname = prevPathnameRef.current;

  useLayoutEffect(() => {
    if (pathname !== prevPathname && prevPathname === '/') {
      fn();
    }
  }, [pathname, prevPathname, fn]);

  useLayoutEffect(() => {
    prevPathnameRef.current = pathname;
  }, [pathname]);
};
