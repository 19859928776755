import {
  Button, Link, Stack, Typography,
} from '@mui/material';
import EnvelopeImage from 'features/shared/graphics/Envelope';
import { useTranslation } from 'react-i18next';
import ProfileCard from '../shared/ProfileCard';

type ThankYouCardProps = {
  onClose(): void;
}
export default function ThankYouCard({ onClose }: ThankYouCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.sendFeedback.thankYou' });

  return (
    <ProfileCard>
      <Stack alignItems="center">
        <EnvelopeImage />
        <Typography fontWeight="medium" textAlign="center" sx={{ mt: 2 }}>
          {t('title')}
        </Typography>
        <Typography color="text.secondary" textAlign="center" sx={{ mt: 1 }}>
          {t('description')}
        </Typography>
        <Button fullWidth variant="contained" component={Link} href="/" sx={{ mt: 2 }}>
          {t('buttons.goHome')}
        </Button>
        <Button fullWidth variant="outlined" sx={{ mt: 2 }} onClick={onClose}>
          {t('buttons.writeFeedback')}
        </Button>
      </Stack>
    </ProfileCard>
  );
}
