import { Components, css, Theme } from '@mui/material';

export default function createMuiDialogContentText(theme: Theme): NonNullable<Components<Theme>['MuiDialogContentText']> {
  return {
    styleOverrides: {
      root: css`
        color: ${theme.palette.text.primary};
      `,
    },
  };
}
