import axios, { AxiosError } from 'axios';
import { camelizeKeys } from 'lib/utils';
import { mergeAll } from 'ramda';

export type ApiError<T extends string> = AxiosError<{
  result: 'error';
  data: {
    error: {
      base: string;
      fields?: ApiErrorFields<T>;
    }
  };
}>;

export type ApiErrorFields<T extends string> = Partial<{[K in T]: string}>[];

export const isApiError = (error: unknown): error is ApiError<string> => Boolean(
  axios.isAxiosError(error) && error.response && error.response.status < 500,
);

export function getApiErrorMessages<T extends string>(apiError: ApiError<T>) {
  const responseBody = apiError.response!.data;
  const { base: baseErrorMessage, fields } = responseBody.data.error;

  return camelizeKeys(
    mergeAll([{ base: baseErrorMessage }, ...(fields ?? [])]),
  ) as ApiErrorFields<T>[number] & { base?: string };
}
