import GenericEmptyState from 'features/shared/GenericEmptyState';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { isBlank } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import useFaqs from './api/useFaqs';
import FAQCard from './FAQCard';

export default function FAQs() {
  const { data = [], isLoading, isError } = useFaqs();
  const { t } = useTranslation('translation', { keyPrefix: 'profile.faq' });

  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;
  if (isError) return <GenericPageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></GenericPageLayout>;
  if (isBlank(data)) {
    return (
      <GenericPageLayout pageTitle={t('title')!} centerContent>
        <GenericEmptyState
          Icon={HelpOutlineIcon}
          title={t('emptyState.title')!}
        />
      </GenericPageLayout>
    );
  }

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      {data.map(({ id, name, content }) => (
        <FAQCard
          key={id}
          title={name}
          description={content}
        />
      ))}
    </GenericPageLayout>
  );
}
