import isPropValid from '@emotion/is-prop-valid';
import {
  Card as MuiCard, CardHeader, GlobalStyles, css, useTheme, styled,
} from '@mui/material';
import { ReactNode } from 'react';

export type FormCardProps = {
  title?: string;
  hasMaxWidth?: boolean;
  children: ReactNode;
};

export default function FormCard({ title, hasMaxWidth = true, children }: FormCardProps) {
  return (
    <>
      <GlobalPaperBgcolorOnMobile />

      <Card hasMaxWidth={hasMaxWidth}>
        {title && (
          <CardHeader
            title={title}
            sx={{ textAlign: 'center' }}
          />
        )}
        {children}
      </Card>
    </>
  );
}

const Card = styled(MuiCard, { shouldForwardProp: isPropValid })<{ hasMaxWidth: boolean }>(({ theme, hasMaxWidth }) => css`
  width: 100%;

  ${theme.breakpoints.down('md')} {
    border: 0;
    background-color: transparent;
  }

  ${theme.breakpoints.up('md')} {
    ${hasMaxWidth && { maxWidth: 375 }}
  }
`);

function GlobalPaperBgcolorOnMobile() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={css`
        ${theme.breakpoints.down('md')} {
          // It's ugly, but simple comparing the options.
          // Perhaps we could test if stretching the card over the entire height
          // of the viewport won't generate extra scroll / flicker
          body, .AppLayout-contentInnerContainer {
            background-color: ${theme.palette.background.paper} !important;
          }
        }
      `}
    />
  );
}
