import TextField, { TextFieldProps } from 'lib/TextField';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'features/shared/form/Form';
import { FormProvider } from 'react-hook-form';
import { defaultTo, mapObjIndexed } from 'ramda';
import {
  useUpdateAccountInfoMutation, AccountInfoFormValues,
} from './api/updateAccountMutations';
import ProfileCard from '../shared/ProfileCard';

const VALIDATION_SCHEMA = Yup.object().shape({
  displayName: Yup.string().required(),
  cellPhone: Yup.string(),
  email: Yup.string(),
});

const FORM_FIELDS: (keyof AccountInfoFormValues)[] = ['displayName', 'email', 'cellPhone'];
const FIELD_PROPS: { [K in keyof AccountInfoFormValues]: Omit<TextFieldProps, 'name'> } = {
  displayName: {
    autoComplete: 'name',
  },
  email: {
    type: 'email',
    autoComplete: 'email home',
  },
  cellPhone: {
    type: 'phone',
    autoComplete: 'tel home',
  },
};

type AccountInfoFormProps = {
  userId: string;
  onSuccess(): void;
} & AccountInfoFormValues;

export default function AccountInfoForm({
  userId, cellPhone, displayName, email, onSuccess,
}: AccountInfoFormProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.accountSettings.forms.accountInfo' });
  const { mutateAsync: updateAccountInfo } = useUpdateAccountInfoMutation(userId);

  const formMethods = useForm<AccountInfoFormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
    defaultValues: mapObjIndexed(defaultTo(''), {
      displayName,
      cellPhone,
      email,
    }),
  });
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = formMethods;

  const onFormSubmit = async (props: AccountInfoFormValues) => {
    return updateAccountInfo(props).then(onSuccess);
  };

  return (
    <ProfileCard title={t('title')}>
      <FormProvider {...formMethods}>
        <Form
          onSubmit={handleSubmit(onFormSubmit)}
          submitButtonLabel={t('submitButton.label')}
          isSubmitting={isSubmitting}
          errors={errors}
        >
          {
            FORM_FIELDS.map((fieldName) => (
              <TextField
                fullWidth
                key={fieldName}
                label={t(`${fieldName}.label`)}
                errors={errors[fieldName]}
                {...FIELD_PROPS[fieldName]}
                {...register(fieldName)}
              />
            ))
          }
        </Form>
      </FormProvider>
    </ProfileCard>
  );
}
