import { Client } from 'features/shared/api/useClients';
import { createContext } from 'react';

type CurrentCaseContextType = {
  currentCase: Client | null;
  setCurrentCase: (client: Client | null) => void;
  isLoading: boolean;
  isError: boolean;
};

const CurrentCaseContext = createContext<CurrentCaseContextType>({
  currentCase: null,
  setCurrentCase: () => { },
  isLoading: true,
  isError: false,
});

export default CurrentCaseContext;
export type { Client };
