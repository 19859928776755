import {
  Card, Stack, CardContent, Avatar, Typography, IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { useConfirm } from 'material-ui-confirm';
import { getInitials } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { User } from './api/useCaseInvitees';

type InviteeCardProps = {
  invitee: User;
  isCurrentUser?: boolean;
  onRemove?(): void;
}

export default function InviteeCard({
  invitee,
  isCurrentUser = false,
  onRemove: onRemoveInput,
}: InviteeCardProps) {
  const { displayName: name, email } = invitee;
  const isFaded = false;
  const { t } = useTranslation('translation', { keyPrefix: 'profile.shareCase.sharedWith.card' });
  const confirm = useConfirm();

  const onRemove = onRemoveInput && (async () => {
    await confirm({
      dialogProps: { maxWidth: 'xs' },
      title: t('confirmRemove.title', { name }),
      description: t('confirmRemove.description'),
      confirmationText: t('confirmRemove.confirmButton'),
      cancellationText: t('confirmRemove.cancelButton'),
    });
    onRemoveInput();
  });

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent
        sx={{ py: 1, '&:last-child': { pb: 1 } }}
      >
        <Stack alignItems="center" justifyContent="space-between" direction="row">
          <Stack alignItems="center" direction="row" justifyContent="center" spacing={2}>
            {/* TODO: Add team member avatar when available */}
            <Avatar
              alt={name!}
              sx={{ bgcolor: (theme) => theme.palette.grey[isFaded ? 300 : 400] }}
            >
              {getInitials(name!)}
            </Avatar>
            <div>
              <Typography color={isFaded ? 'text.disabled' : 'text.primary'} variant="subtitle1">
                {isCurrentUser ? t('yourself', { name }) : name}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {email}
              </Typography>
            </div>
          </Stack>
          {onRemove && (
            <IconButton edge="end" size="small" title={t('remove')} onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
