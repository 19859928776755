import axios, { AxiosRequestConfig } from 'axios';
import { DEFAULT_LANGUAGE } from 'i18n';

const DEFAULT_OPTIONS: AxiosRequestConfig<any> = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};
const LANGUAGE_LOCAL_STORAGE_KEY = 'i18nextLng';

/**
 * API client based on `axios`, with default options and headers applied.
 *
 * Headers added:
 *
 * - `CP-Language`
 */
export const createApiClient = () => {
  const instance = axios.create(DEFAULT_OPTIONS);

  instance.interceptors.request.use((config) => {
    const language = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
    // eslint-disable-next-line no-param-reassign
    config.headers!['CP-Language'] = language ?? DEFAULT_LANGUAGE;
    return config;
  });

  return instance;
};
