/* eslint-disable no-restricted-imports */
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { forwardRef } from 'react';
import {
  Control, Controller, FieldError, useFormContext,
} from 'react-hook-form';

export type TextFieldProps = MuiTextFieldProps & {
  errors?: FieldError | undefined;
  name: string;
  control?: Control<any, any>;
};

const TextField = forwardRef<HTMLDivElement, TextFieldProps>(({
  errors, control, name, ...restProps
}, ref) => {
  const formContext = useFormContext();
  const fieldControl = control || formContext?.control;
  return fieldControl
    ? (
      <Controller
        control={fieldControl}
        name={name}
        render={({ field }) => (
          <MuiTextField
            {...restProps}
            {...field}
            {...(errors && {
              error: true,
              ...(errors.type !== 'required' && {
                helperText: errors.message,
              }),
            })}
          />
        )}
      />
    )
    : (
      <MuiTextField
        {...restProps}
        ref={ref}
        {...(errors && {
          error: true,
          ...(errors.type !== 'required' && {
            helperText: errors.message,
          }),
        })}
      />
    );
});

export default TextField;
