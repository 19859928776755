import { useEffect } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  MutationStatus, QueryClient, useIsMutating, useQuery, useQueryClient,
} from 'react-query';
import { FileUploadMutation, FILE_UPLOAD_MUTATION_KEY, isFileUploadMutation } from './api/useFileUpload';

export type FileUpload = {
  file: File;
  status: MutationStatus;
  isDismissed: boolean;
  retry(): void;
  dismiss(): void;
}

export default function useSelectedFiles() {
  const fileUploadMutations = useFileUploadMutations();

  const {
    isFileUploadDismissed,
    dismissFileUpload,
    dismissAllFileUploads,
  } = useFileUploadDismiss();

  const fileUploads: FileUpload[] = fileUploadMutations.map((mutation) => ({
    file: mutation.state.variables.file,
    status: mutation.state.status,
    isDismissed: isFileUploadDismissed(mutation),
    retry: () => mutation.execute(),
    dismiss: () => dismissFileUpload(mutation),
  }));

  return {
    fileUploads,
    dismissAllFileUploads,
  };
}

function useFileUploadMutations() {
  useRerenderOnNumberOfFileUploadFetchingMutationsChange();

  return getAllFileUploadMutations(useQueryClient());
}

// There is an ugly cast here, but their types are weird since the
// `Mutation` interface is public and generic, but the current implementation
// of `MutationCache` doesn't allow generics and returns always `Mutation`
const getAllFileUploadMutations = (queryClient: QueryClient) => (
  queryClient
    .getMutationCache()
    .getAll()
    .filter(isFileUploadMutation) as unknown as FileUploadMutation[]
);

/**
 * Ensures the content of the component is being refreshed when the status
 * of a mutation created before the component was previously unmounted changes.
 * That happens if the user goes to another page in the app and then they return
 * while an upload is still in progress.
 */
function useRerenderOnNumberOfFileUploadFetchingMutationsChange() {
  useIsMutating(FILE_UPLOAD_MUTATION_KEY);
}

type DismissedFileUploadMutationQueryData = Record<string | number, boolean>;

function useFileUploadDismiss() {
  const queryClient = useQueryClient();

  const { data: dismissedFileUploadMutationsById } = useQuery('dismissedFileUploadMutations', () => (
    queryClient.getQueryData<DismissedFileUploadMutationQueryData>('dismissedFileUploadMutations')
  ), { cacheTime: Infinity });

  // Currently calling `clear()` on the mutation cache is the only way to clear sometthing from it
  // so this should be enough for sync.
  useClearDismissedFileUploadMutationsQueryDataOnMutationCacheClear();

  return {
    isFileUploadDismissed: (mutation: FileUploadMutation) => (
      Boolean(dismissedFileUploadMutationsById?.[mutation.mutationId])
    ),
    dismissFileUpload: (mutation: FileUploadMutation) => {
      queryClient.setQueryData<DismissedFileUploadMutationQueryData>(
        'dismissedFileUploadMutations',
        (prev) => ({ ...prev, [mutation.mutationId]: true }),
      );

      abortFileUpload(mutation);
    },
    dismissAllFileUploads: (pred: (mutation: MutationStatus) => boolean = () => true) => {
      const fileUploadMutations = getAllFileUploadMutations(queryClient)
        .filter((m) => pred(m.state.status));

      queryClient.setQueryData<DismissedFileUploadMutationQueryData>(
        'dismissedFileUploadMutations',
        fileUploadMutations.reduce((acc, mutation) => {
          acc[mutation.mutationId] = true;
          return acc;
        }, {} as DismissedFileUploadMutationQueryData),
      );

      fileUploadMutations.forEach(abortFileUpload);
    },
  };
}

function useClearDismissedFileUploadMutationsQueryDataOnMutationCacheClear() {
  const queryClient = useQueryClient();

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe(() => {
      if (queryClient.getMutationCache().getAll().length === 0) {
        queryClient.setQueryData<DismissedFileUploadMutationQueryData>(
          'dismissedFileUploadMutations',
          {},
        );
      }
    });

    return unsubscribe;
  }, [queryClient]);
}

function abortFileUpload(mutation: FileUploadMutation) {
  mutation.state.variables.abortController.abort();
}
