import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import GenericError from './GenericError';

export default function PageNotFoundError() {
  const { t } = useTranslation('translation', { keyPrefix: 'generic.pageNotFound' });
  return (
    <GenericError
      title={t('title')!}
      subtitle={t('subtitle')!}
      description={t('description')!}
      action={(
        <Button href="/" variant="contained" fullWidth>
          {t('action')}
        </Button>
      )}
    />
  );
}
