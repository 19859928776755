import { FormHelperText } from '@mui/material';
import { FieldErrors } from 'react-hook-form';

export default function FormBaseErrors({ errors }: { errors: FieldErrors }) {
  return (
    <FormHelperText error sx={{ mt: 2 }}>
      {errors.base!.message}
    </FormHelperText>
  );
}
