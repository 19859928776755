import { styled, css } from '@mui/material';
import { isObjOf } from 'lib/utils';
import { FieldErrors } from 'react-hook-form';
import FormBaseErrors from 'features/shared/form/FormBaseErrors';
import SubmitButton from 'features/shared/form/SubmitButton';

type FormWrapperProps = {
  children: React.ReactNode;
  errors: FieldErrors;
  isSubmitting: boolean;
  onSubmit(): void;
  submitButtonLabel: string;
};

export default function Form({
  children, errors, isSubmitting, onSubmit, submitButtonLabel,
}: FormWrapperProps) {
  return (
    <StyledForm onSubmit={onSubmit}>
      {children}

      {isObjOf('base', errors) && <FormBaseErrors errors={errors} />}

      <SubmitButton label={submitButtonLabel} loading={isSubmitting} disabled={isSubmitting} />
    </StyledForm>
  );
}

const StyledForm = styled('form')(({ theme }) => css`
  .MuiFormControl-root {
    margin-top: ${theme.spacing(2)};

    &:first-of-type {
      margin-top: 0
    }
  }
`);
