// eslint-disable-next-line no-restricted-imports
import { camelize as inflectorCamelize, underscore } from 'inflected';

// Use this instead of `camelize` from "inflected" to prevent default pascal case
// and weird stuff like `Inflector.camelize('ID', false); // => 'iD'`;
export const camelize = (str: string) => inflectorCamelize(underscore(str), false);

export const pascalize = (str: string) => inflectorCamelize(underscore(str));

/**
 * @see {@link https://github.com/martinandert/inflected/blob/master/src/capitalize.js | @martinandert/inflected on GitHub}
 */
export function capitalize(str: any) {
  const result = str === null || str === undefined ? '' : String(str);
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
 * Get initials from a person's full name
 * (also works for the first letter of each word delimited by a space).
 *
 * If there are more than 2 space-delimited words, it will return the first and last initials.
 * @example
 * getInitials('Jane Doe') => 'JD'
 * getInitials('Mary') => 'M'
 * getInitials('Monica Jones Wallace') => 'MW'
 */
export const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce<string>((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      // eslint-disable-next-line no-param-reassign
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
};

const EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const isEmailAddress = (input: string) => (
  EMAIL_REGEXP.test(input.trim().toLowerCase())
);
