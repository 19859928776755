import {
  Box,
  styled,
  css,
  Toolbar,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  ListSubheader,
  Badge,
  Stack,
  Link,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTranslation } from 'react-i18next';
import { useLogoutButton, useProfileLinks } from 'features/shared/profile';
import CurrentCaseDetails from 'features/shared/CurrentCaseDetails';
import { ReactNode } from 'react';
import classNames from 'classnames';
import NavSubList from './internal/NavSubList';
import { NavListItem } from './internal/NavListItem';
import NavList from './internal/NavList';

export type AppSidebarProps = {
  className?: string;
  notificationsCount: number;
  pendingFileUploadsCount: number;
};

export default function AppSidebar({
  className,
  notificationsCount,
  pendingFileUploadsCount,
}: AppSidebarProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation.sidebar' });
  const profileLinks = useProfileLinks();
  const logoutButton = useLogoutButton();

  const profileLinksNavListItems = profileLinks.map(({
    path, icon, selectedIcon, text,
  }) => (
    <NavListItem
      key={path}
      path={path}
      icon={icon}
      selectedIcon={selectedIcon}
    >
      {text}
    </NavListItem>
  ));

  return (
    <>
      <Nav className={classNames('AppSidebar-root', className)}>
        <Toolbar />
        <NavList>
          <ListSubheader disableGutters>
            <ListItem
              component="div"
              secondaryAction={(
                <Link href="profile/share" title={t('shareCase')}>
                  <IconButton color="inherit" sx={{ mr: -1 }}>
                    <PersonAddOutlinedIcon />
                  </IconButton>
                </Link>
              )}
              sx={{ color: 'primary.main' }}
            >
              <ListItemText>
                <CurrentCaseDetails />
              </ListItemText>
            </ListItem>

            <Divider />
          </ListSubheader>

          <NavSubList
            text={t('case')}
            icon={<HomeOutlinedIcon />}
            selectedIcon={<HomeIcon />}
          >
            <NavListItem
              path="/case"
              icon={<DashboardOutlinedIcon />}
              selectedIcon={<DashboardIcon />}
            >
              {t('dashboard')}
            </NavListItem>

            <NavListItem
              path="/case/status/timeline"
              pattern={{ path: '/case/status', end: false }}
              icon={<FactCheckOutlinedIcon />}
              selectedIcon={<FactCheckIcon />}
            >
              {t('caseStatus')}
            </NavListItem>

            <NavListItem
              path="/case/team"
              icon={<PeopleOutlinedIcon />}
              selectedIcon={<PeopleIcon />}
            >
              {t('caseTeam')}
            </NavListItem>

            <NavListItem
              path="/case/pain-scale"
              icon={<AssessmentOutlinedIcon />}
              selectedIcon={<AssessmentIcon />}
              pattern={{ path: '/case/pain-scale', end: false }}
            >
              {t('painScale')}
            </NavListItem>
          </NavSubList>

          <NavListItem
            path="/upload"
            icon={<DriveFolderUploadOutlinedIcon />}
            selectedIcon={<DriveFolderUploadIcon />}
          >
            <Stack direction="row" alignContent="center" justifyContent="space-between">
              {t('upload')}
              <NavListItemBadge aria-label={t('a11y.uploadsBadgeTitle', { count: pendingFileUploadsCount })}>
                {pendingFileUploadsCount}
              </NavListItemBadge>
            </Stack>
          </NavListItem>

          <NavListItem
            path="/notifications"
            icon={<NotificationsOutlinedIcon />}
            selectedIcon={<NotificationsIcon />}
          >
            <Stack direction="row" alignContent="center" justifyContent="space-between">
              {t('notifications')}
              <NavListItemBadge aria-label={t('a11y.notificationsBadgeTitle', { count: notificationsCount })}>
                {notificationsCount}
              </NavListItemBadge>
            </Stack>
          </NavListItem>

          <NavSubList
            text={t('profile')}
            icon={<ManageAccountsOutlinedIcon />}
            selectedIcon={<ManageAccountsIcon />}
          >
            {profileLinksNavListItems}

            <NavListItem
              icon={logoutButton.icon}
              selectedIcon={logoutButton.selectedIcon}
              onClick={logoutButton.action}
            >
              {logoutButton.text}
            </NavListItem>
          </NavSubList>
        </NavList>
      </Nav>

      <Box sx={{ width: (theme) => theme.sizes.sidebarWidth }} />
    </>
  );
}

const Nav = styled('nav')(({ theme }) => css`
  position: fixed;
  top: 0;
  bottom: 0;
  width: ${theme.sizes.sidebarWidth}px;

  background-color: ${theme.palette.primary.main};
  border-right: 1px solid ${grey[300]};

  display: flex;
  flex-direction: column;
`);

function NavListItemBadge({ children, 'aria-label': ariaLabel }: { children: ReactNode, 'aria-label': string }) {
  return (
    <IconButton
      aria-label={ariaLabel}
      component="span"
      disableRipple
      size="large"
    >
      <Badge color="error" badgeContent={children} />
    </IconButton>
  );
}
