import { Card, CardContent } from '@mui/material';
import { useMemo } from 'react';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import CaseStatusProgress from 'features/shared/CaseStatusProgress';
import { useTranslation } from 'react-i18next';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import Timeline from './timeline/Timeline';
import { useRecoveryJourney } from './api/useRecoveryJourney';

export default function RecoveryJourney() {
  const {
    currentCase,
    isLoading: isLoadingCurrentCase,
    isError: isErrorCurrentCase,
  } = useCurrentCase();
  const {
    data: timeline,
    isLoading: isLoadingTimeline,
    isError: isErrorTimeline,
  } = useRecoveryJourney(currentCase?.id);
  const isLoading = isLoadingCurrentCase || isLoadingTimeline;
  const isError = isErrorCurrentCase || isErrorTimeline;

  const { t } = useTranslation('translation', { keyPrefix: 'caseStatus' });

  const timelineStats = useMemo(() => ({
    completedSteps: timeline?.completedSteps?.length || 0,
    currentSteps: timeline?.currentSteps?.length || 0,
    futureSteps: timeline?.futureSteps?.length || 0,
  }), [timeline]);

  if (isError) return <GenericPageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></GenericPageLayout>;
  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <Card sx={{ mb: 2 }}>
        <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
          <CaseStatusProgress
            completedStepsCount={timelineStats.completedSteps}
            currentStepsCount={timelineStats.currentSteps}
            futureStepsCount={timelineStats.futureSteps}
          />
        </CardContent>
      </Card>
      <Timeline data={timeline!} stats={timelineStats} />
    </GenericPageLayout>
  );
}
