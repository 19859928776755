import {
  Paper as MuiPaper, BottomNavigation, BottomNavigationAction, Link, styled, Badge,
} from '@mui/material';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { matchPath, useLocation } from 'react-router-dom';
import { findIndex } from 'ramda';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type NavItem = {
  translationKey: string;
  path: string;
  icon: ReactNode;
  selectedIcon: ReactNode;
  badgeTranslationKey?: string;
};

const NAV_ITEMS: NavItem[] = [
  {
    translationKey: 'case',
    path: '/case',
    icon: <DashboardOutlinedIcon />,
    selectedIcon: <DashboardIcon />,
  },
  {
    translationKey: 'upload',
    path: '/upload',
    icon: <FolderOutlinedIcon />,
    selectedIcon: <FolderIcon />,
    badgeTranslationKey: 'uploadsBadgeTitle',
  },
  {
    translationKey: 'notifications',
    path: '/notifications',
    icon: <NotificationsOutlinedIcon />,
    selectedIcon: <NotificationsIcon />,
    badgeTranslationKey: 'notificationsBadgeTitle',
  },
  {
    translationKey: 'profile',
    path: '/profile',
    icon: <ManageAccountsOutlinedIcon />,
    selectedIcon: <ManageAccountsIcon />,
  },
];

type AppBottomNavigationProps = {
  showNotificationsIndicator: boolean;
  showUploadsIndicator: boolean;
}

export default function AppBottomNavigation({
  showNotificationsIndicator,
  showUploadsIndicator,
}: AppBottomNavigationProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation.mobile' });
  const location = useLocation();
  const activeNavItemIndex = findIndex(
    (item: NavItem) => Boolean(matchPath(item.path, location.pathname)),
    NAV_ITEMS,
  );

  return (
    <Paper
      variant="outlined"
      sx={{
        position: 'fixed', bottom: 0, left: 0, right: 0,
      }}
    >
      <BottomNavigation
        showLabels
        value={activeNavItemIndex}
      >
        {NAV_ITEMS.map(({
          translationKey, path, icon, selectedIcon, badgeTranslationKey,
        }, index) => {
          const addBadgeToIcon = (path === '/notifications' && showNotificationsIndicator)
            || (path === '/upload' && showUploadsIndicator);

          return (
            <BottomNavigationAction
              component={Link}
              key={translationKey}
              label={t(translationKey)}
              icon={(
                <IconBadgeWrapper
                  icon={activeNavItemIndex === index ? selectedIcon : icon}
                  wrap={addBadgeToIcon}
                  {...(badgeTranslationKey && { badgeTranslationKey })}
                />
              )}
              href={path}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
}

const Paper = styled(MuiPaper)`
  &, .MuiBottomNavigation-root {
    border-radius: 8px 8px 0 0;
  }

  .MuiBottomNavigation-root {
    background-color: transparent;
  }
`;

type IconBadgeWrapperProps = {
  icon: ReactNode;
  wrap: boolean;
  badgeTranslationKey?: string;
};

function IconBadgeWrapper({ icon, wrap, badgeTranslationKey }: IconBadgeWrapperProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation.mobile' });
  if (!wrap) return icon as JSX.Element;
  return (
    <Badge
      color="error"
      variant="dot"
      aria-label={t(`a11y.${badgeTranslationKey}`)}
    >
      {icon}
    </Badge>
  );
}
