import {
  Card as MuiCard,
  CardContent,
  CardContentProps,
  CardHeader,
  CardProps,
  css,
  styled,
} from '@mui/material';

type ProfileCardProps = {
  children: React.ReactNode;
  title?: string;
  cardContentProps?: CardContentProps;
} & CardProps;

export default function ProfileCard({
  children, title, cardContentProps = {}, ...props
}: ProfileCardProps) {
  return (
    <Card {...props}>
      {title && <CardHeader title={title} />}
      <CardContent {...cardContentProps}>
        {children}
      </CardContent>
    </Card>
  );
}

const Card = styled(MuiCard)(({ theme }) => css`
  margin-bottom: ${theme.spacing(1)};
`);
