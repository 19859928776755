import GenericEmptyState from 'features/shared/GenericEmptyState';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import { isBlank } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import TeamMemberCard from './TeamMemberCard';
import useTeamMembers from './useTeamMembers';

export default function CaseTeam() {
  const { data: teamMembers = [], isLoading, isError } = useTeamMembers();
  const { t } = useTranslation('translation', { keyPrefix: 'caseTeam' });

  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;
  if (isError) return <GenericPageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></GenericPageLayout>;
  if (isBlank(teamMembers)) {
    return (
      <GenericPageLayout pageTitle={t('title')!} centerContent>
        <GenericEmptyState
          Icon={PeopleOutlinedIcon}
          title={t('emptyState.title')!}
        />
      </GenericPageLayout>
    );
  }

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      {teamMembers!.map((member) => <TeamMemberCard member={member} key={member.id} />)}
    </GenericPageLayout>
  );
}
