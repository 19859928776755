import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type ClearUploadsDialogProps = {
  open: boolean;
  onClose: () => void;
  onWaitPending: () => void;
  onClearAll: () => void;
};

export default function ClearUploadsDialog({
  open, onClose, onWaitPending, onClearAll,
}: ClearUploadsDialogProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'fileUpload.clearUploadsDialog' });

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="clear-uploads-dialog-title"
      aria-describedby="clear-uploads-dialog-description"
    >
      <DialogTitle id="clear-uploads-dialog-title">
        {t('title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="clear-uploads-dialog-description">
          {t('text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} autoFocus>{t('cancelButton')}</Button>
        <Button onClick={() => { onClose(); onWaitPending(); }}>
          {t('waitPendingButton')}
        </Button>
        <Button onClick={() => { onClose(); onClearAll(); }}>
          {t('clearAllButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
