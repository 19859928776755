import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import useMutation from 'features/shared/api/useMutation';
import { createApiClient } from 'features/shared/api/client';
import { deserializeCustomResponse, ResponseBody } from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import { Notification, QUERY_KEY as NOTIFICATIONS_QUERY_KEY } from '../../notifications/api/useNotifications';

const REQUEST_URL = toApiUrl('Notification');
const UNDO_TIMEOUT = 5000;

const apiClient = createApiClient();

export default function useDismissNotification(
  { undoable = false }: { undoable?: boolean } = {},
) {
  const queryClient = useQueryClient();
  const undoMutationRef = useRef<(() => void) | null>(null);
  const [dismissedNotification, setDismissedNotification] = useState<Notification | null>(null);

  const mutation = useMutation(
    (notificationId: string) => {
      // A mutation function must return a Promise
      const mutationPromise = new Promise((resolve, reject) => {
        // Run the mutation after a set amount of time
        const mutateTimeout = setTimeout(() => {
          apiClient
            .delete(`${REQUEST_URL}/${notificationId}`)
            .then((result) => resolve(result))
            .catch((err) => reject(err));
        }, undoable ? UNDO_TIMEOUT : 0);

        // Prepare the function that cancels the mutation
        const cancelMutation = () => {
          clearTimeout(mutateTimeout);
          reject(new Error('Undo notification dismiss'));
        };

        // Put the function that cancels the mutation to a ref to be used by the cancel button
        undoMutationRef.current = cancelMutation;
      }).catch((error: Error) => {
        // eslint-disable-next-line no-console
        console.log(error.message);
      });

      return mutationPromise;
    },
    {
      // Optimistically update the cache value on mutate, but store
      // the old value and return it so that it's accessible in case of
      // an error
      onMutate: async (notificationId: string) => {
        await queryClient.cancelQueries(NOTIFICATIONS_QUERY_KEY);

        const previousNotifications = deserializeCustomResponse<Notification[]>(
          (queryClient.getQueryData(NOTIFICATIONS_QUERY_KEY) as any).data,
        );

        const currentNotification = previousNotifications?.find(
          (notification) => notification.id === notificationId,
        ) ?? null;

        setDismissedNotification(currentNotification);

        if (previousNotifications) {
          queryClient.setQueryData(
            NOTIFICATIONS_QUERY_KEY,
            // @ts-ignore
            (old: ResponseBody<any[]>) => {
              return {
                // @ts-ignore
                data: old?.data.filter((obj) => obj.id !== notificationId),
              };
            },
          );
        }

        return previousNotifications;
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousNotifications) => {
        queryClient.setQueryData(NOTIFICATIONS_QUERY_KEY, previousNotifications);
      },
      // After success or failure, refetch the query
      onSettled: () => {
        queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEY);
      },
    },
  );
  return { ...mutation, undoRef: undoMutationRef, dismissedNotification };
}
