import {
  is, isEmpty, isNil, trim,
} from 'ramda';

/**
 * Checks that input is nil or empty
 * @param {any} input
 * @return {boolean}
 * @example
 * isBlank(""); //=> true
 * isBlank(" "); //=> true
 * isBlank(null); //=> true
 * isBlank(undefined); //=> true
 * isBlank([]); //=> true
 * isBlank({}); //=> true
 * isBlank(0); //=> false
 * isBlank(false); //=> false
 * @see {@link https://github.com/alexandrecolas/ramda-validations/blob/master/src/is-blank.ts | @alexandrecolas/ramda-validations on GitHub}
 */
export const isBlank = (input: any): boolean => {
  const value = is(String, input) ? trim(input) : input;
  return isNil(value) || isEmpty(value);
};

/**
 * Checks that input value is present
 * @param {number} input
 * @return {boolean}
 * @example
 * isPresent("Hello"); //=> true
 * isPresent([]); //=> false
 * isPresent({}); //=> false
 * isPresent(""); //=> false
 * isPresent(null); //=> false
 * isPresent(undefined); //=> false
 * @see {@link https://github.com/alexandrecolas/ramda-validations/blob/master/src/is-present.ts | @alexandrecolas/ramda-validations on GitHub}
 */
export const isPresent = (input: any) => !(isNil(input) || isEmpty(input));
