import { Box, CardContent } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'features/shared/form';
import TextField from 'lib/TextField';
import PasswordTextField from 'lib/PasswordTextField';
import { useTranslation } from 'react-i18next';
import Form from 'features/shared/form/Form';
import { FormProvider } from 'react-hook-form';
import { removePasswordSetupDetailsFromSession, usePasswordSetupDetails } from 'features/shared/session/password-setup';
import { isBlank } from 'lib/utils';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useSetPasswordMutation from './api/useSetPasswordMutation';
import FormCard from '../internal/FormCard';

type FormValues = {
  user: string;
  password: string;
}

export default function SetPassword() {
  const { t } = useTranslation('translation', { keyPrefix: 'setPassword' });
  const { user = null, code, isReset: isResetPasswordFlow = false } = (
    usePasswordSetupDetails() ?? {}
  );
  const navigate = useNavigate();
  const { mutateAsync: setPassword } = useSetPasswordMutation();
  const isUserInputHidden = isResetPasswordFlow && isBlank(user);
  const formMethods = useForm<FormValues>({
    resolver: useFormResolver(user),
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...(!isUserInputHidden && { user: user ?? '' }),
    },
  });
  const {
    register, handleSubmit, formState: { errors, isSubmitting },
  } = formMethods;

  useEffect(() => {
    if (!code) {
      navigate('/login');
    }
  }, [code, navigate]);

  const onFormSubmit = async ({ user: username, password }: FormValues) => {
    await setPassword({ code: code!, username, password });
    removePasswordSetupDetailsFromSession();
    navigate('/');
  };

  return (
    <GenericPageLayout pageTitle={t('title')!} disableGutters disableMargins centerContent="on-desktop">
      <FormCard title={t('title')!}>
        <CardContent>
          <FormProvider {...formMethods}>
            <Form
              onSubmit={handleSubmit(onFormSubmit)}
              errors={errors}
              isSubmitting={isSubmitting}
              submitButtonLabel={t('button')}
            >
              {!isUserInputHidden && (
                <TextField
                  fullWidth
                  {...register('user')}
                  label={t('userInputLabel')}
                  disabled={isResetPasswordFlow}
                  errors={errors.user}
                />
              )}
              <PasswordTextField
                fullWidth
                {...register('password')}
                type="password"
                label={t('passwordInputLabel')}
                helperText={(
                  <div>
                    {t('passwordRequirements.baseDescription')}
                    <Box component="ul" sx={{ my: 0, pl: '1rem' }}>
                      {(t('passwordRequirements.criteria', { returnObjects: true }) as string[]).map((criterion) => (
                        <li key={criterion}>{criterion}</li>
                      ))}
                    </Box>
                  </div>
                )}
                FormHelperTextProps={{ component: 'div' }}
                errors={errors.password}
              />
            </Form>
          </FormProvider>
        </CardContent>
      </FormCard>
    </GenericPageLayout>
  );
}

function useFormResolver(user: string | null) {
  return yupResolver(useMemo(() => (
    Yup.object().shape({
      ...(user && { user: Yup.string().required() }),
      password: Yup.string()
        .required()
        .min(8)
        .test('matches-pattern', 'doesn\'t match the pattern', (string) => (
          [/[a-z]/, /[A-Z]/, /\d/, /\W/].filter((regex) => Boolean(string?.match(regex))).length >= 3
        )),
    })
  ), [user]));
}
