import { useCallback, useMemo, useState } from 'react';
import AuthContext, { User } from './AuthContext';

type AuthProviderProps = {
  children: React.ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(() => {
    const userId = localStorage.getItem('userId');
    return userId ? { id: userId } : null;
  });

  const setCurrentUserContextValue = useCallback((user: User | null) => {
    updateUserIdInLocalStorage(user?.id ?? null);
    setCurrentUser(user);
  }, []);

  const value = useMemo(
    () => ({ currentUser, setCurrentUser: setCurrentUserContextValue }),
    [currentUser, setCurrentUserContextValue],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

function updateUserIdInLocalStorage(userId: string | null) {
  if (userId) {
    localStorage.setItem('userId', userId);
  } else {
    localStorage.removeItem('userId');
  }
}
