import {
  ListItem,
} from '@mui/material';
import { UseMatchPattern } from 'lib/routing';
import { ReactNode } from 'react';
import { useMatch } from 'react-router-dom';
import NavListItemButton from './NavListItemButton';

export type NavListItemProps = {
  icon: ReactNode;
  selectedIcon: ReactNode;
  path?: string;
  pattern?: UseMatchPattern;
  onClick?: () => void;
  children: ReactNode;
};

export function NavListItem({
  icon, selectedIcon, children, path, pattern = path, onClick,
}: NavListItemProps) {
  const isSelected = Boolean(useMatch(pattern ?? '/') && pattern);

  return (
    <ListItem disablePadding>
      <NavListItemButton
        href={path}
        selected={isSelected}
        icon={icon}
        selectedIcon={selectedIcon}
        text={children}
        onClick={onClick}
      />
    </ListItem>
  );
}
