import {
  Link, IconButton, Card, Typography, Stack, Box, CardActionArea, alpha, CardActions,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { css, styled } from '@mui/material/styles';
import isPropValid from '@emotion/is-prop-valid';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import { capitalize, toIsoDate } from 'lib/utils';
import HistoryUpdatesCountChip from 'features/shared/HistoryUpdatesCountChip';
import { RecoveryJourneyStep } from '../api/useRecoveryJourney';

type TimelineStepCardProps = {
  accentColor: string;
  historyUpdate?: HistoryUpdatesProps | null;
  step: RecoveryJourneyStep;
}

export default function TimelineStepCard({
  accentColor, historyUpdate, step,
}: TimelineStepCardProps) {
  const { id, title } = step;
  return (
    <Card sx={{ '&:last-of-type': { mb: 0 } }}>
      <Stack direction="row" justifyContent="flex-start">
        <CardLeftBorderAccent accentColor={accentColor} />
        <Box sx={{ flex: '1 1 0' }}>
          <CardActionArea component={Link} href={id}>
            <CardContent>
              <Typography color="text.primary" variant="subtitle1">
                {title}
              </Typography>
              <Stack alignItems="center" justifyContent="center">
                <IconButton edge="end" size="small">
                  <ChevronRightIcon />
                </IconButton>
              </Stack>
            </CardContent>
          </CardActionArea>
          {historyUpdate?.title && (
            <Footer>
              <HistoryUpdates
                date={historyUpdate.date}
                unreadCount={historyUpdate.unreadCount}
                title={historyUpdate.title}
              />
            </Footer>
          )}
        </Box>
      </Stack>
    </Card>
  );
}

type CardLeftBorderAccentProps = {
  accentColor: string;
}

const CardLeftBorderAccent = styled('div', { shouldForwardProp: isPropValid })<CardLeftBorderAccentProps>(
  ({ accentColor }) => css`
    background-color: ${accentColor};
    display: flex;
    width: 2px;
  `,
);

const CardContent = styled(Stack)(({ theme }) => css`
  align-items: center;
  flex-direction: row;
  flex: 1 1 0;
  justify-content: space-between;
  padding: 11px ${theme.spacing(1)} 11px ${theme.spacing(2)};

  &:last-child {
    padding-bottom: ${theme.spacing(2)};
  }
`);

function Footer({ children }: { children: React.ReactNode }) {
  return (
    <CardActions sx={{
      bgcolor: 'grey.50',
      borderTop: (theme) => `1px solid ${alpha(theme.palette.common.black, 0.12)}`,
      px: 2,
      py: 1,
    }}
    >
      {children}
    </CardActions>
  );
}

type HistoryUpdatesProps = {
  date: Date | null;
  title: string | null;
  unreadCount: number;
}

function HistoryUpdates({ date, title, unreadCount }: HistoryUpdatesProps) {
  const { formatRelativeDate } = useLocalizedDateFormatFns();

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ flex: '1 1 0' }}>
      <Stack alignItems="center" direction="row" spacing={1}>
        {unreadCount >= 1 && <HistoryUpdatesCountChip updatesCount={unreadCount} />}
        <Typography variant="body2" color="text.secondary" noWrap sx={{ maxWidth: 'min(60vw, 530px);' }}>
          {title}
        </Typography>
      </Stack>
      {date && (
        <Typography
          component="time"
          dateTime={toIsoDate(date)}
          sx={{ color: 'text.disabled', minWidth: '50px', textAlign: 'right' }}
          variant="caption"
        >
          {capitalize(formatRelativeDate(date))}
        </Typography>
      )}
    </Stack>
  );
}
