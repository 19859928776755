import isPropValid from '@emotion/is-prop-valid';
import {
  Collapse, css, IconButton, Stack, styled, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import getColorFromTheme, { ColorSwatch } from 'lib/getColorFromTheme';
import { GenericBannerProps } from './types';

/**
 * Render's a custom "Snackbar Alert"-like banner, which can be used to display user feedback.
 *
 * The default behaviour is for the banner to be positioned
 * - sticky, at the top of the content on desktop and
 * - fixed to the bottom of the screen on mobile.
 */
export default function GenericBanner({
  backgroundColor,
  ActionButton,
  expanded = true,
  visuallyHidden = false,
  Icon,
  onClick,
  noBottomGutterOnMobile = false,
  title,
}: GenericBannerProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'uiElements.buttons' });

  return (
    <CollapsibleContainer
      in={expanded}
      visuallyHidden={visuallyHidden}
      noBottomGutterOnMobile={noBottomGutterOnMobile}
      timeout="auto"
      unmountOnExit
    >
      <Content direction="row" colorSwatch={backgroundColor} onClick={onClick}>
        <Stack direction="row" spacing="0.5rem" sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {Icon && <Icon fontSize="small" />}
          <Typography>
            {title}
          </Typography>
        </Stack>
        {ActionButton || (
          <IconButton
            edge="end"
            onClick={onClick}
            size="small"
            sx={{ padding: '0.25rem' }}
            title={t('close')}
          >
            <CloseIcon sx={{
              fontSize: '1rem',
              color: (theme) => theme.palette.getContrastText(
                getColorFromTheme(backgroundColor, theme),
              ),
            }}
            />
          </IconButton>
        )}
      </Content>
    </CollapsibleContainer>

  );
}

type CollapsibleContainerOwnProps = {
  visuallyHidden: boolean;
  noBottomGutterOnMobile: boolean;
}
const CollapsibleContainer = styled(
  Collapse,
  { shouldForwardProp: isPropValid },
)<CollapsibleContainerOwnProps>(
  ({ theme, visuallyHidden, noBottomGutterOnMobile }) => css`
    text-decoration: none;
    z-index: 5;

    ${theme.breakpoints.up('md')} {
      align-self: flex-start;
      position: sticky;
      top: ${theme.sizes.toolbarHeight + 10}px;
      width: 100%;
    }

    ${!visuallyHidden && css`
      ${theme.breakpoints.down('md')} {
        position: fixed;
        bottom: ${noBottomGutterOnMobile ? 0 : `${theme.sizes.bottomNavigationHeight}px`};
        left: ${theme.spacing(1)};
        right: ${theme.spacing(1)};
      }
    `}

    ${visuallyHidden && css`
      visibility: hidden;
    `}
  `,
);

type ContentOwnProps = {
  colorSwatch: ColorSwatch;
}
const Content = styled(
  Stack,
  { shouldForwardProp: isPropValid },
)<ContentOwnProps>(
  ({ theme, colorSwatch }) => css`
    align-items: center;
    background-color: ${getColorFromTheme(colorSwatch, theme)};
    border-radius: 20px;
    color: ${theme.palette.getContrastText(getColorFromTheme(colorSwatch, theme))};
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    width: 100%;
  `,
);
