import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Stack } from '@mui/material';

type GenericLoadingProps = {
  size?: 'sm' | 'lg';
  showText?: boolean;
}

export default function GenericLoading({ size = 'lg', showText = true }: GenericLoadingProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'generic.dataFetching' });

  return (
    <Stack justifyContent="center" alignItems="center">
      <CircularProgress
        size={size === 'sm' ? 40 : 96}
        sx={{ color: (theme) => theme.palette.grey[400] }}
      />
      {showText && (
        <Typography
          color="text.secondary"
          sx={{ mt: size === 'sm' ? 2 : 4 }}
        >
          {t('loading')}
        </Typography>
      )}
    </Stack>
  );
}
