import { Components, Theme } from '@mui/material';

export default function createMuiIconButton(): NonNullable<Components<Theme>['MuiIconButton']> {
  return ({
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.size === 'small') && {
          padding: '4px',
        },
      }),
    },
  });
}
