import { Collapse, Stack } from '@mui/material';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import { isBlank, camelize } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { TransitionGroup } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import GenericEmptyState from 'features/shared/GenericEmptyState';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import { HIGHLIGHTED_HISTORY_CARD_PARAM_KEY } from 'features/case-status/histories/Histories';
import useNotifications, { Notification } from './api/useNotifications';
import useDismissNotification from '../shared/api/useDismissNotification';
import NotificationCard from './NotificationCard';
import UndoNotificationDismissBanner from './UndoNotificationDismissBanner';

export default function Notifications() {
  const { data: notifications, isLoading, isError } = useNotifications();
  const {
    mutate: dismissNotificationUndoable,
    isLoading: notificationIsDismissing,
    undoRef,
    dismissedNotification,
  } = useDismissNotification({ undoable: true });
  const { mutate: dismissNotificationImmediately } = useDismissNotification();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'notifications' });
  const isMobile = useIsMobileScreen();

  if (isLoading) return <GenericPageLayout pageTitle={t('title')!} centerContent><GenericLoading /></GenericPageLayout>;

  if (isError) return <GenericPageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></GenericPageLayout>;

  if (isBlank(notifications)) {
    return (
      <GenericPageLayout pageTitle={t('title')!} centerContent>
        <GenericEmptyState
          Icon={NotificationsNoneIcon}
          title={t('emptyState.title')!}
        />
      </GenericPageLayout>
    );
  }

  return (
    <GenericPageLayout pageTitle={t('title')!}>
      <UndoNotificationDismissBanner
        expanded={notificationIsDismissing}
        notificationTitle={dismissedNotification?.name}
        onUndo={undoRef.current!}
      />
      <Stack>
        <TransitionGroup>
          {notifications!.map((notification) => {
            const {
              id, name, contents, notificationDate, type, timeSensitive, recordId,
            } = notification;
            const actionUrl = getActionUrlForNotificationType({ type, resourceId: recordId });
            const onActionClick = actionUrl && (() => {
              dismissNotificationImmediately(id);
              navigate(actionUrl);
            });
            return (
              <Collapse key={id} timeout="auto" unmountOnExit>
                <NotificationCard
                  key={id}
                  title={name}
                  date={notificationDate}
                  description={contents}
                  highlighted={timeSensitive}
                  {...onActionClick && {
                    actionTitle: t(`card.callToAction.${camelize(type.replace(' ', ''))}`)!,
                    onActionClick,
                  }}
                  onDismiss={() => { dismissNotificationUndoable(id); }}
                />
              </Collapse>
            );
          })}
        </TransitionGroup>
        {notificationIsDismissing && isMobile && (
          (
            <UndoNotificationDismissBanner
              notificationTitle={dismissedNotification?.name}
              onUndo={undoRef.current!}
              hidden
            />
          )
        )}
      </Stack>
    </GenericPageLayout>
  );
}

// TODO - Use the URL from the server when it becomes available
const getActionUrlForNotificationType = ({
  type, resourceId = '',
}: { type: Notification['type'], resourceId?: string | null }) => {
  switch (type) {
    case 'Unread History':
      return `/case/status/history?${HIGHLIGHTED_HISTORY_CARD_PARAM_KEY}=${resourceId}`;
    default:
      return null;
  }
};
