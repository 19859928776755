import {
  MutationFunction,
  // eslint-disable-next-line no-restricted-imports
  useMutation as useRQMutation,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { useUnauthoriedErrorHandling } from './shared';

export default function useMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFnInput: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const mutationFn = useUnauthoriedErrorHandling(mutationFnInput);
  return useRQMutation<TData, TError, TVariables, TContext>(mutationFn, options);
}
