import { Stack, Typography, Collapse } from '@mui/material';
import { useDetailedHistories } from 'features/case-status/histories/api/useHistories';
import HistoryCard from 'features/case-status/histories/HistoryCard';
import useHistoriesWithNotificationStatus from 'features/case-status/histories/useHistoriesWithNotificationStatus';
import ExpandCollapseButton from 'features/shared/ExpandCollapseButton';
import GenericEmptyState from 'features/shared/GenericEmptyState';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';
import GenericErrorBoundaryFallback from 'features/shared/GenericErrorBoundaryFallback';

type HistoriesSectionProps = {
  historiesIds: string[];
}

export default function HistoriesSection({ historiesIds }: HistoriesSectionProps) {
  const [sectionExpanded, setSectionExpanded] = useState(true);
  const { data = [], isLoading, isError } = useDetailedHistories(historiesIds);
  const { t } = useTranslation('translation', { keyPrefix: 'caseStatus.stepDetails.historiesSection' });
  const historiesWithNotificationStatus = useHistoriesWithNotificationStatus(data);
  const layoutProps = {
    expanded: sectionExpanded,
    onToggleExpand: () => setSectionExpanded(!sectionExpanded),
    title: t('title'),
  };

  if (isLoading) {
    return <SectionLayout {...layoutProps}><GenericLoading size="sm" /></SectionLayout>;
  }
  if (isError) {
    return <SectionLayout {...layoutProps}><DataFetchingError fullWidth /></SectionLayout>;
  }

  return (
    <SectionLayout {...layoutProps}>
      {historiesWithNotificationStatus && historiesWithNotificationStatus.length > 0
        ? historiesWithNotificationStatus.map((item) => <HistoryCard item={item} key={item.id} />)
        : (
          <GenericEmptyState
            Icon={ForumOutlinedIcon}
            title={t('emptyState.title')!}
          />
        )}
    </SectionLayout>
  );
}

type SectionLayoutProps = {
  children: React.ReactNode;
  expanded: boolean;
  onToggleExpand(): void;
  title: string;
}
function SectionLayout({
  children, expanded, onToggleExpand, title,
}: SectionLayoutProps) {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2.25, mb: 1 }}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        <ExpandCollapseButton
          expanded={expanded}
          onClick={onToggleExpand}
        />
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {/* @ts-ignore */}
        <ErrorBoundary fallback={<GenericErrorBoundaryFallback fullWidth />}>
          {children}
        </ErrorBoundary>
      </Collapse>
    </>
  );
}
