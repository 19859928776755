import { Stack, Typography, Box } from '@mui/material';
import { DashboardData } from 'features/dashboard/api/useDashboard';
import { range } from 'ramda';

const PROGRESS_INDICATOR_COLORS = {
  completed: 'success.main',
  current: 'info.main',
  future: 'rgba(0, 0, 0, 0.3)',
};

type CaseStatusProgressProps = Pick<DashboardData['caseStatus'], 'completedStepsCount' | 'futureStepsCount'>
  & { currentStepsCount: number }

export default function CaseStatusProgress({
  completedStepsCount, currentStepsCount, futureStepsCount,
}: CaseStatusProgressProps) {
  const totalSteps = completedStepsCount + currentStepsCount + futureStepsCount;
  return (
    <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ width: 1 }}>
      <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
        {totalSteps === 0 && <CaseStatusProgressIndicator type="future" />}
        {range(1, completedStepsCount + 1).map((step) => (
          <CaseStatusProgressIndicator type="completed" key={step} />
        ))}
        {range(1, currentStepsCount + 1).map((step) => (
          <CaseStatusProgressIndicator type="current" key={step} />
        ))}
        {range(1, futureStepsCount + 1).map((step) => (
          <CaseStatusProgressIndicator type="future" key={step} />
        ))}
      </Stack>
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        <Typography component="span" variant="body2" sx={{ color: 'success.main', fontWeight: 'medium' }}>
          {completedStepsCount}
        </Typography>
        <Box component="span" sx={{ mx: '0.5rem' }}>
          &frasl;
        </Box>
        {totalSteps}
      </Typography>
    </Stack>
  );
}

function CaseStatusProgressIndicator({ type }: { type: 'completed' | 'current' | 'future' }) {
  const isCurrent = type === 'current';
  return (
    <Box sx={{
      alignItems: 'center',
      bgcolor: PROGRESS_INDICATOR_COLORS[type],
      display: 'inline-flex',
      flex: 1,
      fontSize: '0.85rem',
      height: isCurrent ? '12px' : '4px',
      justifyContent: 'center',
      textAlign: 'center',
    }}
    />
  );
}
