import { Components, Theme } from '@mui/material/styles';

export default function createMuiFormHelperText(): NonNullable<Components<Theme>['MuiFormHelperText']> {
  return {
    styleOverrides: {
      root: {
        letterSpacing: 0.4,
      },
    },
  };
}
