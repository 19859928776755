import { createApiClient } from 'features/shared/api/client';
import useMutation from 'features/shared/api/useMutation';
import { toApiUrl } from 'features/shared/api/request';

const REQUEST_URL = toApiUrl('Login/Invite');

const apiClient = createApiClient();

export default function useGenerateInviteLink() {
  return useMutation(async (clientId: string) => {
    const result = await apiClient.post(REQUEST_URL, computeFormData(clientId));
    return result.data.data.url;
  });
}

const computeFormData = (clientId: string) => ({
  client_ids: [clientId],
  // empty strings because the endpoint expects a non-null value
  email: '',
  phone: '',
  send_email: false,
  send_sms: false,
  recipient_language: 'en',
});
