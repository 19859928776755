import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import App from 'features/app/App';
import { AuthProvider } from 'features/shared/session/auth';
import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfirmProvider } from 'material-ui-confirm';
import CurrentCaseProvider from 'features/shared/current-case/CurrentCaseProvider';
import { HelmetProvider } from 'react-helmet-async';
import './polyfills';
import theme from './theme';
import './i18n';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';

console.log(`--- ${process.env.REACT_APP_GIT_TAG} ---`);

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOMClient.createRoot(rootElement);

const HELMET_CONTEXT = {};

root.render(
  <React.StrictMode>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <CurrentCaseProvider>
            <ThemeProvider theme={theme}>
              {/** @ts-ignore */}
              <ConfirmProvider>
                {/** @ts-ignore */}
                <HelmetProvider context={HELMET_CONTEXT}>
                  <CssBaseline />
                  <App />
                </HelmetProvider>
              </ConfirmProvider>
            </ThemeProvider>
          </CurrentCaseProvider>
        </AuthProvider>
      </QueryClientProvider>
    </HashRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
