import AssessmentIcon from '@mui/icons-material/Assessment';
import { Typography } from '@mui/material';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import { capitalize, toIsoDate } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { DashboardData } from '../../api/useDashboard';
import DashboardCard from '../DashboardCard';
import PainScaleLevel from './PainScaleLevel';

type PainScaleCardProps = DashboardData['painScale'];

export default function PainScaleCard({
  description, date, level,
}: PainScaleCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.cards.painScale' });
  const { formatRelativeDate } = useLocalizedDateFormatFns();

  const formattedDate = date ? capitalize(formatRelativeDate(date)) : '';

  return (
    <DashboardCard
      href="/case/pain-scale"
      icon={<AssessmentIcon />}
      title={t('title')}
      headerContent={level > 0 && <PainScaleLevel currentLevel={level} />}
    >
      {description && date
        ? (
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {capitalize(description)}
            <Typography
              component="time"
              variant="body2"
              dateTime={toIsoDate(date)}
              sx={{ ml: '0.25rem', color: 'text.disabled' }}
            >
              (
              {formattedDate}
              )
            </Typography>
          </Typography>
        )
        : t('noEntry')}
    </DashboardCard>
  );
}
