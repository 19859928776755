import { Components, css, Theme } from '@mui/material/styles';

export default function createMuiAppBar(): NonNullable<Components<Theme>['MuiAppBar']> {
  return {
    defaultProps: {},
    styleOverrides: {
      root: css`
        box-shadow: none;
      `,
    },
  };
}
