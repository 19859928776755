import { useCurrentUser } from 'features/shared/session/auth';
import { isPresent } from 'lib/utils';
import useFilesUpload from 'features/shared/use-files-upload/useFilesUpload';
import useBeforeUnload from 'lib/useBeforeUnload';
import { useTranslation } from 'react-i18next';
import { sentryInit } from 'lib/errorTracing';
import { Routes } from './routing';

sentryInit();

export default function App() {
  const { currentUser } = useCurrentUser();
  useConfirmationWhenNavigatingAwayWhileUploadNotDone({ enabled: isPresent(currentUser) });

  return (
    <Routes />
  );
}

function useConfirmationWhenNavigatingAwayWhileUploadNotDone(
  { enabled = true }: { enabled?: boolean },
) {
  const { t } = useTranslation();
  const { fileUploads } = useFilesUpload();

  const anyUploadFailedOrInProgress = (
    isPresent(fileUploads) && fileUploads.some((upload) => upload.status !== 'success')
  );

  useBeforeUnload({
    enabled: enabled && anyUploadFailedOrInProgress,
    message: t('fileUpload.leavePageBeforeUploadSuccessConfirm')!,
  });
}
