import {
  styled, Card as MuiCard, CardContent as MuiCardContent, css,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import isPropValid from '@emotion/is-prop-valid';
import { ReactNode } from 'react';
import ErrorGraphic from './graphics/Error';

type ErrorContentProps = {
  title?: string;
  subtitle?: string;
  description?: string;
  action?: ReactNode;
  fullWidth?: boolean;
}

export type GenericErrorProps = {
} & ErrorContentProps;

export default function GenericError({
  fullWidth = false, title, subtitle, description, action,
}: GenericErrorProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'generic.error' });

  return (
    <Card fullWidth={fullWidth}>
      <CardHeader>
        <Title>{title ?? t('title')}</Title>
      </CardHeader>
      <CardContent>
        <ErrorGraphic />
        <Subtitle>{subtitle ?? t('subtitle')}</Subtitle>
        <Description>{description ?? t('description')}</Description>
        <Action>{action}</Action>
      </CardContent>
    </Card>
  );
}

const Card = styled(
  MuiCard,
  { shouldForwardProp: isPropValid },
)<{ fullWidth: boolean }>(({ theme, fullWidth }) => css`
  color: ${theme.palette.text.secondary};
  margin-bottom: 0;
  ${!fullWidth && css`max-width: 375px;`}
  text-align: center;
  white-space: break-spaces;
`);

const CardHeader = styled('div')(({ theme }) => css`
  border-bottom: 1px solid ${theme.palette.grey[300]};
  padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
  color: ${theme.palette.text.primary};
`);

const CardContent = styled(MuiCardContent)(({ theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;

  &:last-child {
    padding-bottom: ${theme.spacing(2)};
  }
`);

const Title = styled('p')(({ theme }) => css`
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
  color: ${theme.palette.text.primary};
`);

const Subtitle = styled(Title)(({ theme }) => css`
  margin-bottom: ${theme.spacing(1)};
`);

const Description = styled('p')(({ theme }) => css`
  color: ${theme.palette.text.secondary};
  margin-bottom: 0;
  text-align: center;
  white-space: break-spaces;
`);

const Action = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2)};
  align-self: stretch;
`;
