import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import { isBlank, isPresent } from 'lib/utils';
import { RelationshipClient } from '../../../shared/api/useClients';
import { JourneyStep, useJourneySteps } from '../api/useJourneySteps';
import { TimelineStepType } from '../api/useRecoveryJourney';

export default function useStepDetails(stepId: string) {
  const {
    currentCase,
    isLoading: isCurrentCaseLoading,
    isError: isCurrentCaseError,
  } = useCurrentCase();
  const {
    data: allSteps = [],
    isLoading: isStepsLoading,
    isError: isStepsError,
  } = useJourneySteps();

  const isLoading = isCurrentCaseLoading || isStepsLoading;
  const isError = isCurrentCaseError || isStepsError;

  const toResult = ({
    data = null, stepType = null, date = null, totalSteps = 0,
  }: {
    data?: JourneyStep | null | undefined,
    stepType?: TimelineStepType | null,
    date?: Date | null,
    totalSteps?: number,
  }) => ({
    data,
    stepType,
    date,
    totalSteps,
    isLoading,
    isError,
  });

  if (!currentCase || isBlank(allSteps)) return toResult({});

  // get all the steps associated with the current case's Journey ID
  const stepsForJourney = allSteps.filter(
    (step) => step.relationships.journeys[0].id === currentCase.journey,
  );

  if (isBlank(stepsForJourney)) return toResult({});

  const stepData = stepsForJourney.find((step) => step.id === stepId);

  if (!stepData) return toResult({});

  const stepDetails = stepData.relationships.clients?.find(
    (client) => client.id === currentCase.id,
  );

  const date = stepDetails?.endDate ?? stepDetails?.startDate;

  return toResult({
    data: stepData,
    date: date ?? null,
    stepType: getStepType(stepDetails),
    totalSteps: stepsForJourney.length,
  });
}

const getStepType = (
  clientData: RelationshipClient | undefined | null,
): TimelineStepType | null => {
  if (isBlank(clientData?.startDate)) return 'future';
  if (isPresent(clientData?.startDate) && isPresent(clientData?.endDate)) return 'completed';
  if (isPresent(clientData?.startDate) && isBlank(clientData?.endDate)) return 'current';

  throw new Error('Cannot compute step type');
};
