import axios from 'axios';
import useLogout from '../session/useLogout';

// Bad typings, I know. I tried generic function returning promise but it breaks types
// when being used so ¯\_(ツ)_/¯
export function useUnauthoriedErrorHandling<F>(requestFn: F): F {
  const logout = useLogout(false);

  // @ts-ignore
  return async (...args) => {
    try {
      // @ts-ignore
      return await requestFn(...args);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        logout();
      }

      throw error;
    }
  };
}
