import {
  Avatar, Card, CardContent, Stack, styled, Typography, css, Box,
  CardHeader as MuiCardHeader, SvgIcon, Button, CardActions, Collapse, Grid, CardActionArea,
} from '@mui/material';
import ExpandCollapseButton from 'features/shared/ExpandCollapseButton';
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';
import { keys } from 'ramda';
import { getInitials } from 'lib/utils';
import useToggle from 'lib/useToggle';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import { TeamMember } from './useTeamMembers';

type TeamMemberCardProps = {
  member: TeamMember;
}

export default function TeamMemberCard({ member }: TeamMemberCardProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'caseTeam.card' });
  const { isPrimary, title, name } = member;
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const isExpandable = !isPrimary;
  const isMobile = useIsMobileScreen();
  const isFullCardExpandable = isMobile && isExpandable;

  return (
    <Card sx={{ mb: 1 }}>
      <Stack direction="row" justifyContent="flex-start">
        {isPrimary && <CardLeftBorderAccent />}
        <Box sx={{ flex: '1 1 0' }}>
          {isPrimary && (
            <CardHeader
              title={t('primaryContact')}
              titleTypographyProps={{
                color: 'primary.light',
                variant: 'overline',
              }}
            />
          )}
          <CardContent
            component={isFullCardExpandable ? CardActionArea : 'div'}
            onClickCapture={isFullCardExpandable ? toggleIsExpanded : undefined}
            sx={{ py: 1, pr: 1, '&:last-child': { pb: 1 } }}
          >
            <Stack alignItems="center" justifyContent="space-between" direction="row">
              <Stack alignItems="center" direction="row" justifyContent="center" spacing={2}>
                {/* TODO: Add team member avatar when available */}
                <Avatar alt={name}>
                  {getInitials(name)}
                </Avatar>
                <div>
                  <Typography color="text.primary" variant="subtitle1">
                    {name}
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {title.join(', ')}
                  </Typography>
                </div>
              </Stack>
              {isExpandable && (
                <ExpandCollapseButton
                  expanded={isExpanded}
                  onClick={toggleIsExpanded}
                  edge="end"
                  {...(isFullCardExpandable && { component: 'span' })}
                />
              )}
            </Stack>
          </CardContent>
          {
            isExpandable ? (
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <TeamMemberContactOptions member={member} />
              </Collapse>
            ) : (
              <TeamMemberContactOptions member={member} />
            )
          }
        </Box>
      </Stack>
    </Card>
  );
}

const CardHeader = styled(MuiCardHeader)(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  padding-bottom: 0;
  padding-top: 0;
`);

const CardLeftBorderAccent = styled('div')(({ theme }) => css`
  background-color: ${theme.palette.primary.main};
  display: flex;
  width: 2px;
`);

type ContactButtonType = 'call' | 'message' | 'email';
type ButtonsConfig = {
  [K in ContactButtonType]: {
    acceptKey: 'acceptCalls' | 'acceptSms' | 'acceptEmail';
    hrefSchema: string;
    icon: typeof SvgIcon;
    key: string;
    valueKey: 'phone' | 'smsPhone' | 'email';
  }
}
const BUTTONS_CONFIG: ButtonsConfig = {
  call: {
    acceptKey: 'acceptCalls',
    hrefSchema: 'tel:',
    icon: CallIcon,
    key: 'call',
    valueKey: 'phone',
  },
  message: {
    acceptKey: 'acceptSms',
    hrefSchema: 'sms:',
    icon: MessageIcon,
    key: 'message',
    valueKey: 'smsPhone',
  },
  email: {
    acceptKey: 'acceptEmail',
    hrefSchema: 'mailto:',
    icon: EmailIcon,
    key: 'email',
    valueKey: 'email',
  },
};

function TeamMemberContactOptions({ member }: { member: TeamMember }) {
  const { t } = useTranslation('translation', { keyPrefix: 'caseTeam.card.actionButtons' });

  return (
    <CardFooter>
      <Grid container spacing={2}>
        {keys(BUTTONS_CONFIG).map((buttonType) => {
          const {
            acceptKey, hrefSchema, icon: Icon, key, valueKey,
          } = BUTTONS_CONFIG[buttonType];
          const value = member[valueKey];
          const acceptsContactMethod = member[acceptKey];

          if (!value || !acceptsContactMethod) return null;

          const hrefValue = valueKey === 'email' ? value : sanitizePhoneNumber(value);
          const href = `${hrefSchema}${hrefValue}`;
          return (
            <Grid item key={buttonType} xs={4}>
              <ContactButton
                href={href}
                icon={<Icon />}
                title={t(key)}
              />
            </Grid>
          );
        })}
      </Grid>
    </CardFooter>
  );
}

const CardFooter = styled(CardActions)(({ theme }) => css`
  background-color: ${theme.palette.grey[50]};
  border-top: 1px solid ${theme.palette.grey[300]};
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
`);

type ContactButtonProps = {
  href: string;
  icon: React.ReactNode;
  title: string;
}
function ContactButton({ href, icon, title }: ContactButtonProps) {
  return (
    <Button
      color="primary"
      component="a"
      href={href}
      size="small"
      startIcon={icon}
      sx={{ width: '100%' }}
      variant="outlined"
    >
      {title}
    </Button>
  );
}

const sanitizePhoneNumber = (value: string) => (
  // Remove non-digit characters, except for +
  value.replace(/[^\d+]/g, '')
);
