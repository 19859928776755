import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProfileCard from '../shared/ProfileCard';
import ShareByLinkButton from './ShareByLinkButton';

type ShareByLinkProps = {
  caseId: string;
}
export default function ShareByLink({ caseId }: ShareByLinkProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.shareCase.shareByLink' });

  return (
    <ProfileCard title={t('title')} sx={{ mt: 2, mb: 0 }}>
      <Typography>
        {t('description')}
      </Typography>
      <ShareByLinkButton caseId={caseId} />
    </ProfileCard>
  );
}
