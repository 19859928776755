import {
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemButtonProps,
  Link,
} from '@mui/material';
import { ReactNode } from 'react';

type NavListItemButtonProps = ListItemButtonProps & {
  href?: string;
  icon: ReactNode;
  selectedIcon: ReactNode;
  text: ReactNode;
};

export default function NavListItemButton({
  icon, selectedIcon, text, href, selected: isSelected, children, ...otherProps
}: NavListItemButtonProps) {
  return (
    <ListItemButton
      selected={isSelected}
      {...(href && { component: Link, href })}
      {...otherProps}
    >
      <ListItemIcon sx={{ ...(isSelected && { color: 'primary.main' }) }}>
        {isSelected ? selectedIcon : icon}
      </ListItemIcon>

      <ListItemText sx={{ ...(isSelected && { color: 'primary.main', fontWeight: 500 }) }} disableTypography>
        {text}
      </ListItemText>

      {children}
    </ListItemButton>
  );
}
