import Cookies from 'js-cookie';
import useCurrentUser from './useCurrentUser';

export default function useSessionCreate() {
  const { setCurrentUser } = useCurrentUser();

  return (token: string, userId: string) => {
    // Use non-secure cookie during development, for CORS reasons, while letting
    // the server set the auth token cookie securely on production.s
    if (process.env.NODE_ENV !== 'production') {
      Cookies.set('token', token);
    }

    const currentUser = { id: userId };
    setCurrentUser(currentUser);
    return currentUser;
  };
}
