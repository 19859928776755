import {
  Box, Card, CardActionArea, Stack, Typography, Collapse, styled, css,
} from '@mui/material';
import {
  capitalize, greaterThanDays, isPresent, toIsoDate,
} from 'lib/utils';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import { useTranslation } from 'react-i18next';
import ExpandCollapseButton from 'features/shared/ExpandCollapseButton';
import isPropValid from '@emotion/is-prop-valid';
import useToggle from 'lib/useToggle';
import useIsMobileScreen from 'lib/useIsMobileScreen';
import { PainReport } from '../api/usePainReports';
import PainLevelBox from './PainLevelBox';
import { PAIN_SCALE_COLORS } from '../shared';

/** Highlight animation duration, in `ms` */
export const HIGHLIGHT_ANIMATION_DURATION = 600;

type PainReportCardProps = Pick<PainReport, 'createDate' | 'description' | 'painLevel'> & {
  initiallyHighlighted?: boolean;
}
export default function PainReportCard({
  createDate, description, painLevel, initiallyHighlighted = false,
}: PainReportCardProps) {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const { t } = useTranslation('translation', { keyPrefix: 'painScale' });
  const isMobile = useIsMobileScreen();
  const { formatDate, formatRelativeDate } = useLocalizedDateFormatFns();
  const formattedDate = greaterThanDays(createDate, -7)
    ? formatDate(createDate)
    : formatRelativeDate(createDate);
  const formattedTime = formatDate(createDate, { format: { timeStyle: 'short' } });
  const isExpandable = isPresent(description);

  return (
    <Card sx={{ mb: 1 }}>
      <Box
        component={isMobile && isExpandable ? CardActionArea : 'div'}
        onClickCapture={isMobile && isExpandable ? toggleIsExpanded : undefined}
      >
        <CardContent
          initiallyHighlighted={initiallyHighlighted}
          highlightColor={PAIN_SCALE_COLORS[painLevel]}
        >
          <Stack direction="row">
            <PainLevelBox painLevel={painLevel} />
            <Stack direction="column" sx={{ pl: 2, py: 0.5 }}>
              <Typography
                variant="caption"
                color="text.disabled"
                component="time"
                dateTime={toIsoDate(createDate)}
              >
                {t('card.dateAndTime', {
                  date: capitalize(formattedDate),
                  time: formattedTime,
                })}
              </Typography>
              <Typography variant="body2" fontWeight="medium">
                {t(`aboutPainScale.levels.${painLevel}.title`)}
              </Typography>
            </Stack>
          </Stack>
          {isExpandable && <ExpandCollapseButton expanded={isExpanded} onClick={toggleIsExpanded} edge="end" />}
        </CardContent>
      </Box>
      {isExpandable
        && (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box sx={{ py: 1, px: 2, borderTop: (theme) => `1px solid ${theme.palette.grey[300]}` }}>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </Box>
          </Collapse>
        )}
    </Card>
  );
}

type CardContentOwnProps = {
  initiallyHighlighted: boolean;
  highlightColor: string;
}
const CardContent = styled(Stack, { shouldForwardProp: isPropValid })<CardContentOwnProps>((
  { theme, highlightColor, initiallyHighlighted },
) => css`
  align-items: center;
  box-shadow: inset 0 0 0 0 transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: ${theme.spacing(1)};

  ${initiallyHighlighted && css`
    animation: borderHighlight ${HIGHLIGHT_ANIMATION_DURATION}ms ease-in-out;
    animation-delay: ${HIGHLIGHT_ANIMATION_DURATION}ms;

    @keyframes borderHighlight {
      0% { box-shadow: inset 0 0 0 0 ${highlightColor} }
      50% { box-shadow: inset 0 0 0 2px ${highlightColor} }
      100% { box-shadow: inset 0 0 0 0 transparent;}
    }
  `}
`);
