import { useEffect } from 'react';

export type UseBeforeUnloadProps = {
  enabled: boolean;
  message?: string;
};

export default function useBeforeUnload({ enabled, message }: UseBeforeUnloadProps) {
  useEffect(() => {
    if (enabled) {
      const onBeforeUnload = makeEventHandler(message);

      window.addEventListener('beforeunload', onBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload);
      };
    }

    return () => { };
  }, [enabled, message]);
}

// Message does not work on most browsers
const makeEventHandler = (message: string = 'Are you sure you want to exit?') => (event: BeforeUnloadEvent) => {
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue = message;
  return event.returnValue;
};
