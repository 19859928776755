import type { } from '@mui/lab/themeAugmentation';
import { Components, Theme } from '@mui/material';

declare module '@mui/lab/TimelineDot' {
  interface TimelineDotPropsVariantOverrides {
    unstyled: true;
  }
}

export default function createMuiTimelineDot(): NonNullable<Components<Theme>['MuiTimelineDot']> {
  return ({
    styleOverrides: {
      root: {
        margin: '6px 0',
      },
    },
    variants: [
      {
        props: { variant: 'unstyled' },
        style: {
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: 0,
          boxShadow: 'none',
          margin: 0,
          paddingBottom: 0,
          paddingTop: 0,
        },
      },
    ],
  });
}
