import type { } from '@mui/lab/themeAugmentation';
import { Components, Theme } from '@mui/material';

export default function createMuiTimelineItem(): NonNullable<Components<Theme>['MuiTimelineItem']> {
  return ({
    styleOverrides: {
      root: {
        // left-align timeline, instead of the default centered layout
        '&:before': {
          flex: 0,
          padding: 0,
        },
        minHeight: 40,
      },
    },
  });
}
