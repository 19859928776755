import {
  Box, Button, IconButton, Link, Typography, Collapse,
} from '@mui/material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GenericEmptyState from 'features/shared/GenericEmptyState';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import useClickOrTapLabel from 'lib/useClickOrTapLabel';
import { pick } from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { isBlank } from 'lib/utils';
import AppHeaderTitleAccessory from 'features/shared/app-layout/AppHeaderTitleAccessory';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { usePainReports } from './api/usePainReports';
import PainReportCard, { HIGHLIGHT_ANIMATION_DURATION } from './internal/PainReportCard';
import { NEW_ENTRY_HIGHLIGHT_PARAM_KEY } from './shared';

export default function PainScale() {
  const { t } = useTranslation('translation', { keyPrefix: 'painScale' });
  const { data: painReports, isLoading, isError } = usePainReports();
  const [searchParams, setSearchParams] = useSearchParams();
  const [highlightId] = useState(searchParams.get(NEW_ENTRY_HIGHLIGHT_PARAM_KEY));
  const [highlightCardIsVisible, setHighlightCardIsVisible] = useState(false);

  useEffect(() => {
    if (highlightId && !highlightCardIsVisible && !isLoading) {
      setHighlightCardIsVisible(true);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(NEW_ENTRY_HIGHLIGHT_PARAM_KEY);
      setSearchParams(newSearchParams, { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isLoading) return <PageLayout pageTitle={t('title')!} centerContent><GenericLoading /></PageLayout>;
  if (isError) return <PageLayout pageTitle={t('title')!} centerContent><DataFetchingError /></PageLayout>;
  if (isBlank(painReports)) return <PageLayout pageTitle={t('title')!} centerContent><EmptyState /></PageLayout>;

  return (
    <PageLayout pageTitle={t('title')!}>
      <>
        <NewEntryButton />
        {painReports!.map((painReport) => {
          const isInitiallyHighlighted = highlightId === painReport.id;
          return (
            <Collapse
              in={isInitiallyHighlighted ? highlightCardIsVisible : true}
              appear={isInitiallyHighlighted}
              unmountOnExit
              timeout={HIGHLIGHT_ANIMATION_DURATION}
              key={painReport.id}
            >
              <PainReportCard
                initiallyHighlighted={isInitiallyHighlighted}
                {...pick(['createDate', 'description', 'painLevel'], painReport)}
              />
            </Collapse>
          );
        })}
      </>
    </PageLayout>
  );
}

function NewEntryButton() {
  const { t } = useTranslation('translation', { keyPrefix: 'painScale' });
  return (
    <Button
      component={Link}
      variant="contained"
      href="new"
      fullWidth
      sx={{ mb: 2 }}
      size="large"
    >
      {t('addEntryButton')}
    </Button>
  );
}

function EmptyState() {
  const { t } = useTranslation('translation', { keyPrefix: 'painScale' });
  const clickOrTapLabel = useClickOrTapLabel();

  return (
    <GenericEmptyState
      Icon={AssessmentOutlinedIcon}
      title={t('emptyState.title')!}
    >
      <Box sx={{ mt: 1 }}>
        <NewEntryButton />
        <Typography color="text.disabled" textAlign="center">
          <Trans t={t} i18nKey="emptyState.description">
            To learn more about the scale
            {' '}
            <br />
            {' '}
            {{ userAction: clickOrTapLabel }}
            {' '}
            the
            {' '}
            <InfoOutlinedIcon sx={{ fontSize: '1rem', verticalAlign: 'middle' }} />
            {' '}
            icon at the top
          </Trans>
        </Typography>
      </Box>
    </GenericEmptyState>
  );
}

type PageLayoutProps = {
  pageTitle: string;
  children: React.ReactNode;
  centerContent?: boolean;
}

function PageLayout({ pageTitle, children, centerContent }: PageLayoutProps) {
  return (
    <GenericPageLayout pageTitle={pageTitle} {...(centerContent && { centerContent })}>
      <AppHeaderTitleAccessory>
        <IconButton component={Link} href="/case/pain-scale/about" sx={{ height: '3rem', width: '3rem' }}>
          <InfoOutlinedIcon sx={{ fontSize: '1.25rem', color: 'common.white' }} />
        </IconButton>
      </AppHeaderTitleAccessory>
      {children}
    </GenericPageLayout>
  );
}
