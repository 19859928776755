import { Components, Theme } from '@mui/material';

export default function createMuiTab(): NonNullable<Components<Theme>['MuiTab']> {
  return ({
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  });
}
