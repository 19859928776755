import { useEffect, useState } from 'react';

export default function useConfirmationForAction(
  fn: () => void,
  { when: needsConfirmation }: { when: boolean },
) {
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  useEffect(() => {
    if (askForConfirmation && !needsConfirmation) {
      setAskForConfirmation(false);
    }
  }, [askForConfirmation, needsConfirmation]);

  const fnWithConfirmation = () => {
    if (needsConfirmation) {
      setAskForConfirmation(true);
    } else {
      fn();
    }
  };

  const confirm = fn;
  const cancel = () => setAskForConfirmation(false);

  return [
    fnWithConfirmation,
    askForConfirmation,
    confirm,
    cancel,
  ] as const;
}
