import { useTranslation } from 'react-i18next';
import {
  Button, CardContent, Box, Typography,
} from '@mui/material';
import WelcomeBanner from 'features/welcome/WelcomeBanner';
import useClickOrTapLabel from 'lib/useClickOrTapLabel';
import { capitalize } from 'lib/utils';
import { usePasswordSetupDetails } from 'features/shared/session/password-setup';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import FormCard from 'features/session/internal/FormCard';

const I18N_PREFIX = 'welcome';

export default function Welcome() {
  const { t } = useTranslation('translation', { keyPrefix: I18N_PREFIX });
  const clickOrTapLabel = useClickOrTapLabel();
  const isPasswordSetup = Boolean(usePasswordSetupDetails());
  const buttonDescription = t(
    `buttonDescription.${isPasswordSetup ? 'passwordSetup' : 'login'}`,
    { userAction: capitalize(clickOrTapLabel) },
  );

  return (
    <GenericPageLayout pageTitle={t('title')!} disableGutters="on-mobile" disableMargins="on-mobile">
      <FormCard hasMaxWidth={false}>
        <Box sx={{ '& img': { width: '100%' } }}>
          <WelcomeBanner />
        </Box>
        <CardContent>
          <Typography color="text.secondary">
            {t('description')}
          </Typography>
          <hr />
          <Typography>
            {buttonDescription}
          </Typography>
          <ContinueButton />
        </CardContent>
      </FormCard>
    </GenericPageLayout>
  );
}

function ContinueButton() {
  const { t } = useTranslation('translation', { keyPrefix: I18N_PREFIX });
  const isPasswordSetup = Boolean(usePasswordSetupDetails());

  const href = isPasswordSetup ? '/set-password' : '/login';

  return (
    <Button href={href} variant="contained" fullWidth sx={{ mt: 2 }}>
      {t('continueButton')}
    </Button>
  );
}
