import { Error as ErrorIcon } from '@mui/icons-material';
import { forwardRef } from 'react';
import Card, { CardProps } from './Card';

export type ErrorCardProps = Omit<CardProps, 'icon'>;

export default forwardRef<HTMLDivElement, ErrorCardProps>(function ErrorCard(props, ref) {
  return (
    <Card ref={ref} icon={<ErrorIcon color="error" />} {...props} />
  );
});
