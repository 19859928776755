import { useQueryClient } from 'react-query';
import useMutation from 'features/shared/api/useMutation';
import { createApiClient } from 'features/shared/api/client';
import deserialize, { ResponseBody } from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import useCurrentLocale from 'lib/useCurrentLocale';
import { CaseInvitee, getCaseInviteesQueryKey } from './useCaseInvitees';

const REQUEST_URL = toApiUrl('Login/RevokeInvite');

const apiClient = createApiClient();

export default function useRemoveCaseInvitee() {
  const queryClient = useQueryClient();
  const locale = useCurrentLocale();

  const mutation = useMutation<unknown, unknown, { caseId: string, inviteeId: string }>(
    ({ caseId, inviteeId }) => {
      return apiClient.post(REQUEST_URL, {
        client_ids: [caseId],
        user_id: inviteeId,
        recipient_language: locale,
      });
    },
    {
      onMutate: async ({ caseId, inviteeId }) => {
        const caseInviteesQueryKey = getCaseInviteesQueryKey(caseId);

        await queryClient.cancelQueries(caseInviteesQueryKey);

        const previousQueryData = queryClient.getQueryData<ResponseBody<CaseInvitee>>(
          caseInviteesQueryKey,
        );

        const previousCaseInvitees = (
          previousQueryData && deserialize<CaseInvitee[]>(previousQueryData)
        );

        if (previousCaseInvitees) {
          queryClient.setQueryData(
            caseInviteesQueryKey,
            // @ts-ignore
            (old: ResponseBody<any[]>) => {
              return {
                ...old,
                data: old?.data.filter((obj) => obj.data.ID !== inviteeId),
              };
            },
          );
        }

        return previousQueryData;
      },
      // On failure, roll back to the previous value
      onError: (_err, { caseId }, previousCaseInvitees) => {
        queryClient.setQueryData(getCaseInviteesQueryKey(caseId), previousCaseInvitees);
      },
      // After success or failure, refetch the query
      onSettled: (_data, _error, { caseId }) => {
        queryClient.invalidateQueries(getCaseInviteesQueryKey(caseId));
      },
    },
  );
  return { ...mutation };
}
