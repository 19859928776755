import { Navigate } from 'react-router-dom';

/**
 * Proceed with caution:
 *
 * Navigate usage inside App.tsx was restricted because, due to the way AppLayout
 * works, when an unexpected error occurs it is catched by an error boundary
 * whose fallback its rendered.
 *
 * In order to let the user be able to navigate to another page, we are using
 * `key` to make the error boundary from `AppLayout` unmount and mount again. That will
 * make it render its children instead of the fallback until a new error occurs.
 *
 * `Navigate` (or `DangerouslyNavigate`) won't work if it's not being rendered due to the fallback
 * so, in case you really need to use this, make sure the key of the error boundary changes as well.
 */
const DangerouslyNavigate = Navigate;
export default DangerouslyNavigate;
