import type { } from '@mui/lab/themeAugmentation';
import { Components, Theme } from '@mui/material';

export default function createMuiTimelineContent(): NonNullable<Components<Theme>['MuiTimelineContent']> {
  return ({
    styleOverrides: {
      root: {
        paddingRight: 0,
      },
    },
  });
}
