import { Components, Theme } from '@mui/material';
import createMuiAppBar from './createMuiAppBar';
import createMuiAvatar from './createMuiAvatar';
import createMuiBottomNavigationAction from './createMuiBottomNavigationAction';
import createMuiButton from './createMuiButton';
import createMuiButtonBase from './createMuiButtonBase';
import createMuiCssBaseline from './createMuiCssBaseline/createMuiCssBaseline';
import createMuiIconButton from './createMuiIconButton';
import createMuiLink from './createMuiLink';
import createMuiListItemIcon from './createMuiListItemIcon';
import createMuiTab from './createMuiTab';
import createMuiTabs from './createMuiTabs';
import createMuiTextField from './createMuiTextField';
import createMuiTimelineContent from './createMuiTimelineContent';
import createMuiTimelineDot from './createMuiTimelineDot';
import createMuiTimelineItem from './createMuiTimelineItem';
import createMuiToolbar from './createMuiToolbar';
import createMuiCard from './createMuiCard';
import createMuiCardContent from './createMuiCardContent';
import createMuiCardHeader from './createMuiCardHeader';
import createMuiFormHelperText from './createMuiFormHelperText';
import createMuiDialogTitle from './createMuiDialogTitle';
import createMuiDialogContentText from './createMuiDialogContentText';

export default (theme: Theme): Components<Theme> => ({
  MuiAppBar: createMuiAppBar(),
  MuiAvatar: createMuiAvatar(theme),
  MuiBottomNavigationAction: createMuiBottomNavigationAction(theme),
  MuiButton: createMuiButton(),
  MuiButtonBase: createMuiButtonBase(),
  MuiCard: createMuiCard(),
  MuiCardContent: createMuiCardContent(),
  MuiCardHeader: createMuiCardHeader(theme),
  MuiCssBaseline: createMuiCssBaseline(theme),
  MuiDialogTitle: createMuiDialogTitle(theme),
  MuiDialogContentText: createMuiDialogContentText(theme),
  MuiFormHelperText: createMuiFormHelperText(),
  MuiIconButton: createMuiIconButton(),
  MuiLink: createMuiLink(),
  MuiListItemIcon: createMuiListItemIcon(),
  MuiTab: createMuiTab(),
  MuiTabs: createMuiTabs(),
  MuiTextField: createMuiTextField(),
  MuiTimelineContent: createMuiTimelineContent(),
  MuiTimelineDot: createMuiTimelineDot(),
  MuiTimelineItem: createMuiTimelineItem(),
  MuiToolbar: createMuiToolbar(theme),
});
