// https://stackoverflow.com/a/69450583/3760848
import {
  Color, Palette, PaletteColor, Theme,
} from '@mui/material';

// "primary" | "secondary" | "error" | "warning" | "info" | "success"
type PaletteKey = keyof {
  [Key in keyof Palette as Palette[Key] extends PaletteColor ? Key : never]: true;
}
// "grey"
type GreyPaletteKey = keyof {
  [Key in keyof Palette as Palette[Key] extends Color ? Key : never]: true;
}

// "light" | "dark" | "main" | "contrastText"
type PaletteColorKey = keyof PaletteColor
// "50", "100", "A100", etc.
type GreyPaletteColorKey = keyof Color;

export type ColorSwatch = `${PaletteKey}.${PaletteColorKey}` | `${GreyPaletteKey}.${GreyPaletteColorKey}`;

/**
 * Get the value of a color swatch from the theme palette.
 *
 * Limited support for the moment (check definition).
 * @example
 * getColorFromTheme('success.main', theme) => #2e7d32
 * getColorFromTheme('grey.900', theme) => #212121
 * getColorFromTheme('info.contrastText', theme) => #fff
 */
export default function getColorFromTheme(swatch: ColorSwatch, theme: Theme) {
  const [parentColor, childColor] = swatch.split('.');
  return theme.palette[parentColor as PaletteKey][childColor as PaletteColorKey];
}
