import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

/**
 * Hook that returns a boolean if the screen size is considered "mobile".
 * @param breakpoint Breakpoint below which is considered "mobile screen". Defaults to `md`.
 */
export default function useIsMobileScreen(breakpoint: number | Breakpoint = 'md' as Breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}
