import { Typography } from '@mui/material';
import { useCopyToClipboard } from 'usehooks-ts';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'lib/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useGenerateInviteLink from './api/useGenerateInviteLink';

type ShareByLinkButtonProps = {
  caseId: string;
}

type ButtonState = 'default' | 'loading' | 'successCopied' | 'successShared' | 'error' | 'shareError';

export default function ShareByLinkButton({ caseId }: ShareByLinkButtonProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.shareCase.shareByLink.submitButton' });
  const [, copyToClipboard] = useCopyToClipboard();
  const [buttonState, setButtonState] = useState<ButtonState>('default');
  const [inviteUrl, setInviteUrl] = useState<string>();
  const { mutateAsync: requestInviteUrl } = useGenerateInviteLink();
  const buttonLabel = (() => {
    switch (buttonState) {
      case 'default':
      case 'error':
      case 'loading':
      case 'shareError':
        return t(buttonState);
      case 'successCopied':
      case 'successShared':
        return (
          <>
            <CheckCircleIcon sx={{ mr: 1 }} />
            {t(buttonState)}
          </>
        );
      default:
        return '';
    }
  })();

  const buttonColor = (() => {
    switch (buttonState) {
      case 'successShared':
      case 'successCopied':
        return 'success';
      case 'default':
      case 'error':
      case 'shareError':
      default:
        return 'primary';
    }
  })();

  const handleClick = () => {
    setButtonState('loading');
    requestInviteUrl(caseId)
      .then((url) => {
        setInviteUrl(url);
        shareUrl(url);
      })
      .catch(() => setButtonState('error'));
  };

  const shareUrl = (url: string) => {
    // Sharesheet limitations: not working on Chrome on desktop, works only on HTTPS
    // Clipboard copy limitations: may not work on Safari for desktop due to permissions
    //
    // If any of the above happen, a text input fallback will be show
    // for the user to copy the link manually
    // @ts-ignore
    if (navigator.canShare) {
      navigator.share({ url })
        .then(() => onShareSuccess('successShared'))
        .catch(onShareError);
    } else {
      copyToClipboard(url)
        .then(() => onShareSuccess('successCopied'))
        .catch(onShareError);
    }
  };

  const onShareSuccess = (newState: ButtonState) => {
    setButtonState(newState);
    setTimeout(() => {
      setButtonState('default');
    }, 3000);
  };

  const onShareError = () => {
    setButtonState('shareError');
  };

  return (
    <>
      <LoadingButton
        fullWidth
        color={buttonColor}
        variant="contained"
        size="large"
        loading={buttonState === 'loading'}
        disabled={buttonState === 'loading'}
        sx={{ mt: 2 }}
        onClick={handleClick}
      >
        {buttonLabel}
      </LoadingButton>
      {buttonState === 'shareError' && (
        <>
          <Typography sx={{ mt: 1 }}>
            {t('shareErrorDetails')}
          </Typography>
          <TextField
            value={inviteUrl}
            inputProps={{ readOnly: true }}
            fullWidth
            name="shareUrlInput"
            onClick={(e) => (e.target as HTMLInputElement).select()}
            sx={{ mt: 1 }}
          />
        </>
      )}
    </>
  );
}
