import { RefObject, useEffect, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FileUploader as RDDFFileUploader } from 'react-drag-drop-files';

export type FileUploaderProps = RDDFFileUploaderProps & {
  browseButtonRef?: RefObject<HTMLButtonElement>;
}

// https://github.com/KarimMokhtar/react-drag-drop-files/blob/dev/src/FileUploader.tsx#L14
type RDDFFileUploaderProps = {
  name?: string;
  hoverTitle?: string;
  types?: Array<string>;
  classes?: string;
  children?: JSX.Element;
  maxSize?: number;
  minSize?: number;
  fileOrFiles?: Array<File> | File | null;
  disabled?: boolean | false;
  label?: string | undefined;
  multiple?: boolean | false;
  onSizeError?: (arg0: string) => void;
  onTypeError?: (arg0: string) => void;
  onDrop?: (arg0: File | Array<File>) => void;
  onSelect?: (arg0: File | Array<File>) => void;
  handleChange?: (arg0: FileList) => void;
  onDraggingStateChange?: (dragging: boolean) => void;
}

/**
 * Acts the same as the `FileUploader` from `react-drag-drop-files` except it
 * prevents click events forwarding from the container to the inner input when a
 * `browseButtonRef` is passed. In that case input will receive the `click` event trigger
 * only when the element within `browseButtonRef` is clicked.
 */
export default function FileUploader({ browseButtonRef, ...otherProps }: FileUploaderProps) {
  const rootElementRef = useRef<HTMLDivElement>(null);
  const expectsBrowseButton = Boolean(browseButtonRef);

  useEffect(() => {
    if (expectsBrowseButton) {
      disableFileSelectionOnClickOutsideBrowseButton({
        container: rootElementRef.current!,
        browseButton: browseButtonRef!.current!,
      });
    }
  }, [expectsBrowseButton, browseButtonRef]);

  return (
    <div ref={rootElementRef}>
      <RDDFFileUploader
        key={expectsBrowseButton}
        {...otherProps}
      />
    </div>
  );
}

type DisableFileSelectionOnClickOutsideBrowseButtonOptions = {
  container: HTMLElement;
  browseButton: HTMLElement;
};

// This actually relies on the internal behaviour of 'react-drag-drop-files' so it's
// not the best thing.
// Check this for removal sync
// https://react-query.tanstack.com/reference/MutationCache#mutationcachesubscribe
const disableFileSelectionOnClickOutsideBrowseButton = (
  { container, browseButton }: DisableFileSelectionOnClickOutsideBrowseButtonOptions,
) => {
  const inputElement = container.querySelector('input[type="file"]')!;
  inputElement.setAttribute('disabled', '');

  browseButton.addEventListener('click', () => {
    inputElement.removeAttribute('disabled');
    enqueueTask(() => {
      inputElement.setAttribute('disabled', '');
    });
  });
};

const enqueueTask = (fn: () => void) => {
  setTimeout(fn, 0);
};
