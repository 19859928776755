import useCurrentCase from 'features/shared/current-case/useCurrentCase';

/**
 * Returns true if the server confirmed there is no case
 * associated with the current user's account.
 */

export default function useNoAvailableCaseCheck() {
  const {
    currentCase, isLoading: isLoadingCurrentCase, isError: failedToFetchCurrentCase,
  } = useCurrentCase();

  return !currentCase && !isLoadingCurrentCase && !failedToFetchCurrentCase;
}
