import { createApiClient } from 'features/shared/api/client';
import { toApiUrl } from 'features/shared/api/request';
import useMutation from 'features/shared/api/useMutation';
import { User } from './useUserDetails';

export type AccountInfoFormValues = Pick<User, 'cellPhone' | 'displayName' | 'email'>
export type ChangePasswordFormValues = {
  oldPassword: string;
  newPassword: string;
}
export type AddressFormValues = Pick<User, 'address1' | 'address2'>;

const apiClient = createApiClient();

export function useUpdateAccountInfoMutation(userId: string) {
  return useMutation(({
    cellPhone, displayName, email,
  }: AccountInfoFormValues) => (
    apiClient.put(toApiUrl(`User/${userId}`), { CellPhone: cellPhone, DisplayName: displayName, Email: email })
  ));
}

export function useUpdatePasswordMutation(userId: string) {
  return useMutation(({
    oldPassword, newPassword,
  }: ChangePasswordFormValues) => (
    apiClient.post(toApiUrl('/Users/ChangePassword'), {
      user_id: userId, old_password: oldPassword, new_password: newPassword,
    })
  ));
}

export function useUpdateAddressMutation(userId: string) {
  return useMutation(({
    address1, address2,
  }: AddressFormValues) => (
    apiClient.put(toApiUrl(`User/${userId}`), { Address1: address1, Address2: address2 })
  ));
}
