import isPropValid from '@emotion/is-prop-valid';
import { css, styled, Typography } from '@mui/material';
import { PainReport } from 'features/pain-scale/api/usePainReports';
import { PainLevel, PAIN_SCALE_COLORS } from 'features/pain-scale/shared';
import { useTranslation } from 'react-i18next';

type PainLevelBoxProps = Pick<PainReport, 'painLevel'>;
export default function PainLevelBox({ painLevel }: PainLevelBoxProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'painScale.card' });
  return (
    <PainLevelColorBox
      painLevel={painLevel}
      aria-label={t('painLevelAriaLabel', { painLevel })}
    >
      <Typography variant="body2" fontWeight="medium">
        {painLevel}
      </Typography>
    </PainLevelColorBox>
  );
}

const PainLevelColorBox = styled(
  'div',
  { shouldForwardProp: isPropValid },
)<{ painLevel: PainLevel }>(({ theme, painLevel }) => css`
  align-items: center;
  background-color: ${PAIN_SCALE_COLORS[painLevel]};
  color: ${theme.palette.getContrastText(PAIN_SCALE_COLORS[painLevel])};
  display: inline-flex;
  height: 48px;
  justify-content: center;
  text-align: center;
  width: 48px;
`);
