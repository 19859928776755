import { useCurrentUser } from 'features/shared/session/auth';
import { PasswordSetupDetails } from 'features/shared/session/password-setup';
import { DEFAULT_PAGE_WHEN_USER_IS_AUTHENTICATED } from './constants';

export default function useDefaultPage(passwordSetupDetails: PasswordSetupDetails | null) {
  const { currentUser } = useCurrentUser();

  if (currentUser) return DEFAULT_PAGE_WHEN_USER_IS_AUTHENTICATED;
  if (passwordSetupDetails?.isReset) return '/set-password';
  return '/welcome';
}
