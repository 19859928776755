import isPropValid from '@emotion/is-prop-valid';
import {
  Box, css, GlobalStyles, styled, Theme,
} from '@mui/material';
import { ComponentPropsWithoutRef } from 'react';

type FileUploaderContentProps = ComponentPropsWithoutRef<'div'> & {
  isDraggingFileOver: boolean;
}

const BORDER_RADIUS = 8;

export function FileUploaderContent({ children, ...otherProps }: FileUploaderContentProps) {
  return (
    <>
      <FileUploaderContentRoot {...otherProps}>
        <Box sx={{ width: '100%', maxWidth: { md: '343px' } }}>
          {children}
        </Box>
      </FileUploaderContentRoot>

      <GlobalStyles
        styles={css`
          .file-upload-wrapper > div:not(.description-wrapper) {
            background-color: transparent;
            border-color: transparent;
            padding: 0;
            margin: 0;
          }
        `}
      />
    </>
  );
}

export const FileUploaderContentRoot = styled('div', { shouldForwardProp: isPropValid })<
  Omit<FileUploaderContentProps, 'children'>
>(({ isDraggingFileOver, theme }) => {
  return css`
    align-items: center;
    border-radius: ${BORDER_RADIUS}px;
    color: ${theme.palette.text.secondary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
    position: relative;
    text-align: center;
    transition: background .24s ease-in-out;

    ${getSizeAndSpacingCss(theme)}

    ${isDraggingFileOver && css`
      background-color: ${theme.palette.grey[50]};
    `}

    ${theme.breakpoints.up('md')} {
      background-image: url("data:image/svg+xml,${encodeURIComponent(`<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' fill='none' rx='${BORDER_RADIUS}' ry='${BORDER_RADIUS}' stroke='${getColorForStyle({ theme, isDraggingFileOver })}' stroke-width='2' stroke-dasharray='8, 6' stroke-dashoffset='0' stroke-linecap='square'/></svg>`)}");
      border-width: 2px;
    }

`;
});

const getSizeAndSpacingCss = (theme: Theme) => {
  const marginTopFromAppLayoutSpacing = 2;
  const marginTopSpacing = 1;
  const marginBottomSpacing = 3;
  const totalMarginY = theme.spacing(
    marginTopFromAppLayoutSpacing + marginTopSpacing + marginBottomSpacing,
  );

  return css`
    min-height: calc(100vh - ${theme.sizes.bottomNavigationHeight}px - ${theme.sizes.toolbarDenseHeight}px - ${totalMarginY});
    ${theme.breakpoints.up('md')} {
      min-height: calc(100vh - ${theme.sizes.toolbarHeight}px - ${totalMarginY});
    }

    margin-top: ${theme.spacing(marginTopSpacing)};
    margin-bottom: ${theme.spacing(marginBottomSpacing)};
    padding: ${theme.spacing(6)} 0;
  `;
};

type GetColorForStyleOptions = {
  theme: Theme;
  isDraggingFileOver: boolean;
}

const getColorForStyle = ({ theme, isDraggingFileOver }: GetColorForStyleOptions) => {
  if (isDraggingFileOver) {
    return theme.palette.primary.main;
  }
  return 'rgba(0, 0, 0, 0.23)';
};
