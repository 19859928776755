import { Container, Stack, useTheme } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';
import { ResponsiveBoolean, useResponsiveBooleanResolver } from 'lib/responsive-utils';
import AppLayoutPageTitle from './app-layout/AppLayoutPageTitle';
import { getContentPaddingY as getAppLayoutContentPaddingY } from './app-layout/styles';
import GenericErrorBoundaryFallback from './GenericErrorBoundaryFallback';

type GenericPageLayoutProps = LayoutRootProps & {
  pageTitle: string;
}

export default function GenericPageLayout(
  { pageTitle, ...layoutRootProps }: GenericPageLayoutProps,
) {
  return (
    <>
      {pageTitle && <AppLayoutPageTitle>{pageTitle}</AppLayoutPageTitle>}

      {/** @ts-ignore */}
      <ErrorBoundary
        fallback={(
          <LayoutRoot centerContent><GenericErrorBoundaryFallback /></LayoutRoot>
        )}
      >
        <LayoutRoot {...layoutRootProps} />
      </ErrorBoundary>
    </>
  );
}

type LayoutRootProps = {
  children: ReactNode;
  centerContent?: ResponsiveBoolean;
  disableGutters?: ResponsiveBoolean;
  disableMargins?: ResponsiveBoolean;
}

function LayoutRoot({
  children,
  centerContent: centerContentInput = false,
  disableGutters: disableGuttersInput = false,
  disableMargins: disableMarginsInput = false,
}: LayoutRootProps) {
  const resolveReponsiveBoolean = useResponsiveBooleanResolver();
  const centerContent = resolveReponsiveBoolean(centerContentInput);
  const disableGutters = resolveReponsiveBoolean(disableGuttersInput);
  const disableMargins = resolveReponsiveBoolean(disableMarginsInput);

  const theme = useTheme();
  const appLayoutContentPaddingY = getAppLayoutContentPaddingY(theme);

  return (
    <Container
      sx={{
        maxWidth: { xs: theme.breakpoints.values.sm, xl: theme.breakpoints.values.md },
        height: `calc(100% + ${disableMargins ? `calc(2 * ${appLayoutContentPaddingY})` : '0px'})`,
      }}
      disableGutters={disableGutters}
    >
      <Stack
        sx={{
          height: '100%',
          my: disableMargins ? `-${appLayoutContentPaddingY}` : 0,
          ...(centerContent ? { justifyContent: 'center', alignItems: 'center' } : { width: '100%' }),
        }}
      >
        {children}
      </Stack>
    </Container>
  );
}
