import {
  Stack, Typography, Collapse, Box,
} from '@mui/material';
import ExpandCollapseButton from 'features/shared/ExpandCollapseButton';
import DataFetchingError from 'features/shared/DataFetchingError';
import GenericLoading from 'features/shared/GenericLoading';
import { useCurrentUser } from 'features/shared/session/auth';
import useToggle from 'lib/useToggle';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import GenericErrorBoundaryFallback from 'features/shared/GenericErrorBoundaryFallback';
import { ErrorBoundary } from '@sentry/react';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import { useCaseInvitees } from './api/useCaseInvitees';
import InviteeCard from './InviteeCard';
import useRemoveCaseInvitee from './api/useRemoveCaseInvitee';

type CaseInviteesProps = {
  caseId: string;
}

export default function CaseInvitees({ caseId }: CaseInviteesProps) {
  const { currentUser } = useCurrentUser();
  const { currentCase } = useCurrentCase();
  const { data: invitees, isLoading, isError } = useCaseInvitees(caseId, currentUser!.id);
  const isCurrentUserOwnerOfCurrentCase = currentUser!.id === currentCase!.ownerId;
  const { mutate: removeInvitee } = useRemoveCaseInvitee();

  const { t } = useTranslation('translation', { keyPrefix: 'profile.shareCase.sharedWith' });

  if (isLoading) return <SectionLayout title={t('title')}><GenericLoading size="sm" /></SectionLayout>;
  if (isError) return <SectionLayout title={t('title')}><DataFetchingError fullWidth /></SectionLayout>;

  return (
    <SectionLayout title={t('title')}>
      <TransitionGroup>
        {invitees!.map((invitee) => {
          const isInviteeCurrentUser = invitee.userId === currentUser!.id;
          const isRemovable = isCurrentUserOwnerOfCurrentCase && !isInviteeCurrentUser;

          return (
            <Collapse key={invitee.id} timeout="auto" unmountOnExit>
              <InviteeCard
                invitee={invitee}
                isCurrentUser={isInviteeCurrentUser}
                {...(isRemovable && {
                  onRemove: () => removeInvitee({ caseId: currentCase!.id, inviteeId: invitee.id }),
                })}
              />
            </Collapse>
          );
        })}
      </TransitionGroup>
    </SectionLayout>
  );
}

type SectionLayoutProps = {
  title: string;
  children: React.ReactNode;
}
function SectionLayout({ title, children }: SectionLayoutProps) {
  const [expanded, toggleExpanded] = useToggle(true);
  return (
    <Box sx={{ mb: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2.25, mb: 1 }}>
        <Typography variant="subtitle1" color="text.secondary" sx={{ textTransform: 'uppercase' }}>
          {title}
        </Typography>
        <ExpandCollapseButton
          expanded={expanded}
          onClick={toggleExpanded}
        />
      </Stack>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {/** @ts-ignore */}
        <ErrorBoundary fallback={<GenericErrorBoundaryFallback fullWidth />}>
          {children}
        </ErrorBoundary>
      </Collapse>
    </Box>
  );
}
