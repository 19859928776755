import { TextFieldProps } from 'lib/TextField';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'features/shared/form';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordTextField from 'lib/PasswordTextField';
import Form from 'features/shared/form/Form';
import { FormProvider } from 'react-hook-form';
import { useUpdatePasswordMutation, ChangePasswordFormValues } from './api/updateAccountMutations';
import ProfileCard from '../shared/ProfileCard';

type ChangePasswordFormProps = {
  userId: string;
  onSuccess(): void;
}

const VALIDATION_SCHEMA = Yup.object().shape({
  oldPassword: Yup.string().required(),
  newPassword: Yup.string().required(),
});

const FORM_FIELDS: (keyof ChangePasswordFormValues)[] = ['oldPassword', 'newPassword'];
const FIELD_PROPS: { [K in keyof ChangePasswordFormValues]: Omit<TextFieldProps, 'name'> } = {
  oldPassword: {
    autoComplete: 'current-password',
  },
  newPassword: {
    autoComplete: 'new-password',
  },
};

export default function ChangePasswordForm({ userId, onSuccess }: ChangePasswordFormProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'profile.accountSettings.forms.changePassword' });
  const { mutateAsync: changePassword } = useUpdatePasswordMutation(userId);

  const formMethods = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    reValidateMode: 'onSubmit',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });
  const {
    control, handleSubmit, reset, formState: { errors, isSubmitting },
  } = formMethods;

  const onFormSubmit = async (props: ChangePasswordFormValues) => {
    return changePassword(props).then(() => reset()).then(onSuccess);
  };

  return (
    <ProfileCard title={t('title')}>
      <FormProvider {...formMethods}>
        <Form
          onSubmit={handleSubmit(onFormSubmit)}
          submitButtonLabel={t('submitButton.label')}
          isSubmitting={isSubmitting}
          errors={errors}
        >

          {FORM_FIELDS.map((fieldName) => (
            <PasswordTextField
              control={control}
              key={fieldName}
              name={fieldName}
              fullWidth
              {...FIELD_PROPS[fieldName]}
              type="password"
              label={t(`${fieldName}.label`)}
              errors={errors[fieldName]}
            />
          ))}
        </Form>
      </FormProvider>
    </ProfileCard>
  );
}
