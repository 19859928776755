import useQuery from 'features/shared/api/useQuery';
import deserialize from 'features/shared/api/deserialize';
import { createApiClient } from 'features/shared/api/client';
import { toApiUrl } from 'features/shared/api/request';

const REQUEST_URL = toApiUrl('FAQ');

export type FAQ = {
  type: 'FAQ';
  id: string;
  name: string;
  content: string;
}

const apiClient = createApiClient();

export default function useFaqs() {
  return useQuery(
    ['faqs'],
    async () => {
      return (await apiClient.get(REQUEST_URL)).data;
    },
    {
      select: (data) => deserialize<FAQ[]>(data),
    },
  );
}
