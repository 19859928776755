import useNotifications from 'features/notifications/api/useNotifications';
import {
  filter, indexBy, prop, whereEq,
} from 'ramda';
import { History } from './api/useHistories';

export type HistoryWithNotificationStatus = History & ({
  isUnread: true;
  notificationId: string;
} | {
  isUnread: false;
  notificationId: null;
})

export default function useHistoriesWithNotificationStatus(histories: History[]) {
  const { data: notifications, isLoading, isError } = useNotifications();

  if (isLoading || isError) {
    return histories.map<HistoryWithNotificationStatus>((history) => ({
      ...history, isUnread: false, notificationId: null,
    }));
  }

  const unreadHistoriesNotifications = indexBy(
    prop('recordId'),
    filter(whereEq({ type: 'Unread History', readStatus: 'Unread' }), notifications!),
  );

  return histories.map((history) => {
    const historyNotification = unreadHistoriesNotifications[history.id];
    const isUnread = Boolean(historyNotification);
    return {
      ...history,
      isUnread,
      notificationId: historyNotification?.id,
    } as HistoryWithNotificationStatus;
  });
}
