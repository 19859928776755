import { createApiClient } from 'features/shared/api/client';
import { toApiUrl } from 'features/shared/api/request';
import useMutation from 'features/shared/api/useMutation';

const apiClient = createApiClient();

export default function useForgotPasswordMutation() {
  return useMutation(({ user }: { user: string }) => {
    return apiClient.post(toApiUrl('Users/ForgotPassword'), { username: user });
  });
}
