import { createTheme } from '@mui/material';
import { createComponents } from './components';
import createPalette from './createPalette';
import createTypography from './createTypography';

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      bottomNavigationHeight: number;
      sidebarWidth: number;
      toolbarHeight: number;
      toolbarDenseHeight: number;
    };
  }

  interface ThemeOptions {
    sizes: {
      bottomNavigationHeight: number;
      sidebarWidth: number;
      toolbarHeight: number;
      toolbarDenseHeight: number;
    };
  }
}

const themeWithoutComponents = createTheme({
  sizes: {
    bottomNavigationHeight: 56,
    sidebarWidth: 320,
    toolbarHeight: 64,
    toolbarDenseHeight: 48,
  },
  breakpoints: {
    // override sm from 600 to 688
    values: {
      xs: 0,
      sm: 688,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: createPalette(),
  typography: createTypography(),
});

const theme = createTheme(themeWithoutComponents, {
  components: createComponents(themeWithoutComponents),
});

syncMetaThemeColor();

export default theme;

function syncMetaThemeColor() {
  const metaElement = document.querySelector('meta[name="theme-color"]') ?? document.createElement('meta');

  metaElement.setAttribute('name', 'theme-color');
  metaElement.setAttribute('content', theme.palette.primary.main);

  if (!metaElement.parentElement) {
    document.head.appendChild(metaElement);
  }
}
