import { createApiClient } from 'features/shared/api/client';
import { toApiUrl } from 'features/shared/api/request';
import useMutation from 'features/shared/api/useMutation';

const REQUEST_URL = toApiUrl('Feedback');

export type FeedbackType = 'app' | 'firm';

export type FeedbackFormValues = {
  feedbackType: FeedbackType | '';
  rating: number;
  comments: string;
}

const apiClient = createApiClient();

export default function useSendFeedbackMutation() {
  return useMutation(({ feedbackType, rating, comments }: FeedbackFormValues) => {
    return apiClient.post(REQUEST_URL, {
      type: serializeFeedbackType(feedbackType as FeedbackType),
      rating,
      comments,
    });
  });
}

const serializeFeedbackType = (feedbackType: FeedbackType) => {
  if (feedbackType === 'app') return 'Client Portal';
  return 'Law Firm';
};
