import { SvgIcon, Theme, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useState } from 'react';
import { keys } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Timeline as MuiTimeline } from '@mui/lab';
import TimelineSection from './TimelineSection';
import TimelineStepsCards from './TimelineStepsCards';
import { RecoveryJourneyStepType, RecoveryJourneyData } from '../api/useRecoveryJourney';

type TimelineSections = {
  [K in RecoveryJourneyStepType]: {
    icon: typeof SvgIcon;
    textColor: string;
  }
}

const SECTIONS: TimelineSections = {
  completedSteps: {
    icon: CheckCircleIcon,
    textColor: 'text.secondary',
  },
  currentSteps: {
    icon: RadioButtonCheckedIcon,
    textColor: 'text.primary',
  },
  futureSteps: {
    icon: RadioButtonUncheckedIcon,
    textColor: 'text.secondary',
  },
};

type ExpandState = {
  [K in RecoveryJourneyStepType]: boolean;
}

const INITIAL_EXPAND: ExpandState = {
  completedSteps: false,
  currentSteps: true,
  futureSteps: false,
};

type TimelineStats = {
  [K in RecoveryJourneyStepType]: number;
}

type TimelineProps = {
  data: RecoveryJourneyData;
  stats: TimelineStats;
}

export default function Timeline({ data, stats }: TimelineProps) {
  const [expand, setExpand] = useState(INITIAL_EXPAND);

  const handleExpand = (type: RecoveryJourneyStepType) => {
    setExpand({ ...expand, [type]: !expand[type] });
  };

  const { t } = useTranslation('translation', { keyPrefix: 'caseStatus.timeline.sectionTitles' });
  const theme = useTheme();

  return (
    <MuiTimeline sx={{ p: 0, pl: '6px', my: 0 }}>
      {keys(SECTIONS).map((type) => {
        const Icon = SECTIONS[type].icon;
        const stepsData = data && data[type];
        const accentColor = colorForStepType(theme, type);
        return (
          stepsData && (
            <TimelineSection
              expanded={expand[type]}
              icon={<Icon sx={{ color: accentColor }} />}
              key={type}
              onExpand={() => handleExpand(type)}
              textColor={SECTIONS[type].textColor}
              title={t(type, { count: stats[type] })}
            >
              <TimelineStepsCards accentColor={accentColor} steps={stepsData} />
            </TimelineSection>
          )
        );
      })}
    </MuiTimeline>
  );
}

export const colorForStepType = (theme: Theme, type: RecoveryJourneyStepType) => {
  switch (type) {
    case 'completedSteps':
      return theme.palette.success.main;
    case 'currentSteps':
      return theme.palette.info.main;
    case 'futureSteps':
      return theme.palette.grey[500];
    default:
      return theme.palette.grey[500];
  }
};
