import { createApiClient } from 'features/shared/api/client';
import { ResponseBody } from 'features/shared/api/deserialize';
import { toApiUrl } from 'features/shared/api/request';
import useMutation from 'features/shared/api/useMutation';
import useCurrentCase from 'features/shared/current-case/useCurrentCase';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const REQUEST_URL = toApiUrl('PainReport');

const apiClient = createApiClient();

export type NewPainReportValues = {
  painLevel: number;
  description: string | undefined;
}

export default function useCreatePainReportMutation() {
  const queryClient = useQueryClient();
  const { t } = useTranslation('translation', { keyPrefix: 'painScale.aboutPainScale.levels' });
  const currentCase = useCurrentCase().currentCase!;

  return useMutation(async ({ painLevel, description }: NewPainReportValues) => {
    const result = await apiClient.post(
      REQUEST_URL,
      {
        PainLevel: painLevel,
        Description: description,
        Name: t(`${painLevel}.title`),
      },
      { params: { link_to: currentCase.id } },
    );

    return result.data.data.entities[0];
  }, {
    onSuccess: (newPainReportData) => {
      // @ts-ignore
      queryClient.setQueriesData(
        'painReports',
        (old: ResponseBody<any[]>) => {
          return {
            ...old,
            data: [newPainReportData, ...old.data],
          };
        },
      );
    },
  });
}
