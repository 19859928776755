import { range } from 'ramda';

export type PainLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export const MAX_PAIN_LEVEL = 10;
export const PAIN_LEVELS = range(0, MAX_PAIN_LEVEL + 1) as PainLevel[];
export const PAIN_SCALE_COLORS: Record<PainLevel, string> = {
  0: '#75A64F',
  1: '#83B25E',
  2: '#8DB760',
  3: '#92B760',
  4: '#C1C759',
  5: '#EBCA51',
  6: '#E2B24C',
  7: '#D08340',
  8: '#C96D3C',
  9: '#C05335',
  10: '#BC3D34',
};
export const NEW_ENTRY_HIGHLIGHT_PARAM_KEY = 'highlightID';
