import { LocaleCode } from 'i18n';
import { useTranslation } from 'react-i18next';

/**
 * Returns the current locale code.
 * @example
 * const currentLocale = useCurrentLocale() // => 'es'
 * const currentLocale = useCurrentLocale() // => 'en'
 */
export default function useCurrentLocale(): LocaleCode {
  const { i18n } = useTranslation();
  return i18n.language as LocaleCode;
}

export type {
  LocaleCode,
};
