import { useTranslation } from 'react-i18next';
import GenericPageLayout from 'features/shared/GenericPageLayout';
import PageNotFoundError from 'features/shared/PageNotFoundError';

export default function PageNotFound() {
  const { t } = useTranslation('translation', { keyPrefix: 'generic.pageNotFound' });
  return (
    <GenericPageLayout pageTitle={t('title')!} centerContent>
      <PageNotFoundError />
    </GenericPageLayout>
  );
}
