import { useTranslation } from 'react-i18next';
import useLocalizedDateFormatFns from 'lib/useLocalizedDateFormatFns';
import useCurrentCase, { Client } from 'features/shared/current-case/useCurrentCase';
import { ErrorBoundary } from '@sentry/react';

export default function CurrentCaseDetails() {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation.sidebar.currentCase' });

  const { currentCase, isLoading, isError } = useCurrentCase();

  if (isLoading || !currentCase) return <span>{t('loading')}</span>;
  if (isError) return <span>{t('error')}</span>;

  return (
    // @ts-ignore
    <ErrorBoundary fallback={<span>{t('error')}</span>}>
      <FormattedCaseDetails caseObj={currentCase} />
    </ErrorBoundary>
  );
}

function FormattedCaseDetails({ caseObj: { name, createDate } }: { caseObj: Client }) {
  const { formatDate } = useLocalizedDateFormatFns();

  return (
    <span>
      {`${name} ${formatDate(createDate, { format: {} })}`}
    </span>
  );
}
