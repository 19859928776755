import {
  css, Stack, styled, Tabs as MuiTabs, TabsProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { CORNERS_RADIUS, getContentPaddingY } from './styles';

export default function AppLayoutTabs(props: TabsProps) {
  return (
    <Root>
      <FixedWrapper>
        <Tabs variant="fullWidth" {...props} />
      </FixedWrapper>
      <Tabs sx={{ visibility: 'hidden' }} />
    </Root>
  );
}

export function AppLayoutTabsContainer({ children }: { children: ReactNode }) {
  return <Stack sx={{ height: '100%' }}>{children}</Stack>;
}

export function AppLayoutTabPanelsContainer({ children }: { children: ReactNode }) {
  return <Stack className="AppLayoutTabPanelsContainer-root" sx={{ flex: '1 1 0', '> [role=tabpanel]': { height: '100%' } }}>{children}</Stack>;
}

const Root = styled('div')(({ theme }) => css`
  margin-top: -${getContentPaddingY(theme)};
  margin-bottom: ${getContentPaddingY(theme)};
`);

const Tabs = styled(MuiTabs)(({ theme }) => css`
  background-color: ${theme.palette.background.paper};

  ${theme.breakpoints.up('md')} {
    left: ${theme.sizes.sidebarWidth}px;
    border-top-right-radius: ${CORNERS_RADIUS};
  }
`);

const FixedWrapper = styled('div')(({ theme }) => css`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${theme.palette.primary.main};

  ${theme.breakpoints.up('md')} {
    left: ${theme.sizes.sidebarWidth}px;
  }
`);
